import React from 'react'
import './SectionNabvar.css'

const SectionNavbar = ({ navbarData = [], selectedOption = 0, setSelectedOption = () => {}, inverseColor = false }) => {

    const changeOption = (index) => {
        setSelectedOption(index);
    }

    return (
        <div className='section-navbar-father'>
            <div className='section-navbar-row'>
                {
                    navbarData.map((option, index) => 
                        <div
                            key={index}
                            className={
                                (!inverseColor) ?
                                `section-navbar-button ${(index === selectedOption) ? 'section-navbar-button-selected' : ''}`
                                :
                                `section-navbar-button-inverse ${(index === selectedOption) ? 'section-navbar-button-inverse-selected' : ''}`
                            }
                            onClick={() => {changeOption(index)}}
                        >
                            {option.name}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default SectionNavbar;