import Navbar from "../../components/Navbar/Navbar";
import React, { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { FaPlus, FaSave } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import BACK_END from '../../config/direction';
import { AiFillDelete } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './AddList.css';

const AddList = () => {

    const location = useLocation();
    const groupId = location.state?.group_id;

    const { list } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState('');
    const [itemsList, setItemsList] = useState([]);

    // Get data
    useEffect(() => {
            fetch(
                `${BACK_END}/${list}?group_id=${groupId}`,
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(async (res) => {
                const response = await res.json();
                setItemsList(response.data);
            })
            .catch((error) => console.log(error));
        }, [list, groupId]);
        
    // Change textfield's value
    const onChangeInput = (event, itemList) => {
        
        const value = event.target.value;
        const index = itemsList.indexOf(itemList);
        
        setItemsList([
            ...itemsList.slice(0,index),
            { ...itemsList[index], name: value },
            ...itemsList.slice(index + 1)
        ]);
    }

    // Upload a new item's list
    const uploadItem = () => {

        const data = { item, groupId };

        fetch(
            `${BACK_END}/${list}`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        )
        .then(async (res) => {
            if(res.ok) {
                const response = await res.json();
                setItemsList([response.data, ...itemsList]);
                setItem('');
            }
        })
        .catch((error) => console.log(error));
    }

    // Update an item's list
    const editItem = (itemList) => {

        const data = { id: itemList.id, name: itemList.name };

        fetch(
            `${BACK_END}/${list}`,
            {
                method: 'PATCH',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        )
        .then(async (res) => {
            if(res.ok) {
                const response = await res.json();
                console.log(response);
                toast.success('Elemento actualizada', { hideProgressBar: true, autoClose: 1000 } );
            }
        })
        .catch((error) => console.log(error));
    }

    // Delete an item's list
    const deleteItem = (itemList) => {

        const deleteConfirmed = () => {
            const data = { id: itemList.id };

            fetch(
                `${BACK_END}/${list}`,
                {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }
            )
            .then(async (res) => {
                const response = await res.json();
                if(res.ok) {
                    const index = itemsList.indexOf(itemList);
                    setItemsList([
                        ...itemsList.slice(0, index),
                        ...itemsList.slice(index + 1)
                    ]);
                    toast.success(response.message, { hideProgressBar: true, autoClose: 1000 } );
                } else {
                    toast.warn(response.error);
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }

        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="overlay">
                  <div className="custom-ui">
                    <h1>Confirmar eliminación</h1>
                    <p>¿Desea eliminar "{itemList.name}"?</p>
                    <div className="custom-ui-buttons">
                      <button onClick={onClose}>Cancelar</button>
                      <button
                        onClick={() => {
                          deleteConfirmed();
                          onClose();
                        }}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              );
            },
          });
    }

    // Render
    return (
    <div className="add-list-father-container">
        <Navbar />
        <div className='add-list-container'>
            <div className='product-card'>

                <h3 className="add-list-header">Agregar {(list === 'origin') ? 'Origen' : (list === 'group') ? 'Grupo' : 'Departamento'}</h3>
                
                <div className='drop-row-add-list'>
                    <div className='add-list-input'>
                        <input
                            type='text'
                            placeholder='Nuevo elemento'
                            value={item}
                            onChange={(value) => setItem(value.target.value)}
                            autoFocus={!isMobile}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    uploadItem();
                                }
                              }}
                        />
                    </div>
                    <button
                        className='dropdown-button'
                        onClick={uploadItem}
                        title='Agregar'
                    >
                        <FaPlus size={20} color='black' />
                    </button>
                </div>

                <div className='add-list-column'>
                    {
                        itemsList.map((itemList, index) => (
                            <div className='drop-row' key={index}>
                                <div className='add-list-input'>
                                    <input
                                        type='text'
                                        placeholder='Editar elemento'
                                        value={itemList.name}
                                        onChange={(e) => onChangeInput(e, itemList)}
                                    />
                                </div>
                                <button
                                    className='add-list-button'
                                    title='Guardar'
                                    onClick={() => editItem(itemList)}
                                >
                                    <FaSave size={20} color='black' />
                                </button>
                                <button
                                    className='add-list-button'
                                    title='Eliminar'
                                    onClick={() => deleteItem(itemList)}
                                >
                                    <AiFillDelete size={20} color='black' />
                                </button>
                            </div>
                        ))
                    }
                </div>

                <button className='add-list-back-button' onClick={() => navigate(-1)}><IoMdArrowBack size={16} color='fff' /> Volver</button>

            </div>
        </div>
    </div>
    );
}

export default AddList;