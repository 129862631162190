import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import './CustomerPaymentsModal.css';
import BACK_END from '../../config/direction';
import { toast } from "react-toastify";
import axios from 'axios';
import { GiSwapBag } from 'react-icons/gi';
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { SessionContext } from "../../hooks/useSession";

Modal.setAppElement('#root');

const CustomerPaymentsModal = ({ onRequestClose, customer_id }) => {

    const userJWT = useContext(SessionContext)?.token;

    const navigate = useNavigate();

    const [customerData, setCustomerData] = useState(null);
    const [payments, setPayments] = useState([]);

    const [showBills, setShowBills] = useState(false);
    const [bills, setBills] = useState([]);

    const handleRowClick = (billId) => {
        navigate(`/pay_debt/${billId}`, { state: { customer_id: customer_id } });
    };

    const getData = async () => {
        try {
            const res = await axios.get(`${BACK_END}/customer/${customer_id}/payments`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            setPayments(res.data);
        } catch (error) {
            if (error.response) {
                toast.error("No se pudo recuperar la información del cliente.", { autoClose: 2500, hideProgressBar: true });
                console.log(error.response.data.error);
            } else {
                console.log(error.message);
            }
        }
    };

    const fetchBills = () => {
        fetch(`${BACK_END}/bill/outstanding/${customer_id}`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userJWT}`
            },
        })
            .then(async (res) => {
                const data = await res.json();
                if (res.ok) {
                    setBills(data);
                } else {
                    toast.error("No se pudo recuperar la información de deudas.", { autoClose: 2500, hideProgressBar: true });
                    console.log(data.error);
                }
            })
            .catch((error) => {
                toast.error("No se pudo recuperar la información de deudas.", { autoClose: 2500, hideProgressBar: true });
                console.log(error);
            });
    };

    const getCustomerData = async () => {
        try {
            const res = await axios.get(`${BACK_END}/customer/${customer_id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = res.data[0];
            setCustomerData(data);
        } catch (error) {
            if (error.response) {
                toast.error("No se pudo recuperar la información del cliente.", { autoClose: 2500, hideProgressBar: true });
                console.log(error.response.data.error);
            } else {
                console.log(error.message);
            }
        }
    };

    const showBillsModal = () => {
        setShowBills(true);
    }

    const closeBillsModal = () => {
        setShowBills(false);
    }

    useEffect(() => {
        if (customer_id) {
            getCustomerData();
            getData();
        }
        if (customer_id && showBills) {
            fetchBills();
        } // eslint-disable-next-line
    }, [customer_id, showBills]);

    return (
        <Modal
            isOpen={customer_id !== undefined && customer_id !== null}
            onRequestClose={onRequestClose}
            contentLabel="Historial de Abonos"
            className="customer-modal"
            overlayClassName="customer-overlay"
        >
            {
                (!showBills)
                    ?
                    <>
                        <div className='customer-modal-row'>
                            <h2 className="modal-title">Información del cliente</h2>
                            <button className="customer-close-button" onClick={onRequestClose}>Cerrar</button>
                        </div>
                        <div>
                            <p className='payment-modal-customer-text'>Nombre: <span className='payment-modal-customer-gray'>{customerData?.name}</span></p>
                            <p className='payment-modal-customer-text'>Teléfono: <span className='payment-modal-customer-gray'>{customerData?.phone}</span></p>
                            <p className='payment-modal-customer-text'>Puntos: <span className='payment-modal-customer-gray'>{customerData?.points}</span></p>
                            <p className='payment-modal-customer-text'>Deuda: <span className='payment-modal-customer-green'>${customerData?.totalDebt}</span></p>
                        </div>

                        <br />

                        <div className='customer-modal-row'>
                            <h2 className="modal-title">Historial de Abonos</h2>
                            {
                                !isMobile &&
                                <button
                                    className="payment-debt-button shadow-div golden-button"
                                    onClick={showBillsModal}
                                >
                                    <GiSwapBag size={33} color="#000" />
                                    COBRAR DEUDA
                                </button>
                            }
                        </div>
                        <div className="modal-header">
                            <div className="header-item">Folio ticket</div>
                            <div className="header-item hidden">Apartado por</div>
                            <div className="header-item">Pagó con</div>
                            <div className="header-item hidden">Fecha</div>
                            <div className="header-item hidden">Total</div>
                            <div className="header-item">Artículos</div>
                        </div>
                        <div className="customer-payments-list">
                            {payments.length > 0 ? (
                                payments.map((payment, index) => {
                                    return (
                                        <div key={index} className="customer-payment-item">
                                            <div className="customer-payment-detail">
                                                <span className={`customer-payment-value ${(payment.is_debt_paid) ? 'customer-paied' : ''}`}>{payment?.bill_id}</span>
                                            </div>
                                            <div className="customer-payment-detail hidden">
                                                <span className={`customer-payment-value ${(payment.is_debt_paid) ? 'customer-paied' : ''}`}>{payment?.cashier}</span>
                                            </div>
                                            <div className="customer-payment-detail">
                                                <span className={`customer-payment-value ${(payment.is_debt_paid) ? 'customer-paied' : ''}`}>Efectivo: ${payment?.cash ?? 'N/A'}</span>
                                                <span className={`customer-payment-value ${(payment.is_debt_paid) ? 'customer-paied' : ''}`}>Tarjeta: ${payment?.card ?? 'N/A'}</span>
                                            </div>
                                            <div className="customer-payment-detail">
                                                <span className={`customer-payment-value ${(payment.is_debt_paid) ? 'customer-paied' : ''}`}>{new Date(payment.created_at).toLocaleString()}</span>
                                            </div>
                                            <div className="customer-payment-detail hidden">
                                                <span className={`customer-payment-value ${(payment.is_debt_paid) ? 'customer-paied' : ''}`}>${payment.debtOriginal}</span>
                                            </div>
                                            <div className="customer-payment-detail">
                                                <span className={`customer-payment-value ${(payment.is_debt_paid) ? 'customer-paied' : ''}`}>
                                                    {
                                                        payment?.product_names.map((productName, indexName) => {

                                                            return (
                                                                <span className={`payment-modal-customer-tiny ${(indexName % 2 === 0) ? 'payment-modal-customer-yellow' : ''}`}>
                                                                    -{productName}
                                                                </span>
                                                            )
                                                        })
                                                        ??
                                                        'N/A'
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            ) : (
                                <div className='customer-not-debt-container'>
                                    <p className='debt-payment-not-found'>No hay registros de abonos.</p>
                                </div>
                            )}
                        </div>
                    </>
                    :
                    <div className='bill-debt-modal-content'>
                        <div className='bill-debt-modal-header'>
                            <h2 className="modal-title">Seleccione la factura por Pagar</h2>
                            <button className="bill-debt-close-button" onClick={closeBillsModal}>Volver</button>
                        </div>
                        <div className="bill-debt-details">
                            {
                                (bills.length > 0)
                                    ?
                                    <table className="bill-debt-products-table">
                                        <thead>
                                            <tr>
                                                <th>Folio ticket</th>
                                                <th>Deuda Original</th>
                                                <th>Deuda Pendiente</th>
                                                <th>Fecha</th>
                                                <th>Artículos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bills.map((bill) => (
                                                <tr key={bill?.bill_id} onClick={() => handleRowClick(bill.bill_id)}>
                                                    <td>{bill?.bill_id}</td>
                                                    <td>${bill?.debt.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</td>
                                                    <td>${bill?.debtOutstanding.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</td>
                                                    <td>{new Date(bill?.created_at).toLocaleString()}</td>
                                                    <td><span className="customer-payment-value">
                                                        {
                                                            bill?.product_names.map((productName, indexName) => {
                                                                return (
                                                                    <span className={`payment-modal-customer-tiny ${(indexName % 2 === 0) ? 'payment-modal-customer-yellow' : ''}`}>
                                                                        -{productName}
                                                                    </span>
                                                                )
                                                            })
                                                            ??
                                                            'N/A'
                                                        }
                                                    </span></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    :
                                    <div className='customer-not-debt-container'>
                                        <p className='debt-payment-not-found'>No hay deudas pendientes.</p>
                                    </div>
                            }
                        </div>
                    </div>
            }
        </Modal>
    );
};

export default CustomerPaymentsModal;