import UtilityContainer from '../UtilityContainer/UtilityContainer';
import React, { useState, useEffect } from 'react';
import BACK_END from '../../../config/direction';
import axios from 'axios';

import './UtilityCashier.css';

import { exportToExcel } from '../../../util/excel';
import { BsCalendar3 } from 'react-icons/bs';
import Calendar from 'react-calendar';
import { MdClose } from 'react-icons/md';

const UtilityCashier = () => {

    const weekdayLabels = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [rangeDate, setRangeDate] = useState(null);

    const [dataset, setDataset] = useState([]);

    const commission = 0.02;

    // Initial fetch
    const getData = () => {
        let startDate = new Date();
        let endDate = new Date();

        if (rangeDate) {
            startDate = rangeDate[0];
            endDate = rangeDate[1];
        }

        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        startDate = startDate.toISOString();
        endDate = endDate.toISOString();

        axios.get(`${BACK_END}/stadistics/sales-by-employee`,
            {
                params: { startDate, endDate }
            }
        )
            .then((res) => {
                const data = res?.data;
                if (res.status === 200) {
                    const filteredData = data.filter(cashier => cashier.employeeName !== 'admin');
                    setDataset(filteredData);
                }
            })
            .catch((error) => console.error(error));
    }

    // eslint-disable-next-line
    useEffect(getData, [rangeDate]);

    const handleExportClick = () => {
        const data = dataset.map(row => ({
            Nombre: row.employeeName,
            Ventas: row.sales,
            Total: row.totalAmount,
            'Comisión': Number((row.totalAmount * commission).toFixed(2))
        }));
        exportToExcel(data, 'Ventas-Cajeros');
    };

    // Give a nice format
    const formatNumber = (number) => {
        number = Number(number);
        return number.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    // Container header
    const header = (
        <div className='utility-header'>
            Ventas por cajero
            <button className="date-entry-button utility-cashier-button" onClick={() => setShowDatePicker(!showDatePicker)}>
                <BsCalendar3 size={28} />
                <span className="date-entry-label">Fecha</span>
            </button>
        </div>
    );

    const chart = (
        <div className='utility-cashier-container'>
            <div className='utility-cashier-header'>
                <span className='utility-cashier-grid-column'>Nombre</span>
                <span className='utility-cashier-grid-column2'>Ventas</span>
                <span className='utility-cashier-grid-column'>Total</span>
                <span className='utility-cashier-grid-column'>Comisión (2%)</span>
            </div>
            {
                dataset.map((cashier, index) => (
                    <div className='utility-top-margin-row' key={`cashier_${index}`}>
                        <span className='utility-grid-column'>{cashier.employeeName}</span>
                        <span className='utility-grid-column2'>{cashier.sales}</span>
                        <span className='utility-grid-column'>${formatNumber(cashier.totalAmount)}</span>
                        <span className='utility-grid-column'>${formatNumber(cashier.totalAmount * 0.02)}</span>
                    </div>
                ))
            }
            {showDatePicker && (
                <div className='date-modal-overlay'>
                    <div className="date-picker-container">
                        <Calendar
                            tileClassName={({ date, view }) => {
                                if (view === 'month' && date.toDateString() === new Date().toDateString()) {
                                    return 'highlight-today';
                                }
                                return '';
                            }}
                            onChange={(date) => setRangeDate(date)}
                            value={rangeDate}
                            selectRange={true}
                            showNeighboringMonth={false}
                            formatShortWeekday={(locale, date) => weekdayLabels[date.getDay()]}
                        />
                        <button className="calendar-close-button" onClick={() => setShowDatePicker(false)}>
                            <MdClose size={20} color='black' />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <UtilityContainer
            header={header}
            content={chart}
            handleExportClick={handleExportClick}
        />
    )
}

export default UtilityCashier;