import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './BillDetailModal.css';
import { TiCancel, TiPrinter } from 'react-icons/ti';
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import BACK_END from '../../config/direction';
import { printTicket } from '../../util/pos_esc';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';
import { toast } from 'react-toastify';

Modal.setAppElement('#root');

const BillModal = ({ bill_id, closeModal }) => {

    const { userData } = useContext(SessionContext);

    const [products, setProdcts] = useState([]);
    const [bill, setBill] = useState(null);

    const navigate = useNavigate();

    const formatCurrency = (amount) => {
        const number = parseFloat(amount);
        if (isNaN(number)) {
            return '0.00';
        }
        return number.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${BACK_END}/bill/last-bill?bill_id=${bill_id}`
            );
            const { bill, details } = response.data;

            setBill(bill);
            setProdcts(details);
        } catch (error) {
            console.error("Error fetching bill details:", error);
        }
    };

    useEffect(() => {
        if (bill_id) {
            fetchData();
        } // eslint-disable-next-line
    }, [bill_id]);

    const printBill = (bill_id) => {

        axios.get(`${BACK_END}/bill/last-bill?bill_id=${bill_id}`).then((result) => {
            const { bill, details } = result.data;

            const cart = details.reduce((acc, detail) => {
                const { product_id, discount, quantity } = detail;
                const key = `${product_id}-${discount}`;

                if (!acc[key]) {
                    acc[key] = { product: { ...detail }, amount: 0 };
                }

                acc[key].amount += quantity;
                return acc;
            }, {});

            const cartArray = Object.values(cart);

            printTicket(
                userData.name,
                cartArray,
                bill?.created_at,
                { cash: Number(bill?.cash), card: Number(bill?.card), debt: Number(bill?.debt), points: Number(bill?.points) },
                bill?.total_amount,
                0,
                bill?.discount,
                bill?.bill_id,
            );
        });
    }

    const goToRefund = () => {
        if (bill) {
            navigate(`/refund/${bill?.bill_id}`);
        }
    }

    const cancelBill = () => {

        const confirmedCancel = () => {
            axios.post(`${BACK_END}/bill/cancel`, { bill_id, user_id: userData.user_id })
                .then((response) => {
                    toast.success(`Ticket ${bill_id} cancelado.`, { hideProgressBar: true, autoClose: 1200 });
                    closeModal();
                })
                .catch((error) => {
                    console.error(error);
                    const message = error?.response?.data?.error;
                    if (message) {
                        toast.error(message);
                    } else {
                        toast.error('No se pudo cancelar el ticket.');
                    }
                });
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="overlay">
                        <div className="custom-ui">
                            <h1>Confirmarción</h1>
                            <p>¿Estás seguro de que deseas cancelar este ticket?</p>
                            <div className="custom-ui-buttons">
                                <button onClick={onClose}>No</button>
                                <button
                                    onClick={() => {
                                        confirmedCancel();
                                        onClose();
                                    }}
                                >
                                    Si
                                </button>
                            </div>
                        </div>
                    </div>
                );
            },
        });
    }

    return (
        <Modal
            isOpen={bill_id !== null && bill_id !== undefined}
            onRequestClose={closeModal}
            contentLabel="Detalles de la Factura"
            className="bill-modal"
            overlayClassName="bill-overlay"
        >
            <div className='bill-modal-content'>
                <div className='bill-modal-header'>
                    <h2 className="modal-title">Detalles de la Factura #{bill?.bill_id}</h2>
                    <div className='bill-modal-button-row'>
                        {!isMobile && <button className="bill-close-button" onClick={() => printBill(bill_id)}><TiPrinter /> Imp. ticket</button>}
                        <button className="bill-close-button" onClick={closeModal}>Cerrar</button>
                    </div>
                </div>
                <div className="bill-details">
                    <div className="bill-info">
                        <div className='bill-modal-columns'>
                            <div className="bill-info-item"><strong>Pagado con efectivo:</strong> {formatCurrency(bill?.cash)}</div>
                            <div className="bill-info-item"><strong>Pagado con tarjeta:</strong> {formatCurrency(bill?.card)}</div>
                            <div className="bill-info-item"><strong>Pagado con puntos:</strong> {formatCurrency(bill?.points)}</div>
                            <div className="bill-info-item"><strong>Total pagado:</strong> {formatCurrency(bill?.total_amount)}</div>
                            <div className="bill-info-item"><strong>Cambio:</strong> {formatCurrency(bill?.change)}</div>
                        </div>

                        <div className='bill-modal-columns'>
                            <div className="bill-info-item"><strong>Fecha y hora:</strong> {new Date(bill?.created_at).toLocaleString()}</div>
                            {bill?.cancelled_at && <div className="bill-info-item bill-modal-text-cancel"><strong>Cancelación:</strong> {new Date(bill?.cancelled_at).toLocaleString()}</div>}
                            <div className="bill-info-item"><strong>Canal de Venta:</strong> {bill?.sale_channel === 'online' ? 'E-commerce' : 'Punto de venta'}</div>
                            <div className="bill-info-item"><strong>Apartado:</strong> {(bill?.debt > 0) ? 'Si' : 'No'}</div>
                            {bill?.sale_channel === 'online' && (
                                <div className="bill-info-item"><strong>Usuario E-commerce:</strong> {bill?.ecommerce_user_id}</div>
                            )}
                            {bill?.sale_channel === 'online' && <div className="bill-info-item"><strong>Estado de Envío:</strong> {bill?.shipping_status}</div>}
                        </div>
                    </div>
                    <div className="bill-products">
                        <h3>Productos:</h3>
                        <table className="bill-products-table">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Precio</th>
                                    <th>Descuento (%)</th>
                                    <th>Precio final</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map((product, index) => (
                                    <tr key={`product_${index}`} className={(product.refund > 0) ? 'bill-detail-modal-gray' : ''}>
                                        <td>{product.name}</td>
                                        <td>{formatCurrency(product.price)}</td>
                                        <td>{product.discount}%</td>
                                        <td>{formatCurrency(product.price - (product.price * (product.discount / 100)))}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='bill-modal-button-row bill-modal-margin-top'>
                        {
                            !isMobile && !bill?.cancelled_at &&
                            <button className="bill-action-button bill-modal-button-cancel" onClick={cancelBill}><TiCancel /> Cancelar ticket</button>
                        }
                        {
                            !isMobile && !bill?.cancelled_at &&
                            <button className="bill-action-button" onClick={goToRefund}><HiOutlineReceiptRefund /> Reembolsar productos</button>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default BillModal;