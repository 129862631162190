import { FaPlusCircle, FaSearch } from 'react-icons/fa';
import Navbar from '../../components/Navbar/Navbar';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdFilterAltOff } from 'react-icons/md';
import BACK_END from '../../config/direction';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Dropdown from 'react-dropdown';
import { MdEdit } from "react-icons/md";
import axios from 'axios';
import './Users.css';

import SectionNavbar from '../../components/SectionNavbar/SectionNavbar';

const Users = () => {

  const [searchedUser, setSearchedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);

  const roles = ['Administrador', 'Personal de caja'];

  // Get users
  const getUsers = () => {
    const params = new URLSearchParams();

    if (searchedUser) {
      params.append('name', searchedUser);
    }
    if (selectedRole?.value) {
      params.append('rol', selectedRole.value);
    }

    axios.get(
      `${BACK_END}/user/0?${params.toString()}`
    )
      .then((result) => {
        const data = result.data;
        setUsers(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Delete a user
  const deleteUser = (name, id) => {

    const deleteConfirmed = async () => {
      await axios.delete(`${BACK_END}/user/${id}`)
        .then((result) => {
          const data = result.data;
          toast.success(data.message, { hideProgressBar: true, autoClose: 1000 });

          const newUsersList = users.filter(user => user.user_id !== id);
          setUsers(newUsersList);
        });
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="overlay">
            <div className="custom-ui">
              <h1>Confirmar eliminación</h1>
              <p>¿Desa eliminar el usuario de "{name}" de forma permanente?</p>
              <div className="custom-ui-buttons">
                <button onClick={onClose}>Cancelar</button>
                <button
                  onClick={() => {
                    deleteConfirmed();
                    onClose();
                  }}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  // Filter and fetch users
  useEffect(() => {
    getUsers(); // eslint-disable-next-line
  }, [searchedUser, selectedRole]);

  const cleanFilters = () => {
    setSearchedUser('');
    setSelectedRole(null);
  }

  return (
    <div className='parent'>
      <Navbar />
      <div className='users-container'>
        <SectionNavbar
          navbarData={[{ name: 'Usuarios', link: '/users' }]}
          setSelectedOption={() => { }}
          selectedOption={1}
        />
        <div className='inventory-container'>
          <div className='table-box-container table-padding-bottom'>
            <div className='table-box'>
              <table className='table'>
                <thead>
                  <tr className='table-header'>
                    <th className='inventory-header'>#</th>
                    <th className='inventory-header'>Usuario</th>
                    <th className='inventory-header2'>Nombre</th>
                    <th className='inventory-header'>Rol</th>
                    <th className='inventory-header'>Opciones</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {
                    users.map(user =>
                      <tr className='inventary-tbody user-row' key={user.user_id}>
                        <td>{user?.user_id}</td>
                        <td>{user?.username}</td>
                        <td className='user-row2'>{user?.name}</td>
                        <td>{user?.rol}</td>
                        <td>
                          <div className='table__options'>
                            <button className='option-button option-button--hover-shadow' onClick={() => navigate(`/edit_user/${user.user_id}`)}>
                              <MdEdit size={24} color='white' />
                            </button>
                            <button className='option-button-delete option-button--hover-shadow' onClick={() => deleteUser(user.name, user.user_id)}>
                              <RiDeleteBin6Line size={24} color='white' />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                  {/* Agrega/Elimina filas si es necesario */}
                </tbody>
              </table>
            </div>
          </div>
          <div className='users-bottom-row  '>

            <button className='add-user-button golden-button' onClick={() => navigate('/add_user')}><FaPlusCircle size={24} />Agregar</button>

            <div className='search-bar-users'>
              <FaSearch className='search-icon' />
              <input
                value={searchedUser}
                onChange={(event) => setSearchedUser(event.target.value)}
                type='text'
                placeholder='Nombre del usuario'
                autoFocus
              />
            </div>
            <div className="both-cont">
              <Dropdown className='category-scroll' options={roles} onChange={(text) => setSelectedRole(text)} value={selectedRole} placeholder='Seleccione un rol' />
              <button className="view-mode-button" onClick={cleanFilters}>
                <MdFilterAltOff size={24} color='#000' />
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Users;