import InventoryMain from './InventoryMain/InventoryMain';
import Navbar from '../../components/Navbar/Navbar';
import React, { useEffect, useState } from 'react';
import 'react-dropdown/style.css';
import './Inventory.css';
import SectionNavbar from '../../components/SectionNavbar/SectionNavbar';
import InventoryEntries from './InventoryEntries/InventoryEntries';
import InventaryExits from './InventoryExits/InventoryExits';
import InventoryMovements from './InventoryMovements/InventoryMovements';

const Inventory = () => {

  // Get option saved
  const initialSelectedOption = parseInt(sessionStorage.getItem('selectedOptionInventaryNavbar')) || 0;
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

  // Save change
  useEffect(() => {
    sessionStorage.setItem('selectedOptionInventaryNavbar', selectedOption.toString());
  }, [selectedOption]);

  const navbarData = [
    { name: 'INVENTARIO', link: '/inventory' },
    { name: 'COMPRAS', link: '/inventory' },
    { name: 'VENTAS', link: '/inventory' },
    { name: 'MOVIMIENTOS', link: '/inventory' }
  ];

  // Render component
  return (
    <div className='parent-inventary'>
        <Navbar />
        <div className='inventary-father'>
          <SectionNavbar
            navbarData={navbarData}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          {
            (selectedOption === 0) // Inventory
            ?
            <InventoryMain />
            :
            (selectedOption === 1) // Entries
            ?
            <InventoryEntries />
            :
            (selectedOption === 2) // Exits
            ?
            <InventaryExits />
            :
            (selectedOption === 3) // Movements
            ?
            <InventoryMovements />
            :-
            <></>
          }
        </div>
    </div>
  );
}

export default Inventory;