import Navbar from "../../components/Navbar/Navbar";
import { printTicketDemo } from "../../util/pos_esc";
import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import BACK_END from "../../config/direction";
import { toast } from "react-toastify";
import axios from "axios";
import "./Settings.css";

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';

const Settings = () => {

  const { userData } = useContext(SessionContext);
  const isAdmin = userData && userData.rol === "Administrador";

  const [settings, setSettings] = useState({});
  const [settingsLoading, setSettingsLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    fetchData();
    isValidStripeId();
  }, []);

  const isValidStripeId = async () => {
    let isValid = false;

    const res = await axios.get(`${BACK_END}/payment/stripe-is-valid`);

    if (res.status === 200) {
      isValid = res.data.valid;
    }

    setIsValid(isValid);
  }

  const fetchData = async () => {
    try {
      const res = await axios.get(`${BACK_END}/settings/`);
      if (res.status === 200) {
        setSettings(res.data);
        setSettingsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const handleUpdateSettings = async () => {
    if(!isAdmin) {
      toast.info('Opción restrigida para Admin');
      return;
    }
    try {
      const res = await axios.put(`${BACK_END}/settings/`, settings);
      if (res.status === 200) {
        toast.success(res.data.message);
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  const handleConnectStripe = async () => {
    if(!isAdmin) {
      toast.info('Opción restrigida para Admin');
      return;
    }
    try {
      setIsLoading(true);
      const res = await axios.post(`${BACK_END}/payment/create-account-link`);
      if (res.status === 200) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      console.error("Error creating Stripe account link:", error);
      toast.error("Error al conectar Stripe Connect");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="settings-father">
      <Navbar />
      <div className="settings-container">
        <div className="settings-box shadow-div">
          <div className="settings-top-row">
            <div className="settings-h1">Configuración</div>
            <button className="setting-button golden-button" onClick={handleUpdateSettings}>
              Guardar
            </button>
          </div>
          <div className="gray-container">
            <div className="settings-row-column">
              <div className="settings-child">
                <div className="settings-title">🛍️ Tienda en Línea</div>
                <div className="settings-row">
                  <p className="settings-text">
                    Precio de envío ({settings?.currency ?? "MXN"}):
                  </p>
                  <input
                    type="text"
                    disabled={settingsLoading}
                    className="settings-input"
                    placeholder="Precio de envío"
                    value={settings?.shipping_delivery_price ?? ""}
                    onChange={(event) =>
                      setSettings({
                        ...settings,
                        shipping_delivery_price: event.target.value,
                      })
                    }
                    autoComplete="off"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleUpdateSettings(); // Llama a la función de actualización al presionar Enter
                      }
                    }}
                  />
                </div>
              </div>

              <div className="settings-child">
                <div className="settings-title">🖨️ Impresora</div>
                <div className="settings-row">
                  <div className="setting-button silver-button" onClick={printTicketDemo}>
                    Imprimir ticket de prueba
                  </div>
                </div>
              </div>
            </div>

            <div className="white-container">
              <div className="settings-title">💳 Stripe Connect</div>
              <div className="settings-row">
                <p className="settings-normal-text">
                  Para recibir pagos en línea, utilizamos Stripe Connect, <strong>la plataforma más segura del mercado. </strong> Protege tus transacciones y te permite acceder a tus ganancias de forma rápida y sencilla. La comisión por cada transacción es del 3.6% + $3.00 MXN.
                  ¡Regístrate en minutos y empieza a recibir pagos! 🎉 Para más información, haz <a href="https://stripe.com/mx/pricing" target="_blank" rel="noreferrer noopener">clic aquí</a>.
                </p>
                <img className="stripe" src="/stripe.png" alt="stripe-img" />

              </div>
              <div className="settings-row2">
                <p className="settings-text">
                  Estado:{" "}
                  <span
                    className={
                      isValid
                        ? "settings-green-text"
                        : "settings-red-text"
                    }
                  >
                    {isValid ? "Conectado" : "Desconectado"}
                  </span>
                </p>
                {
                  isLoading ? (
                    <ColorRing
                      visible={true}
                      height="60"
                      width="60"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#FFD700",
                        "#FFEC8B",
                        "#FFFACD",
                        "#F0E68C",
                        "#EEE8AA",
                      ]}
                    />
                  ) : (
                    <button
                      className="setting-button golden-button"
                      onClick={handleConnectStripe}
                    >
                      Conectar Stripe Connect
                    </button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
