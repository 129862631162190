import { FaClockRotateLeft } from 'react-icons/fa6';
import { FaEdit } from 'react-icons/fa';
import BACK_END from '../../config/direction';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import axios from 'axios';

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';

const CashDepositModal = ({ isCashDepositModalOpen, closeCashDepositModal, showRegisterScreen = true }) => {

    const { userData } = useContext(SessionContext);

    const [showDepositForm, setShowDepositForm] = useState(showRegisterScreen);
    const [cashDepositAmount, setCashDepositAmount] = useState('');
    const [cashDepositReason, setCashDepositReason] = useState('');
    const [cashDeposits, setCashDeposits] = useState([]);

    const toggleDepositView = () => {
        setShowDepositForm((prev) => !prev);
    };

    const formatDate = (isoDate) => {
        if (!isoDate) {
            return null;
        }

        const date = new Date(isoDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

        return date.toLocaleDateString('es-MX', options);
    };

    const handleCashDeposit = async () => {
        try {
            const response = await axios.post(`${BACK_END}/cashDeposits`, {
                user_id: userData.user_id,
                amount: cashDepositAmount,
                reason: cashDepositReason,
            });
            toast.success(response.data.message);
            setCashDepositAmount('');
            setCashDepositReason('');
            closeCashDepositModal();
            fetchCashDeposits();
        } catch (error) {
            console.error(error);
            toast.error('Error al registrar la entrada');
        }
    };

    const fetchCashDeposits = async () => {
        try {
            const response = await axios.get(`${BACK_END}/cashDeposits`);
            setCashDeposits(response.data);
        } catch (error) {
            console.error(error);
            toast.error('Error al obtener el historial de entradas');
        }
    };

    useEffect(() => {
        fetchCashDeposits();
    }, []);

    return (
        <Modal
            isOpen={isCashDepositModalOpen}
            onRequestClose={closeCashDepositModal}
            className="custom-ui-modal"
            overlayClassName="overlay-modal"
            contentLabel="Registrar Entrada de Efectivo"
        >
            <h2>{showDepositForm ? 'Registrar Entrada de Efectivo' : 'Historial de Entradas'}</h2>
            {showDepositForm ? (
                <>
                    <input
                        type="number"
                        value={cashDepositAmount}
                        onChange={(e) => setCashDepositAmount(e.target.value)}
                        placeholder="Monto"
                        autoFocus
                    />
                    <input
                        type="text"
                        value={cashDepositReason}
                        onChange={(e) => setCashDepositReason(e.target.value)}
                        placeholder="Motivo"
                    />
                    <div className="custom-ui-buttons-modal">
                        <button className="golden-button withdrawals-confirm-modal" onClick={handleCashDeposit}>
                            <FaEdit /> Registrar
                        </button>
                    </div>
                </>
            ) : (
                <div className="withdrawals-table">
                    <div className="withdrawals-table__header">
                        <div className="withdrawals-table__header-item">Monto</div>
                        <div className="withdrawals-table__header-item">Motivo</div>
                        <div className="withdrawals-table__header-item">Usuario</div>
                        <div className="withdrawals-table__header-item">Fecha</div>
                    </div>
                    <div className="withdrawals-table__content">
                        {cashDeposits.map((deposit) => (
                            <div key={deposit.id} className="withdrawals-table__row">
                                <div className="withdrawals-table__item">${deposit.amount}</div>
                                <div className="withdrawals-table__item">{deposit.reason}</div>
                                <div className="withdrawals-table__item">{deposit.user_name}</div>
                                <div className="withdrawals-table__item">{formatDate(deposit.created_at)}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className="toggle-view-button">
                <button className="golden-buttn withdrawals-cancel-modal" onClick={closeCashDepositModal}>
                    Cancelar
                </button>
                <button className='withdrawals-history-modal' onClick={toggleDepositView}>
                    {showDepositForm ? <FaClockRotateLeft /> : <FaEdit />}
                    {showDepositForm ? 'Ver Historial' : 'Nueva entrada'}
                </button>
            </div>
        </Modal>
    )
}

export default CashDepositModal;