import { FaChartLine, FaBoxArchive, FaUserGroup, FaUsers, FaCircleUser } from "react-icons/fa6";
import { FiLogOut } from 'react-icons/fi';
import { IoSettingsSharp, IoCut } from "react-icons/io5";
import { isMobile } from 'react-device-detect';
import { MdAttachMoney } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import './Navbar.css';

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';

const Navbar = () => {

  const { userData, logout } = useContext(SessionContext);
  const isAdmin = userData && userData.rol === "Administrador";
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const initialSelection = sessionStorage.getItem('selectedNavbarOption') ?? 'sales';
  const [selectedNavbar, setSelectedNavbar] = useState(initialSelection);

  useEffect(() => {
    sessionStorage.setItem('selectedNavbarOption', selectedNavbar);
  }, [selectedNavbar]);

  useEffect(() => {
    if (isMobile) {
      setSelectedNavbar('inventary');
    }
  }, [setSelectedNavbar]);

  const handleLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="overlay">
            <div className="custom-ui">
              <h1>Confirmación</h1>
              <p>¿Desea cerrar la sesión actual?</p>
              <div className="custom-ui-buttons">
                <button onClick={onClose}>Cancelar</button>
                <button
                  onClick={() => {
                    logout();
                    onClose();
                  }}
                >
                  Cerrar sesión
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  return (
    <div className="navbar">
      <nav className="NavbarBottomToggle" >
        <div className="NavbarLinks">
          {!isMobile && <NavLink className="NavBottom" exact='true' to="/"><MdAttachMoney size={30} /></NavLink >}
          <NavLink className="NavBottom" to="/inventory"><FaBoxArchive size={30} /></NavLink>
          <NavLink className="NavBottom" to="/customers"><FaUsers size={30} /></NavLink>
          <NavLink className="NavBottom" to="/utilities"><FaChartLine size={30} /></NavLink>
          {isAdmin && !isMobile && <NavLink className="NavBottom" to="/users"><FaUserGroup size={30} /></NavLink>}
          {isAdmin && <NavLink className="NavBottom" to="/envios"> <TbTruckDelivery size={32} /></NavLink>}
          {isAdmin && <NavLink className="NavBottom" to="/settings"> <IoSettingsSharp size={32} /></NavLink>}
          <NavLink className="NavBottom" to="" onClick={handleLogout}><FiLogOut size={30} /></NavLink>
        </div>
      </nav>
      <nav className="NavbarBottom">
        <NavLink
          className={selectedNavbar === 'myuser' ? "NavLink-selected" : "NavLink"}
          to="/my_user"
          onMouseDown={() => setSelectedNavbar('myuser')}
          onMouseOver={() => setHoveredIcon('myuser')}
          onMouseOut={() => setHoveredIcon(null)}
        >
          <div className="NavIconContainer">
            <FaCircleUser
              size={42}
              color={selectedNavbar === 'myuser' ? '#000' : hoveredIcon === 'myuser' ? '#333' : 'whitesmoke'}
            />
            <span className="NavLabel" style={{ marginLeft: '0.4rem' }}>{userData?.name}</span>
          </div>
        </NavLink>
        {
          !isMobile &&
          <NavLink
            className={selectedNavbar === 'sales' ? "NavLink-selected" : "NavLink"}
            exact='true'
            to="/"
            onMouseDown={() => setSelectedNavbar('sales')}
            onMouseOver={() => setHoveredIcon('sales')}
            onMouseOut={() => setHoveredIcon(null)}
          >
            <div className="NavIconContainer">
              <MdAttachMoney
                size={32}
                color={selectedNavbar === 'sales' ? '#000' : hoveredIcon === 'sales' ? '#333' : 'whitesmoke'}
              />
              <span className="NavLabel ">Ventas</span>
            </div>
          </NavLink>
        }
        <div className="NavbarLinks">
          <NavLink
            className={selectedNavbar === 'inventary' ? "NavLink-selected" : "NavLink"}
            to="/inventory"
            onMouseDown={() => setSelectedNavbar('inventary')}
            onMouseOver={() => setHoveredIcon('inventary')}
            onMouseOut={() => setHoveredIcon(null)}
          >
            <div className="NavIconContainer">
              <FaBoxArchive
                size={32}
                color={selectedNavbar === 'inventary' ? '#000' : hoveredIcon === 'inventary' ? '#333' : 'whitesmoke'}
              />
              <span className="NavLabel ">Inventario</span>
            </div>
          </NavLink>
          <NavLink
            className={selectedNavbar === 'customers' ? "NavLink-selected" : "NavLink"}
            to="/customers"
            onMouseDown={() => setSelectedNavbar('customers')}
            onMouseOver={() => setHoveredIcon('customers')}
            onMouseOut={() => setHoveredIcon(null)}
          >
            <div className="NavIconContainer">
              <FaUsers
                size={32}
                color={selectedNavbar === 'customers' ? '#000' : hoveredIcon === 'customers' ? '#333' : 'whitesmoke'}
              />
              <span className="NavLabel ">Clientes</span>
            </div>
          </NavLink>
          <NavLink
            className={selectedNavbar === 'utilities' ? "NavLink-selected" : "NavLink"}
            to="/utilities"
            onMouseDown={() => setSelectedNavbar('utilities')}
            onMouseOver={() => setHoveredIcon('utilities')}
            onMouseOut={() => setHoveredIcon(null)}
          >
            <div className="NavIconContainer">
              <FaChartLine
                size={32}
                color={selectedNavbar === 'utilities' ? '#000' : hoveredIcon === 'utilities' ? '#333' : 'whitesmoke'}
              />
              <span className="NavLabel">Utilidades</span>
            </div>
          </NavLink>
          {
            isAdmin && !isMobile &&
            <NavLink
              className={selectedNavbar === 'users' ? "NavLink-selected" : "NavLink"}
              to="/users"
              onMouseDown={() => setSelectedNavbar('users')}
              onMouseOver={() => setHoveredIcon('users')}
              onMouseOut={() => setHoveredIcon(null)}
            >
              <div className="NavIconContainer">
                <FaUserGroup
                  size={32}
                  color={selectedNavbar === 'users' ? '#000' : hoveredIcon === 'users' ? '#333' : 'whitesmoke'}
                />
                <span className="NavLabel ">Usuarios</span>
              </div>
            </NavLink>
          }

          {
            isAdmin && !isMobile &&
            <NavLink
              className={selectedNavbar === 'envios' ? "NavLink-selected" : "NavLink"}
              to="/envios"
              onMouseDown={() => setSelectedNavbar('envios')}
              onMouseOver={() => setHoveredIcon('envios')}
              onMouseOut={() => setHoveredIcon(null)}
            >
              <div className="NavIconContainer">
                <TbTruckDelivery
                  size={32}
                  color={selectedNavbar === 'envios' ? '#000' : hoveredIcon === 'envios' ? '#333' : 'whitesmoke'}
                />
                <span className="NavLabel">Envios</span>
              </div>

            </NavLink>
          }
          <NavLink
            className={selectedNavbar === 'cut' ? "NavLink-selected" : "NavLink"}
            to="/cut"
            onMouseDown={() => setSelectedNavbar('cut')}
            onMouseOver={() => setHoveredIcon('cut')}
            onMouseOut={() => setHoveredIcon(null)}
          >
            <div className="NavIconContainer">
              <IoCut
                size={32}
                color={selectedNavbar === 'cut' ? '#000' : hoveredIcon === 'cut' ? '#333' : 'whitesmoke'}
              />
              <span className="NavLabel ">Corte</span>
            </div>
          </NavLink>
        </div>
        <NavLink
          className={selectedNavbar === 'settings' ? "NavLink-selected" : "NavLink"}
          to="/settings"
          onMouseDown={() => setSelectedNavbar('settings')}
          onMouseOver={() => setHoveredIcon('settings')}
          onMouseOut={() => setHoveredIcon(null)}
        >
          <div className="NavIconContainer">
            <IoSettingsSharp
              size={32}
              color={selectedNavbar === 'settings' ? '#000' : hoveredIcon === 'settings' ? '#333' : 'whitesmoke'}
            />
            <span className="NavLabel ">Configuración</span>
          </div>
        </NavLink>
      </nav>
    </div>
  );
}

export default Navbar;