import Navbar from "../../../components/Navbar/Navbar";
import BACK_END from "../../../config/direction";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FaSave, FaSearch } from "react-icons/fa";
import React, { useContext, useEffect, useState } from "react";
import "./AddEntry.css";
import { MdAddBox, MdCancel, MdDelete } from "react-icons/md";
import { FaTrash } from "react-icons/fa6";
import { BsCalendar3 } from "react-icons/bs";
import axios from "axios";
import Calendar from "react-calendar";
import { SessionContext } from "../../../hooks/useSession";
import { isMobile } from 'react-device-detect';

const AddEntry = () => {
  const { entry_id } = useParams();

  const { userData, token } = useContext(SessionContext);

  const isAdmin = userData?.rol === 'Administrador';

  const [searchedProduct, setSearchedProduct] = useState("");

  const [showId, setShowId] = useState(false);

  const [entryList, setEntryList] = useState(
    JSON.parse(localStorage.getItem("entryList")) ?? []
  );

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [entryData, setEntryData] = useState(
    localStorage.getItem("entryData")
      ? JSON.parse(localStorage.getItem("entryData"))
      : {
        entry_date: new Date(),
        description: "",
      }
  );

  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  // Add the new product and update products
  useEffect(() => {
    const storedProductData = sessionStorage.getItem('productData');
  
    const updateEntryList = async () => {
      let newEntryList = entryList;

      if (entry_id) {
        newEntryList = await fetchEntryDetails();
      }
  
      if (storedProductData) {
        const productData = JSON.parse(storedProductData);
        console.log(productData);
        newEntryList = [
          {
            product_id: productData.productCode,
            name: productData.productName,
            cost: productData.productCost,
            quantity: 1,
          },
          ...newEntryList,
        ];
        sessionStorage.removeItem('productData');
      }
  
      if (newEntryList.length > 0) {
        const productIds = newEntryList.map(product => product.product_id);
        try {
          const response = await axios.get(`${BACK_END}/product/cart`, { params: { productIds } });
          const updatedProducts = response.data;
  
          newEntryList = newEntryList.map(product => {
            const updatedProduct = updatedProducts.find(p => p.product_id === product.product_id);
            return updatedProduct ? { ...product, name: updatedProduct.name, cost: updatedProduct.cost } : product;
          });
        } catch (error) {
          console.error("Error recuperando los costos de productos", error);
        }
      }
      console.log(newEntryList)
  
      setEntryList(newEntryList);
    };
  
    updateEntryList(); // eslint-disable-next-line
  }, []);

  // Save entryList
  useEffect(() => {
    if (!entry_id) {
      localStorage.setItem("entryList", JSON.stringify(entryList));
    } // eslint-disable-next-line
  }, [entryList]);

  // Save entryData
  useEffect(() => {
    if (!entry_id) {
      localStorage.setItem("entryData", JSON.stringify(entryData));
    } // eslint-disable-next-line
  }, [entryData]);

  // Fetch data to edit
  const fetchEntryDetails = async () => {
    try {
      const response = await axios.get(
        `${BACK_END}/entry/single/${entry_id}`
      );
      const { entry, products } = response.data;
      const localtime = new Date(entry.entry_date);

      setEntryData({
        entry_date: localtime,
        description: entry.description,
      });

      return products;
    } catch (error) {
      console.error("Error fetching entry details:", error);
      return null;
    }
  };

  // Format date
  const formatDate = (isoDate) => {
    if (!isoDate) {
      return null;
    }

    const date = new Date(isoDate);
    const today = new Date();

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return "Hoy";
    }

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("es-MX", options);
  };

  // Add a searched product to the list
  const addSearchedProductToCart = async () => {
    await fetch(`${BACK_END}/product/single/${searchedProduct}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();

          // Check if the product with the same product_id is already in the list
          const existingProductIndex = entryList.findIndex(
            (entry) => entry.product_id === data.product_id
          );

          if (existingProductIndex !== -1) {
            // Product already exists, update the quantity
            const updatedEntryList = [...entryList];
            updatedEntryList[existingProductIndex].quantity += 1;

            setEntryList(updatedEntryList);
          } else {
            // Product doesn't exist, add a new entry
            setEntryList([
              {
                product_id: data.product_id,
                name: data.name,
                cost: data.cost,
                price: data.price,
                quantity: 1,
              },
              ...entryList,
            ]);
          }

          toast.success("Producto agregado", {
            hideProgressBar: true,
            autoClose: 2000,
          });
        } else {
          toast.info("Código o nombre inválido", {
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        throw new Error("Error en la solicitud");
      })
      .finally(() => {
        setSearchedProduct("");
      });
  };

  const removeProduct = (product_id) => {
    const updatedEntryList = entryList.filter(
      (product) => product.product_id !== product_id
    );

    setEntryList(updatedEntryList);
    toast.success("Producto eliminado", {
      hideProgressBar: true,
      autoClose: 2000,
    });
  };

  const handleChangeQuantity = (product_id, newQuantity) => {
    // Expresión regular que valida si la cadena es un número entero con hasta 12 dígitos
    const validNumberRegex = /^[0-9]{0,12}$/;

    if (validNumberRegex.test(newQuantity)) {
      const updatedEntryList = entryList.map((product) => {
        if (product.product_id === product_id) {
          return { ...product, quantity: newQuantity };
        }
        return product;
      });

      setEntryList(updatedEntryList);
    } else {
      toast.error(
        "La cantidad debe ser un número válido y tener como máximo 12 dígitos",
        { hideProgressBar: true, autoClose: 2000 }
      );
    }
  };

  const handleInputBlur = (product_id, currentQuantity) => {
    const newQuantity =
      currentQuantity.trim() === "" || parseInt(currentQuantity, 10) === 0
        ? "1"
        : currentQuantity;
    handleChangeQuantity(product_id, newQuantity);
  };

  const uploadRegister = async () => {

    const { entry_date, description } = entryData;

    if(description === '') {
      toast.info('Falta la descripción', { autoClose: 2500, hideProgressBar: true });
      return;
    }

    const timezoneOffset = new Date().getTimezoneOffset();
    const adjustedEntryDate = new Date(entry_date);
    adjustedEntryDate.setMinutes(
      adjustedEntryDate.getMinutes() - timezoneOffset
    );

    await axios
      .post(`${BACK_END}/entry`, {
        entry_date: adjustedEntryDate,
        description,
        products: entryList,
        user_id: userData.user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        localStorage.removeItem("entryList");
        localStorage.removeItem("entryData");
        toast.success(response.data.message, {
          hideProgressBar: true,
          autoClose: 2000,
        });
        navigate(-1);
      })
      .catch((result) => {
        console.error(result);
        toast.error(result.response.data.message, {
          hideProgressBar: true,
          autoClose: 2000,
        });
      });
  };

  // Update register
  const updateRegister = async () => {

    const { entry_date, description } = entryData;

    if(description === '') {
      toast.info('Falta la descripción', { autoClose: 2500, hideProgressBar: true });
      return;
    }

    const timezoneOffset = new Date().getTimezoneOffset();
    const adjustedEntryDate = new Date(entry_date);
    adjustedEntryDate.setMinutes(
      adjustedEntryDate.getMinutes() - timezoneOffset
    );

    try {
      const response = await axios.put(`${BACK_END}/entry`, {
        entry_id: entry_id,
        entry_date: adjustedEntryDate,
        description,
        products: entryList,
        user_id: userData.user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      localStorage.removeItem("entryList");
      localStorage.removeItem("entryData");

      toast.success(response.data.message, {
        hideProgressBar: true,
        autoClose: 2000,
      });
      navigate(-1);
    } catch (error) {
      console.error(error);
      toast.error("Error al actualizar la compra", {
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  };

  // Delete the current register
  const deleteEntry = async () => {
    try {
      const response = await axios.delete(
        `${BACK_END}/entry/${entry_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.message, {
        hideProgressBar: true,
        autoClose: 2000,
      });
      navigate(-1);
    } catch (error) {
      console.error(error);
      toast.error("Error al eliminar la compra", {
        hideProgressBar: true,
        autoClose: 2000,
      });
      setIsDeleting(false);
    }
  };

  // Calculate the total
  const calculateTotal = () => {
    let total = 0;
    entryList.forEach((product) => {
      total += product.quantity * product.cost;
    });
    return total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleEditClick = (product_id) => {
    navigate(`/edit_product/${product_id}`);
  }

  return (
    <div className="add-entry-father">
      <Navbar />
      <div className="add-entry-container">
        <div className="add-entry-card">
          <div className="add-entry-left-column">
            <div className="add-entry-left-top">
              <div className="add-entry-header-column">
                <h2 className="add-entry-search-header">
                  Artículo en existencia
                </h2>
                <div className="add-entry-search-bar-article">
                  <FaSearch size={22} color="black" className="search-icon" />
                  <input
                    type="text"
                    value={searchedProduct}
                    onChange={(event) => setSearchedProduct(event.target.value)}
                    placeholder="Código del artículo"
                    autoFocus={true}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        addSearchedProductToCart();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="add-entry-header-column">
                <h2 className="add-entry-search-header">Artículo nuevo</h2>
                <button
                  className="add-entry-button"
                  onClick={() => navigate("/add_product", { state: { goBack: true } })}
                >
                  <MdAddBox size={28} />
                  <span className="date-entry-label">
                    Registrar Nueva prenda
                  </span>
                </button>
              </div>
            </div>

            <div className="add-entry-table-box-container">
              <div className="add-entry-table-box">
                <div className="add-entry-header-row">
                  <div className="add-entry-header" onClick={() => setShowId(!showId)}>{(!showId) ? 'Artículo' : 'Código'}</div>
                  <div className="add-entry-header">Cantidad</div>
                  {!isMobile && <div className="add-entry-header">Costo unitario</div>}
                  {!isMobile && <div className="add-entry-header">Subtotal</div>}
                  <div className="add-entry-header">Borrar</div>
                </div>

                {entryList.map((product, productIndex) => (
                  <div
                    key={`addentry${productIndex}`}
                    className="add-entry-row"
                    title={(showId) ? `Nombre: ${product.name}` : `Código: ${product.product_id}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleEditClick(product.product_id);
                    }}
                  >
                    <div>{(!showId) ? product.name : product.product_id}</div>
                    <div className="add-entry-quantity-input">
                      <input
                        type="text"
                        value={product.quantity}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        onChange={(event) => handleChangeQuantity(product.product_id, event.target.value)}
                        onBlur={(event) => handleInputBlur(product.product_id, event.target.value)}
                      />
                    </div>
                    {!isMobile && <div>${product.cost}</div>}
                    {!isMobile && <div>${(product.quantity * product.cost).toFixed(2)}</div>}
                    <div>
                      <button
                        className="add-entry-table-button"
                        title="Eliminar producto"
                        onClick={(event) => {
                          event.stopPropagation();
                          removeProduct(product.product_id);
                        }}
                      >
                        <FaTrash size={20} color="crimson" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <h2 className="add-entry-total-bottom">Costo Total: ${calculateTotal()}</h2>
            </div>
          </div>

          <div className="add-entry-right-column">
            <div className="add-entry-right-top">
              <button
                className="date-add-entry-button"
                onClick={() => setShowDatePicker(!showDatePicker)}
              >
                <span className="date-entry-label">Fecha:</span>
                <span className="date-entry-label-center">
                  {formatDate(entryData.entry_date)}
                </span>
                <BsCalendar3 size={28} />
              </button>
            </div>
            {showDatePicker && (
              <div className="date-modal-overlay">
                <div className="date-picker-container">
                  <Calendar
                    onChange={(date) =>
                      setEntryData({ ...entryData, entry_date: date })
                    }
                    value={entryData.entry_date}
                    selectRange={false}
                    showNeighboringMonth={false}
                  />
                  <button onClick={() => setShowDatePicker(false)}>
                    Cerrar
                  </button>
                </div>
              </div>
            )}
            <div className="add-entry-right-top">
              <div className="add-entry-text-field">
                <textarea
                  type="text"
                  rows={3}
                  value={entryData.description}
                  onChange={(event) =>
                    setEntryData({
                      ...entryData,
                      description: event.target.value,
                    })
                  }
                  placeholder="Descripción . . ."
                />
              </div>
            </div>
            <div className="add-entry-right-top">
              <button
                className="add-entry-button-cancel"
                onClick={() => navigate(-1)}
              >
                <MdCancel size={30} color="white" />
                Cancelar
              </button>
              <button
                className="add-entry-button-save golden-button"
                onClick={() => {
                  if(entry_id) {
                    if(isAdmin) {
                      updateRegister();
                    } else {
                      toast.info('Solo un usuario con rol de Administrador puede editar una entrada..', { autoClose: 3000, hideProgressBar: true })
                    }
                  } else {
                    uploadRegister();
                  }
                }}
              >
                <FaSave size={30} color="black" />
                {entry_id ? "Actualizar" : "Guardar"}
              </button>
            </div>
            {entry_id && !isDeleting ? (
              <div className="add-entry-right-top">
                <button
                  className="add-entry-button-delete"
                  onClick={() => setIsDeleting(true)}
                >
                  <MdDelete size={30} color="white" />
                  Eliminar
                </button>
              </div>
            ) : isDeleting ? (
              <div className="add-entry-header-column-delete">
                <div lassName="add-entry-right-top">
                  <h2 class="add-entry-search-header">
                    ¿Desea eliminar el registro?
                  </h2>
                </div>
                <div className="add-entry-right-top">
                  <button
                    className="add-entry-button-cancel add-entry-button-resize"
                    onClick={() => setIsDeleting(false)}
                  >
                    <MdCancel size={30} color="white" />
                    No
                  </button>
                  <button
                    className="add-entry-button-delete add-entry-button-resize"
                    onClick={() => {
                      if(isAdmin) {
                        deleteEntry();
                      } else {
                        toast.info('Solo un usuario con rol de Administrador puede borrar una entrada.', { autoClose: 3000, hideProgressBar: true });
                      }
                    }}
                  >
                    <MdDelete size={30} color="white" />
                    Si
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEntry;
