import { BsFillPersonVcardFill, BsFillCheckCircleFill } from 'react-icons/bs';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaKey, FaTimesCircle, } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar/Navbar';
import BACK_END from '../../config/direction';
import { PiTextTBold } from 'react-icons/pi';
import { FaUser } from "react-icons/fa6";
import Dropdown from 'react-dropdown';
import axios from 'axios';
import './EditUser.css';

const EditUser = () => {

    const { user_id } = useParams();

    const navigate = useNavigate();

    const roleOptions = ['Administrador', 'Personal de caja'];

    const [name, setName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState(null);

    const inputNameRef = useRef();
    const inputUserNameRef = useRef();
    const inputPasswordRef = useRef();

    useEffect(() => {
        // Get user's data
        const getData = () => {
            axios.get(`${BACK_END}/user/single/${user_id}`)
                .then((result) => {
                    const data = result.data;
                    setUserName(data.username);
                    setName(data.name);
                    setRole({ label: data.rol, value: data.rol });
                })
                .catch(() => {

                });
        }
        getData()
    }, [user_id])

    // verify all textfields
    const validFields = () => {
        let isValid = true;

        if (name === '') {
            isValid = false;
            toast.info('Falta el nombre', { hideProgressBar: true, autoClose: 2000 });
        }
        else if (userName === '') {
            isValid = false;
            toast.info('Falta el nombre de usuario', { hideProgressBar: true, autoClose: 2000 });
        }
        else if (role === null) {
            isValid = false;
            toast.info('Falta seleccionar un rol', { hideProgressBar: true, autoClose: 2000 });
        }

        return isValid;
    }

    // Update a user
    const updateUser = () => {
        if (validFields()) {
            axios.put(
                `${BACK_END}/user`,
                {
                    user_id, name,
                    username: userName,
                    password,
                    rol: role?.value
                }
            )
                .then((res) => {
                    const data = res.data;
                    if (res.status === 200) {
                        toast.success(data.message, { hideProgressBar: true, autoClose: 1000 });
                        navigate('/users');
                    }
                });
        }
    }

    return (
        <div>
            <div className='father-container'>
                <Navbar />
                <div className="parent-card">
                    <div className='user-card'>
                        <div className="card-title">
                            <h1 className='product-header-text'>Editar Usuario</h1>

                        </div>
                        <div className="card-body">
                            <div className='add-user-input'>
                                <PiTextTBold size={32} color='black' />
                                <input
                                    autoComplete='off'
                                    maxLength={120}
                                    type='text'
                                    placeholder='Nombre de la persona'
                                    value={name}
                                    onChange={(value) => setName(value.target.value)}
                                    autoFocus
                                    ref={inputNameRef}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            inputUserNameRef.current.focus();
                                        }
                                    }}
                                />
                            </div>

                            <div className='add-user-input'>
                                <FaUser size={32} color='black' />
                                <input
                                    autoComplete='off'
                                    maxLength={120}
                                    type='text'
                                    placeholder='Nombre de usuario'
                                    value={userName}
                                    onChange={(value) => setUserName(value.target.value)}
                                    autoFocus
                                    ref={inputUserNameRef}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            inputPasswordRef.current.focus();
                                        }
                                    }}
                                />
                            </div>

                            <div className='add-user-input'>
                                <FaKey size={32} color='black' />
                                <input
                                    autoComplete='off'
                                    maxLength={120}
                                    type='password'
                                    placeholder='Nueva contraseña'
                                    value={password}
                                    onChange={(value) => setPassword(value.target.value)}
                                    autoFocus
                                    ref={inputPasswordRef}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            inputNameRef.current.focus();
                                        }
                                    }}
                                />
                            </div>

                            <div className='add-user-dropdown'>
                                <BsFillPersonVcardFill size={32} color='black' />
                                <Dropdown
                                    className='user-role-scroll'
                                    options={roleOptions}
                                    onChange={(text) => setRole(text)}
                                    value={role}
                                    placeholder='Seleccione un rol'
                                />
                            </div>

                            <div className='user-button-container'>
                                <button className='cancel-button' onClick={() => navigate(-1)}><FaTimesCircle size={32} color='d06154' />Cancelar</button>
                                <button className='save-button' onClick={updateUser}> <BsFillCheckCircleFill size={32} color='04c055' />Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default EditUser;