import {
  FaPhoneAlt,
  FaUserAlt,
  FaTimesCircle,
  FaCloudUploadAlt,
  FaPlus} from "react-icons/fa";
import { FaTag } from 'react-icons/fa';
import Dropdown from 'react-dropdown';

import Navbar from "../../components/Navbar/Navbar";
import React, { useState, useRef,useEffect  } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./AddCustomer.css";
import BACK_END from "../../config/direction";

const AddCustomer = () => {
  const navigate = useNavigate();

  const [nameState, setNameState] = useState("");
  const [phoneState, setPhoneState] = useState("");
  const [debtState, setDebtState] = useState("");
  const [originState, setOriginState] = useState(null);
  const [originOptions, setOriginOptions] = useState([]);
  const [clientLoaded, setClientLoaded] = useState(false);
  
  const nameRef = useRef("");
  const phoneRef = useRef("");
  const debtRef = useRef(0);

  const clientData = {
    name: nameState,
    phone: phoneState
  };

  const toastySuccess = (msg) => {
    navigate(-1);
    toast.success(`${msg}`);
  };

  const toastyError = (msg) => {
    toast.error(`${msg}`);
  };
  const toastyWarn = (msg) => {
    toast.warn(`${msg}`);
  };
  const toastyInfo = (msg) => {
    toast.info(`${msg}`);
  };

  const cleanFields = () => {
    setNameState("");
    setPhoneState("");
    setDebtState("");
    setOriginState(null);
  };

  const saveClientData = () => {
    if (clientLoaded) {
      localStorage.setItem("newClient", JSON.stringify(clientData));
    }
  };

  // eslint-disable-next-line
  useEffect(saveClientData, [clientLoaded]);

  const loadClient = () => {
    const json = localStorage.getItem("newClient");

    if (json) {
      const data = JSON.parse(json);

      setNameState(data.name);
      setPhoneState(data.phone);
    }
    setClientLoaded(true);
  };

  // Upload a client
  const uploadClient = () => {
    if (phoneState.length < 10) {
      toastyInfo("Número de teléfono inválido");
      return;
    }

    if(!originState?.value) {
      toastyInfo("Falta seleccionar el origen del cliente");
      return;
    }

    const nameChanged = nameRef.current !== nameState ? true : false;
    const phoneChanged = phoneRef.current !== phoneState ? true : false;

    if (nameChanged && phoneChanged) {
      const formData = {
        name: nameState,
        phone: phoneState,
        debt: debtState === "" ? 0 : debtState,
        origin_id: originState.value
      };
      
      fetch(`${BACK_END}/customer`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      })
        .then(async (res) => {
          const data = await res.json();
          if (res.ok) {
            cleanFields();
            toastySuccess(data.message);
          } else {
            toastyInfo(data.error);
            console.log(res.error);
          }
        })
        .catch((error) => {
          toastyError("Ocurrió un error inesperado");
          console.log(error);
        });
    } else {
      toastyWarn("El nombre y telefono son requeridos");
    }
  };

  // Get dropdown for product info
  useEffect(() => {
    fetch(`${BACK_END}/origin`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          const data = response.data;
          const dataPackage = data.map((origin) => ({
            label: origin.name,
            value: origin.id,
          }));
          setOriginOptions(dataPackage);
        }
      })
      .catch((error) => console.log(error));
      loadClient();
  }, []);

  const handlePhoneChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      setPhoneState(inputValue);
    }
  };

  return (
    <>
      <div className="parent-add-customer">
        <Navbar />
        <div className="customer-container">
          <div className="add-customer-card shadow-div">
            <div className="header-container">
              <h3 className="add-customer__header">Agregar cliente</h3>
            </div>
            <div className="body-container">
              <div className="user-fields">
                <div className="customer-input-row shadow-div">
                  <FaUserAlt size={26} color="000" />
                  <input
                    className="customer-input generic-input"
                    type="text"
                    ref={nameRef}
                    placeholder="Nombre completo"
                    value={nameState}
                    onChange={(e) => setNameState(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        phoneRef.current.focus();
                      }
                    }}
                    maxLength={120}
                  />
                </div>

                <div className="customer-input-row shadow-div">
                  <FaPhoneAlt size={24} color="000" />
                  <input
                    ref={phoneRef}
                    className="customer-input generic-input"
                    type="text"
                    placeholder="Tel&eacute;fono"
                    value={phoneState}
                    maxLength={10}
                    onChange={handlePhoneChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        debtRef.current.focus();
                      }
                    }}
                  />
                </div>
              </div>

              <div className="drop-row-EC">
                <div className="add-customer-input shadow-div">
                  <FaTag size={32} color="black" />
                  <Dropdown
                    className="add-customer-scroll"
                    options={originOptions}
                    onChange={( text) =>
                      setOriginState(text)
                      
                    }
                    value={originState}
                    placeholder="Origen del cliente"
                  />
                </div>

                <button
                  className="dropdown-button shadow-div"
                  title="Agregar Origen"
                  onClick={() => navigate("/add_list/origin")}
                >
                  <FaPlus size={20} color="black" />
                </button>
              </div>

              <div className="add-customer-buttons">
                <button
                  className="add-cancel-button hover-shadow click-shadow"
                  onClick={() => navigate(-1)}
                >
                  <FaTimesCircle size={28} color="F44336" />
                  Regresar
                </button>
                <button
                  className="add-save-button hover-shadow click-shadow"
                  onClick={uploadClient}
                >
                  {" "}
                  <FaCloudUploadAlt size={28} color="04c055" />
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
