import * as XLSX from 'xlsx';

const exportToExcel = (data, filename) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');

    const columnWidth = 20;
    const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
    ws['!cols'] = Array(columnCount).fill({ width: columnWidth });

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10).replace(/-/g, '-');
    filename = `${filename} ${formattedDate}`;

    XLSX.writeFile(wb, filename + '.xlsx');
}

export { exportToExcel };