import {
  FaBarcode,
  FaTag,
  FaRegistered,
  FaTimesCircle,
  FaPlus,
  FaBox,
} from "react-icons/fa";
import { HiMiniRectangleGroup } from "react-icons/hi2";
import {
  RiMoneyDollarCircleFill,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";
import React, { useEffect, useState, useRef } from "react";
import { AiOutlinePercentage } from "react-icons/ai";
import Navbar from "../../components/Navbar/Navbar";
import "react-toastify/dist/ReactToastify.css";
import BACK_END from "../../config/direction";
import { useLocation, useNavigate } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { PiTextTBold } from "react-icons/pi";
import { TbPhotoPlus, TbShoppingCartDollar, TbDiscount } from "react-icons/tb";
import { toast } from "react-toastify";
import Dropdown from "react-dropdown";
import { PiBroomFill } from "react-icons/pi";
import "./AddProduct.css";
import axios from "axios";

const AddProduct = () => {
  const navigate = useNavigate();

  const [groupOptions, setGroupOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const [isAddingOffer, setIsAddingOffer] = useState(false);
  const [productLoaded, setProductLoaded] = useState(false);

  const [productData, setProductData] = useState({
    
    imageUrl: null,
    productImage: null,
    productName: "",
    productCode: "",
    productStock: "",
    selectedGroup: null,
    selectedDepartment: null,
    productDescription: "",
    productCost: "",
    productPrice: "",
    productMargin: "",
    productOfferPrice: "",
    productOfferPercent: "",
  },
  console.log(departmentOptions)
);

  const location = useLocation();
  const goBack = location.state?.goBack;

  const inputImageRef = useRef(null);
  const inputProductNameRef = useRef(null);
  const inputProductCodeRef = useRef(null);
  const inputProductStockRef = useRef(null);
  const inputProductCostRef = useRef(null);
  const inputProductMarginRef = useRef(null);
  const inputProductPriceRef = useRef(null);
  const inputProductPriceOfferRef = useRef(null);
  const inputProductOfferPercentRef = useRef(null);
  const inputProductDescriptionRef = useRef(null);

  const cleanFields = () => {
    setProductData({
      imageUrl: null,
      productImage: null,
      productName: "",
      productCode: "",
      productStock: "",
      selectedDepartment: null,
      productDescription: "",
      productCost: "",
      productPrice: "",
      productMargin: "",
      productOfferPrice: "",
      productOfferPercent: "",
    });
  };

  // Delete .00 from a String
  const removeZeros = (value) => {
    return value.replace(/\.00/, "");
  };

  // Handle stock's input
  const onChangeStock = (event) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      setProductData({
        ...productData,
        productStock: inputValue,
      });
    }
  };

  // Verify if a field is empty
  const isAnyEmpty = () => {
    let isEmpty = false;

    if (productData.productName === "") {
      toast.info("Falta el nombre", { autoClose: 3000 });
      isEmpty = true;
    } else if (productData.productCode === "") {
      toast.info("Falta el código", { autoClose: 3000 });
      isEmpty = true;
    } else if (productData.productStock === "") {
      toast.info("Falta el stock", { autoClose: 3000 });
      isEmpty = true;
    } else if (productData.productDescription === null) {
      toast.info("Falta la descripción", { autoClose: 3000 });
      isEmpty = true;
    } else if (productData.selectedDepartment === null) {
      toast.info("Falta el grupo", { autoClose: 3000 });
      isEmpty = true;
    } else if (productData.selectedDepartment === null) {
      toast.info("Falta la categoría", { autoClose: 3000 });
      isEmpty = true;
    } else if (productData.productCost === "") {
      toast.info("Falta el costo", { autoClose: 3000 });
      isEmpty = true;
    } else if (productData.productMargin === "") {
      toast.info("Falta el margen", { autoClose: 3000 });
      isEmpty = true;
    } else if (productData.productPrice === "") {
      toast.info("Falta el precio", { autoClose: 3000 });
      isEmpty = true;
    } else if (isAddingOffer && (productData.productOfferPrice === "" || productData.productOfferPercent === "")) {
      toast.info("Falta el descuento", { autoClose: 3000 });
      isEmpty = true;
    }

    return isEmpty;
  };

  // Upload a product
  const uploadProduct = () => {
    if (!isAnyEmpty()) {
      const formData = new FormData();

      formData.append("image", productData.productImage);
      formData.append("name", productData.productName);
      formData.append("product_id", productData.productCode);
      formData.append("stock", productData.productStock);
      formData.append("description", productData.productDescription);
      formData.append("department_id", productData.selectedDepartment.value);
      formData.append("cost", productData.productCost);
      formData.append("price", productData.productPrice);
      formData.append("offer_price", productData.productOfferPrice);

      axios
        .post(`${BACK_END}/product`, formData)
        .then(async (res) => {
          if (res.status === 200) {
            toast.success(res.data.message, { autoClose: 3000 });
            if (goBack) {
              sessionStorage.setItem(
                "productData",
                JSON.stringify(productData)
              );
              navigate(-1);
              cleanFields();
            } else {
              cleanFields();
            }
          } else {
            //console.log(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            const data = error?.response?.data?.error || 'Algo salió mal.';
            toast.error(data, { autoClose: 5000 });
          } else if (error.request) {
            console.error("No se recibió respuesta del servidor");
          } else {
            console.error(error);
          }
        });
    }
  };

  // Handle Product's cost
  const handleProductCost = (event) => {
    let productDataUpdated = { ...productData };
    const inputCost = event.target.value;

    if (/^\d*\.?\d{0,2}$/.test(inputCost)) {
      productDataUpdated.productCost = inputCost;
    }

    if (inputCost === "") {
      productDataUpdated.productMargin = "";
    } else if (productData.productMargin !== "") {
      const cost = parseFloat(inputCost);
      const margin = parseFloat(productData.productMargin);
      const price = (cost + cost * (margin / 100.0)).toFixed(2);
      productDataUpdated.productPrice = removeZeros(price.toString());
    } else if (inputCost !== "") {
      const cost = parseFloat(inputCost);
      const price = parseFloat(productData.productPrice);

      const diff = price - cost;

      if (diff > 0) {
        const margin = ((diff / cost) * 100).toFixed(2);
        productDataUpdated.productMargin = removeZeros(margin.toString());
      } else {
        productDataUpdated.productMargin = "";
      }
    }
    setProductData(productDataUpdated);
  };

  // Handle Product's margin
  const handleProductMargin = (event) => {
    let productDataUpdated = { ...productData };

    const inputMargin = event.target.value;

    if (/^\d*\.?\d{0,2}$/.test(inputMargin)) {
      productDataUpdated.productMargin = inputMargin;
    }

    if (productData.productCost !== "") {
      const cost = parseFloat(productData.productCost);
      const margin = parseFloat(inputMargin);
      const price = (cost + cost * (margin / 100.0)).toFixed(2);
      if (!isNaN(price)) {
        productDataUpdated.productPrice = removeZeros(price.toString());
      }
    }

    setProductData(productDataUpdated);
  };

  // Handle Product's price
  const handleProductPrice = (event) => {
    let productDataUpdated = { ...productData };

    const inputPrice = event.target.value;

    if (/^\d*\.?\d{0,2}$/.test(inputPrice)) {
      productDataUpdated.productPrice = inputPrice;
    }

    if (inputPrice === "") {
      productDataUpdated.productMargin = "";
    } else if (productData.productCost !== "") {
      const cost = parseFloat(productData.productCost);
      const price = parseFloat(inputPrice);

      const diff = price - cost;

      if (diff > 0) {
        const margin = ((diff / cost) * 100).toFixed(2);
        productDataUpdated.productMargin = removeZeros(margin.toString());
      } else {
        productDataUpdated.productMargin = "";
      }
    }

    setProductData(productDataUpdated);
  };

  // Handle Product's offer price
  const handleProductOfferPrice = (event) => {

    if(productData.productPrice === '') {
      toast.info('Ingrese el precio primero', { autoClose: 3000, hideProgressBar: true });
      return;
    }

    let productDataUpdated = { ...productData };

    const inputOfferPrice = event.target.value;

    if (/^\d*\.?\d{0,2}$/.test(inputOfferPrice)) {
      productDataUpdated.productOfferPrice = inputOfferPrice;
    } else {
      return;
    }

    if (inputOfferPrice === "") {
      productDataUpdated.productOfferPercent = "";
    } else {

      const price = parseFloat(productDataUpdated.productPrice);
      const offerPrice = parseFloat(inputOfferPrice);

      if (offerPrice < 0 || offerPrice > price) {
        return;
      }

      const percentage = (offerPrice * 100 / price).toFixed(2);
      productDataUpdated.productOfferPercent = percentage;
    }

    setProductData(productDataUpdated);
  };

  // Handle Product's offer percient
  const handleProductOfferPercent = (event) => {

    if(productData.productPrice === '') {
      toast.info('Ingrese el precio primero', { autoClose: 3000, hideProgressBar: true });
      return;
    }
    
    let productDataUpdated = { ...productData };
    const inputOfferPercent = event.target.value;

    if (/^\d*\.?\d{0,2}$/.test(inputOfferPercent)) {
      productDataUpdated.productOfferPercent = inputOfferPercent;
    } else {
      return;
    }

    if (inputOfferPercent === "") {
      productDataUpdated.productOfferPrice = "";
    } else {
      const price = parseFloat(productDataUpdated.productPrice);
      const offerPercent = parseFloat(inputOfferPercent);

      if (offerPercent < 0 || offerPercent > 100) {
        return;
      }

      const offerPrice = (price - (price * offerPercent / 100)).toFixed(2);
      
      productDataUpdated.productOfferPrice = offerPrice;
    }

    setProductData(productDataUpdated);
  };

  useEffect(() => {
    setProductData((prevProductData) => ({
      ...prevProductData,
      productOfferPrice: '',
      productOfferPercent: '',
    }));
  }, [productData.productPrice, setProductData]);

  // Save product data
  const saveProduct = () => {
    if (productLoaded) {
      localStorage.setItem("newProduct", JSON.stringify(productData));
    }
  };

  // Save product data each time these values ​​are updated.
  useEffect(saveProduct, [productData, productLoaded]);

  // Load product stored
  const loadProduct = () => {
    const json = localStorage.getItem("newProduct");

    if (json) {
      const data = JSON.parse(json);

      // Restore data with image
      if (data.imageUrl) {
        fetch(data.imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const imageFile = new File([blob], "productImage.jpg", {
              type: "image/jpeg",
            });
            data.productImage = imageFile;
            setProductData(data);
          })
          .catch((error) => {
            console.error("Error al cargar la imagen: ", error);
          });
      }
      // Restore data without image
      else {
        setProductData(data);
      }
    }
    setProductLoaded(true);
  };

  // Get dropdown for product info
  useEffect(() => {
    fetch(`${BACK_END}/group`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          const data = response.data;
          const dataPackage = data.map((brand) => ({
            label: brand.name,
            value: brand.id,
          }));
          setGroupOptions(dataPackage);
        }
      })
      .catch((error) => console.log(error));
    loadProduct();
  }, []);

  useEffect(() => {
    if (productData.selectedGroup !== null && productData.selectedGroup !== undefined && groupOptions.length > 0) {
      fetch(`${BACK_END}/department?group_id=${productData.selectedGroup?.value}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          if (res.ok) {
            const response = await res.json();
            const data = response.data;
            const dataPackage = data.map((department) => ({
              label: department.name,
              value: department.id,
            }));
            setDepartmentOptions(dataPackage);
          }
        })
        .catch((error) => {
          setDepartmentOptions([]);
          console.error(error);
        });
    }
  }, [groupOptions, productData.selectedGroup]);

  // Select an image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProductData({
        ...productData,
        imageUrl: imageUrl,
        productImage: file,
      });
    }
  };

  const handleDivClick = () => {
    inputImageRef.current.click();
  };

  // Render component
  return (
    <div className="add-product-father">
      <Navbar />
      <div className="add-product-father-container">
        <div className="add-product-card">
          <h3 className="add-product-header-text">Registrar Artículo</h3>

          <div className="add-product-row">
            <div
              className="add-product-image"
              onClick={handleDivClick}
              title={productData.productImage ? "Cambiar foto" : "Subir foto"}
            >
              <button className="add-product-image-button">
                {productData.imageUrl ? (
                  <img src={productData.imageUrl} alt="Imagen seleccionada" />
                ) : (
                  <TbPhotoPlus className="photo-product"  color="black" />
                )}
              </button>
              <div className="file-upload-container">
                <label>
                  {productData.productImage ? "Cambiar foto" : "Agregar foto"}
                </label>
                <input
                  ref={inputImageRef}
                  type="file"
                  multiple={false}
                  id="upload-image"
                  accept="image/*"
                  formEncType="multipart/form-data"
                  className="add-image-button"
                  onClick={() => { }}
                  onChange={handleImageUpload}
                />
              </div>
            </div>

            <div className="right-container">
              <div className="add-product-input">
                <PiTextTBold size={28} color="black" />
                <input
                  maxLength={120}
                  type="text"
                  placeholder="Nombre del producto"
                  value={productData.productName}
                  onChange={(event) =>
                    setProductData({
                      ...productData,
                      productName: event.target.value,
                    })
                  }
                  autoFocus
                  ref={inputProductNameRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      inputProductCodeRef.current.focus();
                    }
                  }}
                />
              </div>

              <div className="add-product-input">
                <FaBarcode size={32} color="black" />
                <input
                  maxLength={35}
                  type="text"
                  placeholder="Código de barras"
                  value={productData.productCode}
                  onChange={(event) =>
                    setProductData({
                      ...productData,
                      productCode: event.target.value,
                    })
                  }
                  ref={inputProductCodeRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      inputProductDescriptionRef.current.focus();
                    }
                  }}
                />
              </div>

              <div className="add-productDesc-input">
                <FaRegistered size={32} color="black" />
                <textarea
                  id="text-areaDesc"
                  maxLength={250}
                  placeholder="Descripción del producto"
                  value={productData.productDescription}
                  onChange={(event) =>
                    setProductData({
                      ...productData,
                      productDescription: event.target.value,
                    })
                  }
                  ref={inputProductDescriptionRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // Previniendo el comportamiento por defecto para Enter en un textarea
                      e.preventDefault();
                      inputProductStockRef.current.focus();
                    }
                  }}
                  style={{
                    flex: 1,
                    padding: "8px",
                    marginLeft: "4px",
                    border: "none",
                    backgroundColor: "transparent",
                    fontSize: "1rem",
                    outline: "none",
                    fontFamily: '"readex"',
                    resize: "none", // Opcional, para prevenir que el usuario cambie el tamaño
                  }}
                />
              </div>

              <div className="drop-row">
                <div className="add-product-input">
                  <HiMiniRectangleGroup size={32} color="black" />
                  <Dropdown
                    className="add-product-department-scroll"
                    options={groupOptions}
                    onChange={(text) =>
                      setProductData({
                        ...productData,
                        selectedGroup: text,
                        selectedDepartment: null,
                      })
                    }
                    value={productData.selectedGroup}
                    placeholder="Seleccionar grupo"
                  />
                </div>

                <button
                  className="dropdown-button"
                  title="Agregar departamento"
                  onClick={() => navigate("/add_list/group")}
                >
                  <FaPlus size={20} color="black" />
                </button>
              </div>

              <div className="drop-row">
                <div className="add-product-input">
                  <FaTag  className="box"
                   color="black" />
                  <Dropdown
                    className="add-product-department-scroll"
                    options={departmentOptions}
                    onChange={(text) =>
                      setProductData({
                        ...productData,
                        selectedDepartment: text,
                      })
                    }
                    value={productData.selectedDepartment}
                    placeholder="Seleccionar departamento"
                  />
                </div>

                <button
                  className="dropdown-button"
                  title="Agregar departamento"
                  onClick={() => navigate("/add_list/department", { state: { group_id: productData.selectedGroup.value } })}
                >
                  <FaPlus size={20} color="black" />
                </button>
              </div>

              <div className="add-product-input">
                <FaBox size={28} color="black" />
                <input
                  type="text"
                  placeholder="Stock"
                  value={productData.productStock}
                  onChange={onChangeStock}
                  ref={inputProductStockRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      inputProductCostRef.current.focus();
                    }
                  }}
                />
              </div>

              <div className="add-product-row-button">
                <div className="little-add-product-input">
                  <RiMoneyDollarCircleLine size={32} color="black" />
                  <input
                    maxLength={12}
                    type="text"
                    placeholder="Costo"
                    value={productData.productCost}
                    onChange={handleProductCost}
                    className="number-input"
                    ref={inputProductCostRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        inputProductMarginRef.current.focus();
                      }
                    }}
                  />
                </div>

                <div className="little-add-product-input">
                  <AiOutlinePercentage size={28} color="black" />
                  <input
                    maxLength={12}
                    type="text"
                    placeholder="Margen"
                    value={productData.productMargin}
                    onChange={handleProductMargin}
                    className="number-input"
                    ref={inputProductMarginRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        inputProductPriceRef.current.focus();
                      }
                    }}
                  />
                </div>

                <div className="little-add-product-input">
                  <RiMoneyDollarCircleFill size={32} color="black" />
                  <input
                    maxLength={12}
                    type="text"
                    placeholder="Precio"
                    value={productData.productPrice}
                    onChange={handleProductPrice}
                    ref={inputProductPriceRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        inputProductNameRef.current.focus();
                      }
                    }}
                  />
                </div>
              </div>

              {
                (!isAddingOffer)
                  ?
                  <button
                    className="add-product-offer-button"
                    onClick={() => setIsAddingOffer(true)}
                  >
                    <BiSolidOffer size={32} color="d1b104" />
                    Agregar oferta
                  </button>
                  :
                  <div className="add-product-column">
                    <div className="add-product-row-button">
                      <div className="little-add-product-input">
                        <TbShoppingCartDollar size={32} color="black" />
                        <input
                          maxLength={12}
                          type="text"
                          placeholder="Precio con descuento"
                          value={productData.productOfferPrice}
                          onChange={handleProductOfferPrice}
                          ref={inputProductPriceOfferRef}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              inputProductNameRef.current.focus();
                            }
                          }}
                        />
                      </div>

                      <div className="little-add-product-input">
                        <TbDiscount size={32} color="black" />
                        <input
                          maxLength={12}
                          type="text"
                          placeholder="Porcentaje de descuento"
                          value={productData.productOfferPercent}
                          onChange={handleProductOfferPercent}
                          ref={inputProductOfferPercentRef}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              inputProductNameRef.current.focus();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <button
                      className="add-product-offer-button"
                      onClick={() => {
                        setIsAddingOffer(false);
                        setProductData({
                          ...productData,
                          productOfferPrice: '',
                          productOfferPercent: '',
                        })
                      }}
                    >
                      <BiSolidOffer size={32} color="d1b104" />
                      Eliminar oferta
                    </button>
                  </div>
              }

              <div className="product-button-container">

                  <button
                    className="add-product-cancel-button"
                    onClick={() => {
                      cleanFields();
                    }}
                    style={{ width: '25%' }}
                  >
                    <PiBroomFill size={32} color="000"/>
                    
                  </button>
                <button
                  className="add-product-cancel-button"
                  onClick={() => navigate(-1)}
                >
                  <FaTimesCircle className="icon-buton" size={32} color="d06154" />
                  Volver
                </button>
                <button
                  className="add-product-save-button"
                  onClick={uploadProduct}
                >
                  {" "}
                  <MdCheckCircle className="icon-buton" size={32} color="04c055" />
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
