import { FaTimesCircle, FaMinus, FaPlus, FaUserAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import React, { useEffect, useState } from 'react';
import { SiMoneygram } from "react-icons/si";
import './Refund.css';
import BACK_END from '../../config/direction';
import { toast } from 'react-toastify';
import logo_white from '../../assets/logo-white.png';
import { TbCircleLetterP } from "react-icons/tb";
import { MdAddCircle, MdCancel } from 'react-icons/md';
import { openCashBox } from '../../util/pos_esc';

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';
import { formatNumber } from '../../util/formatter';

const Refund = () => {

    const { userData } = useContext(SessionContext);

    const { refund_id } = useParams();
    const navigate = useNavigate();

    const [bill, setBill] = useState(null);
    const [cart, setCart] = useState([]);
    const [toRefund, setToRefund] = useState([]);

    const [total, setTotal] = useState('');

    const [finished, setFineshed] = useState(false);

    // Customer data
    const [nameOrPhone, setNameOrPhone] = useState('');
    const [customer, setCustomer] = useState(null);

    const [customerNotExist, setCustomerNotExist] = useState(false);

    const [cartNumberProducts, setCartNumberProducts] = useState(0);
    const [numberOfItemsRefunded, setNumberOfItemsRefunded] = useState(0);

    // Obtains the customer profile by name or phone
    const fetchCustomer = () => {
        if (nameOrPhone !== '') {
            fetch(
                `${BACK_END}/customer/fetch/${nameOrPhone}`,
                {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'default',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                }
            )
                .then(async (res) => {
                    const response = await res.json();
                    if (res.ok) {
                        if (response.length > 0) {
                            setCustomer(response[0]);
                            setNameOrPhone(response[0].name);
                            toast.success('Cliente encontrado', { autoClose: 1000, hideProgressBar: true });
                        }
                        else {
                            toast.warn('Cliente sin registrar', { hideProgressBar: true, autoClos: 2600 });
                            setCustomerNotExist(true);
                        }
                    }
                    else {
                        toast.error('Ocurrió un error inesperado', { hideProgressBar: true, autoClos: 2500 });
                        console.log(res.error);
                    }
                });
        }
        else {
            toast.info('Inserte un dato para buscar', { hideProgressBar: true, autoClos: 1000 });
        }
    }

    const validateSelectedProducts = () => {
        return toRefund.some(item => item.amount > 0);
    }

    // To refund products
    const refundProducts = () => {

        if (Number(bill.debt) > 0 && cartNumberProducts === 2) {
            const discount1 = Number(cart[0].discount);
            const discount2 = Number(cart[1].discount);

            if ((discount1 === 100 && discount2 === 0) || (discount1 === 0 && discount2 === 100)) {

                const numberOfItemsRefunded = toRefund.reduce((acc, item) => acc + item.amount, 0);

                if (numberOfItemsRefunded !== 2) {
                    toast.info('Los apartados con promoción 2x1, deben devolver los 2 artículos.');
                    return;
                }
            }
        }

        if (!customer) {
            toast.info('Falta el cliente', { autoClose: 3000 });
            return;
        }

        if (!validateSelectedProducts()) {
            toast.info('No ha selecionado los productos a reembolsar.', { autoClose: 4000 });
            return;
        }

        fetch(
            `${BACK_END}/refund/`,
            {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({ cart: toRefund, user_id: userData.user_id, customer_id: customer.customer_id }),
            }
        )
            .then(async (res) => {
                const response = await res.json();
                if (res.ok) {
                    toast.success(response.message, { hideProgressBar: false, autoClose: 5000 });
                    openCashBox();
                    setFineshed(true);
                    setCustomer({ ...customer, points: Number(customer.points) + Number(total) })
                    navigate(-1);
                } else {
                    const message = response?.error;
                    if (message) {
                        toast.error(message);
                    } else {
                        toast.error('Error, no se pudo reembolsar.');
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Error, algo salió mal.');
            });
    }

    // Add product to the cart
    const additionProduct = (product) => {

        const cartIndex = cart.findIndex(
            (item) => item.product_id === product.product.product_id && item.discount === product.product.discount
        );
        const productIndex = toRefund.findIndex(
            (item) => item.product.product_id === product.product.product_id && item.product.discount === product.product.discount
        );
    
        if (productIndex !== -1) {
            const cartRefounds = cart.reduce((acc, item) => {
                if (item.product_id === toRefund[productIndex].product.product_id && item.discount === toRefund[productIndex].product.discount) {
                    return acc + item.refund;
                } else {
                    return acc;
                }
            }, 0);
    
            const isRemaining = toRefund[productIndex].amount < (cart[cartIndex].quantity - cartRefounds);
    
            if (toRefund[productIndex].amount < cart[cartIndex].quantity && isRemaining) {
                let updatedRefund = [...toRefund];
                updatedRefund[productIndex].amount += 1;
                setToRefund(updatedRefund);
            } else {
                toast.info('Límite de producto en el ticket alcanzado', { autoClose: 6000, hideProgressBar: true });
            }
        }
    };    

    // Number of products in the cart
    // const numberOfProductsInCart = () => cart.reduce((acc, producto) => acc + producto.quantity, 0);

    // Calculate the number of items not refunded
    const getNumberOfItemsRefunded = () => {
        return cart.reduce((acc, item) => acc + item.refund, 0);
    }

    // Subtract product from the cart
    const subtractProduct = (product) => {
        const productIndex = toRefund.findIndex(
            (item) => item.product.product_id === product.product.product_id && item.product.discount === product.product.discount
        );
    
        if (productIndex !== -1) {
            let updatedRefund = [...toRefund];
            if (updatedRefund[productIndex].amount > 1) {
                updatedRefund[productIndex].amount -= 1;
                setToRefund(updatedRefund);
            } else if (updatedRefund[productIndex].amount === 1) {
                updatedRefund[productIndex].amount = 0;
                setToRefund(updatedRefund);
            } else {
                toast.info('Límite mínimo del producto alcanzado', { autoClose: 2000, hideProgressBar: true });
            }
        }
    };    

    // Get products' list
    useEffect(() => {
        fetch(
            `${BACK_END}/refund/${refund_id}`,
            {
                method: 'GET',
                mode: 'cors',
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
            }
        )
            .then(async (res) => {
                const response = await res.json();

                if (res.ok) {
                    const billData = response.bill;
                    const cartData = response.cart;

                    if (response.bill.length > 0) {
                        setBill(billData[0]);
                        if (billData[0].customer_id) {
                            fetch(`${BACK_END}/customer/${billData[0].customer_id}`, {
                                method: "GET",
                                mode: "cors",
                                cache: "default",
                                credentials: "same-origin",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                redirect: "follow",
                                referrerPolicy: "no-referrer",
                            }).then(async (res) => {
                                const response = await res.json();
                                if (res.ok) {
                                    setCustomer(response[0]);
                                    setNameOrPhone(response[0].name);
                                }
                            });
                        }
                    } else {
                        return;
                    }

                    if (cartData.length > 0) {
                        setCartNumberProducts(cartData.length);
                        const groupedCart = cartData.reduce((acc, item) => {
                            const existingItem = acc.find(
                                (x) => x.product_id === item.product_id && x.discount === item.discount
                            );
                        
                            if (existingItem) {
                                existingItem.quantity += item.quantity;
                                if (item.refund > 0) {
                                    existingItem.refund += 1;
                                }
                            } else {
                                acc.push(item);
                            }
                        
                            return acc;
                        }, []);
                        setCart(groupedCart);

                        const groupedToRefund = cartData.reduce((acc, item) => {
                            const existingItem = acc.find((x) => 
                                x.product.product_id === item.product_id && x.product.discount === item.discount
                            );
                            if (!existingItem) {
                                const newItem = {
                                    product: item, 
                                    amount: 0, 
                                    originalAmount: 1, 
                                    remaining: 0 
                                };
                                if (!(item.refund > 0)) {
                                    newItem.remaining += 1;
                                }
                                acc.push(newItem);
                            } else {
                                existingItem.originalAmount += 1;
                                if (!(item.refund > 0)) {
                                    existingItem.remaining += 1;
                                }
                            }
                            return acc;
                        }, []);
                        setToRefund(groupedToRefund);
                    }
                    else {
                        toast.info(`El ticket de código '${refund_id}' no existe`, { hideProgressBar: false, autoClose: 5000 });
                    }
                }
                else {
                    toast.error('Ocurrio un error inesperado', { hideProgressBar: false, autoClose: 5000 });
                    console.log(res.error);
                }
            });
    }, [refund_id]);

    useEffect(() => {
        const numberOfItemsRefunded = getNumberOfItemsRefunded();
        console.log("asd", numberOfItemsRefunded)
        setNumberOfItemsRefunded(numberOfItemsRefunded); // eslint-disable-next-line
    }, [cart]);

    // update total
    useEffect(() => {
        let calculatedTotal = 0;

        if (cartNumberProducts !== 2) {
            calculatedTotal = toRefund.reduce((acc, item) => {
                return acc + item.amount * (Number(item.product.price) - Number(item.product.price) * (bill.discount / 100));
            }, 0);
        } else {
            calculatedTotal = toRefund.reduce((acc, item) => {
                return acc + item.amount * Math.max(...cart.map(item => Number(item.price))) / 2;
            }, 0);
        }

        setTotal(formatNumber(calculatedTotal));
        // eslint-disable-next-line
    }, [toRefund]);

    return (
        <div className='refund-parent-container'>
            <Navbar />
            <div className='refund-container'>
                <div className="refund-center">
                    <div className='refund-card'>
                        <h1>Reembolsos</h1>
                        <div className='refund-list-container'>
                            <div className='refund-card-content'>
                                {
                                    (toRefund.length > 0)
                                        ?
                                        <>
                                            <h2 className='refund-list-container-text'>Seleccione los productos a devolver.</h2>
                                            <br />
                                            <div className='products-box'>
                                                {
                                                    toRefund.map((item, index) => {
                                                        return (
                                                            <div className='sales-product-column' key={`${item?.product?.product_id}-${item?.product?.discount}`}>
                                                                <div className='sales-product-row'>
                                                                    <p className={`juguete ${(item.amount === 0) ? 'refund-text-disabled' : ''}`}>{item.product.name}</p>
                                                                    {
                                                                        (item.remaining > 0)
                                                                            ?
                                                                            <div className='sales-button-row'>
                                                                                <button className='sale-pad-button' onClick={() => additionProduct(item)}>
                                                                                    <FaPlus size={16} color={(item.amount > 0) ? 'black' : '#676464'} />
                                                                                </button>
                                                                                <button className='sale-pad-button' onClick={() => subtractProduct(item)}>
                                                                                    <FaMinus size={16} color={(item.amount > 0) ? 'black' : '#676464'} />
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>
                                                                <div className='sales-product-row'>
                                                                    <p className={`juguete ${(item.amount === 0) ? 'refund-text-disabled' : ''}`} key={`a${index}`} >({item.amount} de {item.originalAmount})</p>
                                                                    <span className={`sales-dot-separator ${(item.amount === 0) ? 'refund-dot-disabled' : ''}`}></span>
                                                                    {
                                                                        (bill.discount > 0)
                                                                            ?
                                                                            <p className={`juguete ${(item.amount === 0) ? 'refund-text-disabled' : ''}`} key={`b${index}`}>
                                                                                ${(Number(item.product.price) - Number(item.product.price) * (bill.discount / 100))}
                                                                                <span className='discount-text'>({parseInt(bill.discount)}%)</span></p>
                                                                            :
                                                                            <p className={`juguete ${(item.amount === 0) ? 'refund-text-disabled' : ''}`} key={`c${index}`}>${item.product.price}</p>
                                                                    }
                                                                </div>
                                                            </div>)
                                                    }
                                                    )

                                                }
                                            </div>
                                        </>
                                        : <p className='refound-not-found-advice'>No hay más productos por reembolsar en este ticket.</p>
                                }
                            </div>
                        </div>
                        <div className="refund-total-container">
                            <h2 className='refund-total-text'>Total: ${formatNumber(total)}</h2>
                        </div>
                    </div>
                    <div className='refund-card refund-card-right'>

                        <div className='refund-advice'>
                            <p className='refund-advice-text'>
                                Verifique el estado del producto y la promocion de compra, si todo es correcto haga clic en reembolsar.
                            </p>
                        </div>

                        <div className="logo-container">
                            <img src={logo_white} alt='logo' />
                        </div>

                        <div>
                            <p className='refund-input-title'>Apartado: {Number(bill?.debt) === 0 ? 'No' : 'Si'}</p>
                            <p className='refund-input-title'>Número total de artículos: {cartNumberProducts}</p>
                            <p className='refund-input-title'>Reembolsados: {numberOfItemsRefunded}</p>
                            <p className='refund-input-title'>Restantes: {cartNumberProducts - numberOfItemsRefunded}</p>
                        </div>

                        <div className='refund-user-column'>
                            <div className='refund-input-title'>Seleccione un cliente</div>
                            <div className="refund-user-info">
                                <div
                                    className={
                                        `
                                            refound-user-field
                                            ${(customer) ? 'refound-user-field-correct' :
                                            (customerNotExist) ? 'refound-user-field-warn' : ''
                                        }
                                        `
                                    }
                                >
                                    <FaUserAlt size={28} />
                                    <input
                                        type='text'
                                        placeholder='Nombre/teléfono'
                                        disabled={customer}
                                        value={nameOrPhone}
                                        onChange={(e) => {
                                            setNameOrPhone(e.target.value);
                                            setCustomerNotExist(false);
                                        }}
                                        maxLength={128}
                                        onKeyDown={
                                            (event) => {
                                                if (event.key === 'Enter') {
                                                    fetchCustomer();
                                                }
                                            }
                                        }
                                    />
                                    <button
                                        className='refund-user-button'
                                        onClick={(customer) ? () => setCustomer(null) : () => navigate('/add_customer')}
                                    >
                                        {
                                            (customer)
                                                ?
                                                <MdCancel size={32} color='black' />
                                                :
                                                <MdAddCircle size={32} color='black' />
                                        }
                                    </button>
                                </div>
                                {
                                    (customer) ?
                                        <div className='refund-points'>
                                            <span>{customer.points}</span>
                                            <span className='refund-points-subtitle'>pts</span>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>

                        <div className="card-options">

                            {
                                Number(bill?.debt) === 0
                                    ?
                                    <div className='register-total'>
                                        <p className='register__text'>Total : </p>
                                        <div className='register__input-box'>
                                            <TbCircleLetterP className='customer-row__icon' size={32} color='ffcd56' />
                                            <input
                                                className='total__input'
                                                placeholder='0.00'
                                                value={formatNumber(total)}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className='refund-right-p'>
                                        <p className='refund-input-title'>Deuda total: {bill?.debt}</p>
                                        <p className='refund-input-title'>Deuda restante: {bill?.debtOutstanding}</p>
                                    </div>
                            }
                            <div className='card-buttons'>
                                {
                                    (!finished)
                                        ?
                                        <>
                                            <button className="button-opt refund" onClick={() => navigate(-1)}><FaTimesCircle size={28} color='F44336' />Cancelar</button>
                                            <button className="button-opt refund golden-button" onClick={refundProducts}><SiMoneygram size={28} color='black' />Reembolsar</button>
                                        </>
                                        :
                                        <button className='button-opt refund' onClick={() => navigate(-1)}><SiMoneygram size={28} color='04c055' />Terminar</button>
                                }
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Refund;