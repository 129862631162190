import UtilityContainer from '../UtilityContainer/UtilityContainer';
import React, { useState, useEffect } from 'react';
import BACK_END from '../../../config/direction';
import axios from 'axios';
import { exportToExcel } from '../../../util/excel';

const UtilityStockByDepartment = () => {

    const [dataset, setDataset] = useState([]);

    // Initial fetch
    const getData = () => {
        axios.get(`${BACK_END}/stadistics/departament-stock`)
            .then((res) => {
                const data = res.data;
                if (res.status === 200) {
                    setDataset(data);
                }
            })
            .catch((error) => console.error(error));
    }

    // eslint-disable-next-line
    useEffect(getData, []);

    const handleExportClick = () => {
        let data = [];
        dataset.forEach(row => {
            if (row.month !== '') {
                data.push({ 'Departamento': row.department, 'Stock': Number(row.totalStock) });
            }
        });

        exportToExcel(data, 'Artículos con mayor margen');
    };

    // Container header
    const header = (
        <div className='utility-header'>
            Stock por departamento
        </div>
    );

    const chart = (
        <div className="utility-top-margin-container">
            <div className="utility-top-header">
                <span className="utility-grid-column">Departamento</span>
                <span className="utility-grid-column2">Stock</span>
            </div>
            {
                dataset.map((product, index) => (
                    <div className='utility-top-margin-row' key={`stock-department-${index}`} title={product?.department}>
                        <span className='utility-grid-column'>{product?.department}</span>
                        <span className='utility-grid-column2'>{product?.totalStock}</span>
                    </div>
                ))
            }
        </div>
    );

    return (
        <UtilityContainer
            header={header}
            content={chart}
            handleExportClick={handleExportClick}
        />
    )
}

export default UtilityStockByDepartment;