import UtilityContainer from '../UtilityContainer/UtilityContainer';
import React, { useState, useEffect } from 'react';
import BACK_END from '../../../config/direction';
import { exportToExcel } from '../../../util/excel';
import axios from 'axios';

import './UtilityTopMargin.css';

const UtilityTopMargin = () => {

    const [dataset, setDataset] = useState([]);

    const formatNumber = (number) => {
        number = Number(number);
        return number.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    // Initial fetch
    const getData = () => {
        axios.get(`${BACK_END}/stadistics/top-profitable-products`)
            .then((res) => {
                const data = res.data;
                if (res.status === 200) {
                    setDataset(data);
                }
            })
            .catch((error) => console.error(error));
    }

    // eslint-disable-next-line
    useEffect(getData, []);

    const handleExportClick = () => {
        let data = [];
        dataset.forEach(row => {
            if (row.month !== '') {
                data.push({ 'Artículo': row.name, 'Precio': Number(row.price), 'Costo': Number(row.cost), 'Ganancia': Number(row.profitMargin) });
            }
        });

        exportToExcel(data, 'Artículos con mayor margen');
    };

    // Container header
    const header = (
        <div className='utility-header'>
            Artículos con mayor margen
            
        </div>
    );

    const chart = (
        <div className="utility-top-margin-container">
            <div className="utility-top-header">
                <span className="utility-grid-column">Producto</span>
                <span className="utility-grid-column2">Costo</span>
                <span className="utility-grid-column2">Precio</span>
                <span className="utility-grid-column">Margen</span>
            </div>
            {
                dataset.map(product => (
                    <div className='utility-top-margin-row' key={`top-margin-${product.product_id}`} title={product?.name}>
                        <span className='utility-grid-column'>{product?.name}</span>
                        <span className='utility-grid-column2'>${formatNumber(product?.cost)}</span>
                        <span className='utility-grid-column2'>${formatNumber(product?.price)}</span>
                        <span className='utility-grid-column'>${formatNumber(product?.profitMargin)}</span>
                    </div>
                ))
            }
        </div>
    );

    return (
        <UtilityContainer
            header={header}
            content={chart}
            handleExportClick={handleExportClick}
        />
    )
}

export default UtilityTopMargin;