import UtilityContainer from '../UtilityContainer/UtilityContainer';
import React, { useState, useEffect } from 'react';
import BACK_END from '../../../config/direction';
import { BarChart } from '@mui/x-charts';
import Paper from "@mui/material/Paper";
import Dropdown from 'react-dropdown';
import axios from 'axios';
import { exportToExcel } from '../../../util/excel';

import './UtilityPayments.css'

const UtilityPayments = () => {
    const options = ['Ingreso', 'Conteo'];
    const [selectedOption, setSelectedOption] = useState({ label: options[0], value: options[0] });

    const [dataset, setDataset] = useState([]);

    // Initial fetch
    const getData = () => {
        axios.get(`${BACK_END}/stadistics/payment-methods?daysAgo=60`)
            .then((res) => {
                const data = res.data;
                if (res.status === 200) {
                    const yChart = [
                        { total: data.totalCash, count: data.cashCount, method: 'Efectivo' },
                        { total: data.totalCard, count: data.cardCount, method: 'Tarjeta' },
                        { total: data.totalDebt, count: data.debtCount, method: 'Apartado' },
                    ];
                    setDataset(yChart);
                }
            })
            .catch((error) => console.error(error));
    }

    // eslint-disable-next-line
    useEffect(getData, []);

    const handleExportClick = () => {
        let data = [];
        
        dataset.forEach(row => {
            if(row.month !== '') {
                data.push({ 'Método': row.method, 'Conteo': Number(row.count), 'Total': Number(row.total) });
            }
        });
        
        exportToExcel(data, 'Métodos de pago');
    };

    // Container header
    const header = (
        <div className='utility-header'>
            <Dropdown
                className='utility-scroll'
                options={options}
                onChange={(text) => setSelectedOption(text)}
                value={selectedOption}
                placeholder='Ingreso'
            />
            de pagos
            
        </div>
    );

    // Custom Tooltip
    const CustomItemTooltipContent = (props) => {
        const { series, dataIndex, axisValue } = props;
        return (
            <Paper sx={{ padding: 1, backgroundColor: 'white' }}>
                <p>{axisValue}</p>
                {
                    (selectedOption.value === 'Conteo')
                        ?
                        <p>{series[0].stackedData[dataIndex][1]}</p>
                        :
                        <p>$ {series[0].stackedData[dataIndex][1].toFixed(2)}</p>
                }
            </Paper>
        );
    };

    const valueFormatter = (element) => (selectedOption.value !== 'Conteo') ? `$${element.toFixed(0)}` : `${element.toFixed(0)}`;

    const getKey = () => {
        let result = 'total';
        
        if(selectedOption.value === 'Conteo') {
            result = 'count';
        }

        return result;
    }

    const chart = (
        <div className='utility-payment-content chart-content-father'>
            {
                (dataset.length > 0)
                    ?
                    <BarChart
                        sx={{ width: "100%", maxWidth: 580, maxHeight: 300 }}
                        xAxis={[{ scaleType: 'band', label: 'Pagos', dataKey: 'method' }]}
                        yAxis={[
                            {
                                valueFormatter: (element) => (selectedOption.value !== 'Conteo') ? `$${element.toFixed(0)}` : `${element.toFixed(0)}`,
                            },
                        ]}
                        margin={{
                            left: 60,
                            right: 20,
                        }}
                        dataset={dataset}
                        series={[{ dataKey: getKey(), valueFormatter }]}
                        //width={600}
                        height={300}
                        layout="vertical"
                        tooltip={{
                            trigger: "axis",
                            axisContent: CustomItemTooltipContent,
                        }}
                    />
                    :
                    <></>
            }
        </div>
    );

    return (
        <UtilityContainer
            header={header}
            content={chart}
            handleExportClick={handleExportClick}
        />
    )
}

export default UtilityPayments;