import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import React, { useState } from 'react';
import './InitialMoney.css'

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';

const InitialMoney = () => {

    const { setInitialMoney } = useContext(SessionContext);

    const [money, setMoney] = useState('');

    const handleInput = (event) => {
        const input = event.target.value;
        if(/^\d*\.?\d{0,2}$/.test(input)) {
            setMoney(input);
        }
    }

    const saveInitialMoney = () => {
        setInitialMoney(money);
    }

    return (
        <div className="initialmoney-container">
            <div className="initialmoney-box">

                <div className='initialmoney-header-container'>
                    <h2 className='initialmoney-header-text'>¿Con cuanto dinero en la caja comenzamos el día?</h2>
                </div>

                <div className="initialmoney-gen">
                    <div className='initialmoney-header-description'>
                        <p className='initialmoney-description-text'>Dinero total en la caja registradora:</p>
                    </div>

                    <div className="initialmoney-input-container">
                        <div className='initialmoney-input-row'>
                            <RiMoneyDollarCircleFill size={32} color='black'/>
                            <input
                                className='initialmoney-input'
                                maxLength={12}
                                type='text'
                                placeholder='$0'
                                value={money}
                                onChange={handleInput}
                                onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            saveInitialMoney();
                                        }
                                    }
                                }
                            />
                        </div>
                    </div>

                    <button className='initialmoney-button' onClick={saveInitialMoney}> <BsFillCheckCircleFill size={22} color='04c055' />Aceptar</button>
                </div>



            </div>
        </div>
    )
}

export default InitialMoney;