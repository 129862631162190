import { FaPhoneAlt, FaPlus, FaUserAlt, FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import React, { useEffect, useState, useRef } from "react";
import { GiReceiveMoney } from "react-icons/gi";
import Navbar from "../../components/Navbar/Navbar";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import BACK_END from "../../config/direction";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./EditCustomer.css";
import { FaTag } from "react-icons/fa";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const EditCustomer = () => {

  const customer = useParams();
  const navigate = useNavigate();

  const [nameState, setNameState] = useState("");
  const [phoneState, setPhoneState] = useState("");
  const [debtState, setDebtState] = useState(0);
  const [originState, setOriginState] = useState(0);

  const [originOptions, setOriginOptions] = useState([]);
  const [customerLoaded, setCustomerLoaded] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState(null);

  const nameRef = useRef("");
  const phoneRef = useRef("");
  const originRef = useRef(0);

  const toastySuccess = (msg) => {
    navigate(-1);
    toast.success(`${msg}`);
  };
  const toastyError = (msg) => {
    toast.error(`${msg}`);
  };
  const toastyWarn = (msg) => {
    toast.warn(`${msg}`);
  };
  const toastyInfo = (msg) => {
    toast.info(`${msg}`);
  };

  // Get customer data
  const fetchData = () => {
    fetch(`${BACK_END}/customer/${customer.id}`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }).then(async (res) => {
      const response = await res.json();
      if (res.ok) {
        setNameState(response[0].name);
        setPhoneState(response[0].phone);
        setDebtState(response[0].debt);
        setOriginState(response[0].origin_id);

        nameRef.current = response[0].name;
        phoneRef.current = response[0].phone;
        originRef.current = response[0].origin_id;

        setCustomerLoaded(true);
      } else {
        toastyError("Ocurrió un error inesperado");
        console.log(response.error);
      }
    });
  };

  useEffect(() => {
    fetch(`${BACK_END}/origin`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          const data = response.data;
          const dataPackage = data.map((origin) => ({
            label: origin.name,
            value: origin.id,
          }));
          setOriginOptions(dataPackage);
          setOptionsLoaded(true);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (customerLoaded && optionsLoaded) {
      const selectedOriginIndex = originOptions.find(
        (option) => option.value === originState
      );
      setSelectedOrigin(selectedOriginIndex);
    } // eslint-disable-next-line
  }, [customerLoaded, optionsLoaded, originOptions]);

  const onChangeNumber = (event, setValue) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const handlePhoneChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      setPhoneState(inputValue);
    }
  };

  useEffect(fetchData, [customer.id]);

  const uploadData = () => {
    const nameChanged = nameRef.current !== nameState ? true : false;
    const phoneChanged = phoneRef.current !== phoneState ? true : false;
    const originChanged = originRef.current !== selectedOrigin ? true : false;

    if (nameChanged || phoneChanged || originChanged) {
      const formData = {
        id: customer.id,
        name: nameState,
        phone: phoneState,
        origin_id: selectedOrigin.value,
      };
      //print origin state string

      fetch(`${BACK_END}/customer`, {
        method: "PATCH",
        mode: "cors",
        cache: "default",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      })
        .then(async (res) => {
          const data = await res.json();
          if (res.ok) {
            toastySuccess(data.message);
          } else {
            toastyInfo(data.error);
          }
        })
        .catch((error) => {
          toastyError("Ocurrió un error inesperado");
          console.log(error);
        });
    } else {
      toastyWarn("Ningún dato ha sido modificado");
    }
  };

  return (
    <>
      <div className="parent-add-customer">
        <Navbar />
        <div className="customer-container">
          <div className="add-customer-card shadow-div">
            <div className="header-container">
              <h3 className="add-customer__header">Editar cliente</h3>
            </div>
            <div className="body-container">
              <div className="user-fields ">
                <div className="customer-input-row shadow-div">
                  <FaUserAlt size={26} color="000" />
                  <input
                    className="customer-input generic-input"
                    type="text"
                    ref={nameRef}
                    placeholder="Nombre completo"
                    value={nameState}
                    onChange={(e) => setNameState(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        phoneRef.current.focus();
                      }
                    }}
                    maxLength={120}
                  />
                </div>

                <div className="customer-input-row shadow-div">
                  <FaPhoneAlt size={24} color="000" />
                  <input
                    ref={phoneRef}
                    className="customer-input generic-input"
                    type="text"
                    placeholder="Tel&eacute;fono"
                    value={phoneState}
                    maxLength={10}
                    onChange={handlePhoneChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        // debtRef.current.focus();
                      }
                    }}
                  />
                </div>
              </div>

              <div className="add-debt-row">
                <p className="add-debt__text">Deuda: </p>
                <div className="add-debt__input-box shadow-div">
                  <GiReceiveMoney size={30} color="000" />
                  <input
                    className="add-debt__input generic-input"
                    type="number"
                    placeholder="$ 0"
                    // ref={debtRef}
                    value={debtState}
                    onChange={(e) => onChangeNumber(e, setDebtState)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                      }
                    }}
                  />
                </div>
              </div>
              <div className="drop-row-EC">
                <div className="dropdown-customer-input">
                  <FaTag size={32} color="black" />
                  <Dropdown
                    className="add-customer-scroll"
                    options={originOptions}
                    onChange={(text) => setSelectedOrigin(text)}
                    value={selectedOrigin}
                    placeholder="Origen del cliente"
                  />
                </div>
                <button
                  className="dropdown-button shadow-div"
                  title="Agregar Origen"
                  onClick={() => navigate("/add_list/origin")}
                >
                  <FaPlus size={20} color="black" />
                </button>
              </div>

              <div className="edit-customer-buttons">
                <button
                  className="add-cancel-button hover-shadow click-shadow"
                  onClick={() => navigate(-1)}
                >
                  <FaTimesCircle size={28} color="F44336" />
                  Regresar
                </button>
                <button
                  className="add-save-button hover-shadow click-shadow"
                  onClick={uploadData}
                >
                  {" "}
                  <FaCloudUploadAlt size={28} color="04c055" />
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default EditCustomer;