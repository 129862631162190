import { FaBarcode, FaTag, FaRegistered, FaTimesCircle, FaCloudUploadAlt, FaPlus, FaBox, FaTrash } from 'react-icons/fa';
import { RiMoneyDollarCircleFill, RiMoneyDollarCircleLine } from 'react-icons/ri';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AiOutlinePercentage } from 'react-icons/ai';
import Navbar from '../../components/Navbar/Navbar';
import { confirmAlert } from 'react-confirm-alert';
import BACK_END from '../../config/direction';
import { isMobile } from 'react-device-detect';
import { PiTextTBold } from 'react-icons/pi';
import { TbPhotoPlus, TbShoppingCartDollar, TbDiscount } from "react-icons/tb";
import { BiSolidOffer } from "react-icons/bi";
import Dropdown from 'react-dropdown';
import { PiBroomFill } from "react-icons/pi";
import axios from 'axios';
import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';


import 'react-toastify/dist/ReactToastify.css';
import './EditProduct.css';
import { HiMiniRectangleGroup } from 'react-icons/hi2';

const EditProduct = () => {

  const { userData } = useContext(SessionContext);
  const isAdmin = userData && userData.rol === "Administrador";
  const navigate = useNavigate();

  const { product_id } = useParams();

  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [productImageName, setProductImageName] = useState(null);

  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');

  const [productStock, setProductStock] = useState('');

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  // const [brandOptions, setBrandOptions] = useState([]);
  // const [selectedBrand, setSelectedBrand] = useState(null);

  const [productCost, setProductCost] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productMargin, setProductMargin] = useState('');

  const [isAddingOffer, setIsAddingOffer] = useState(false);

  const [productOfferPrice, setProductOfferPrice] = useState('');
  const [productOfferPercent, setProductOfferPercent] = useState('');

  const inputImageRef = useRef(null);
  const inputProductNameRef = useRef(null);
  const inputProductStockRef = useRef(null);
  const inputProductCostRef = useRef(null);
  const inputProductMarginRef = useRef(null);
  const inputProductPriceRef = useRef(null);
  const inputProductPriceOfferRef = useRef(null);
  const inputProductOfferPercentRef = useRef(null);
  const inputProductDescriptionRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);



  // Handle inputs' changes
  const onChangeValue = (event, setValue, current) => {
    const inputValue = event.target.value;

    if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
      setValue(inputValue);
      return inputValue;
    } else {
      return current;
    }
  }

  // Delete .00 from a String
  const removeZeros = (value) => {
    return value.replace(/\.00/, '');
  }

  // Handle stock's input
  const onChangeStock = (event, setValue) => {
    const inputValue = event.target.value;

    if (/^\d*$/.test(inputValue)) {
      setValue(inputValue);
    }
  }

  // Verify if a field is empty
  const isAnyEmpty = () => {
    let isEmpty = false;

    if (productName === '') {
      toast.info('Falta el nombre', { autoClose: 3000 });
      isEmpty = true;
    } else if (productStock === '') {
      toast.info('Falta el stock', { autoClose: 3000 });
      isEmpty = true;
    } else if (productDescription === '') {
      toast.info('Falta la descripción', { autoClose: 3000 });
      isEmpty = true;
      // } else if(selectedBrand === null) {
      //   toast.info('Falta la marca', { autoClose: 3000 });
      //   isEmpty = true;
    } else if (selectedDepartment === null) {
      toast.info("Falta el grupo", { autoClose: 3000 });
      isEmpty = true;
    } else if (selectedDepartment === null) {
      toast.info('Falta la categoría', { autoClose: 3000 });
      isEmpty = true;
    } else if (productCost === '') {
      toast.info('Falta el costo', { autoClose: 3000 });
      isEmpty = true;
    } else if (productPrice === '') {
      toast.info('Falta el precio', { autoClose: 3000 });
      isEmpty = true;
    } else if (isAddingOffer && (productOfferPrice === "" || productOfferPercent === "")) {
      toast.info("Falta el descuento", { autoClose: 3000 });
      isEmpty = true;
    }

    return isEmpty;
  }

  // Upload a product
  const updateProduct = () => {

    if (!isAnyEmpty()) {
      const formData = new FormData();
      formData.append('product_id', product_id);
      formData.append('image', productImage);
      formData.append('image_name', productImageName);
      formData.append('name', productName);
      formData.append('description', productDescription);
      formData.append('stock', productStock);
      // formData.append('brand_id', selectedBrand.value);
      formData.append('department_id', selectedDepartment.value);
      formData.append('cost', productCost);
      formData.append('price', productPrice);
      formData.append("offer_price", productOfferPrice);
      formData.append("isOffer", isAddingOffer);

      setIsLoading(true);

      axios.put(`${BACK_END}/product`, formData)
        .then(async (res) => {
          console.log(res)
          if (res.status === 200) {
            toast.success(res.data.message, { autoClose: 3000 });
            navigate(-1);
          } else {
            //console.log(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            const data = error?.response?.data?.error || 'Algo salió mal.';
            toast.error(data, { autoClose: 5000 });
          } else if (error.request) {
            console.error('No se recibió respuesta del servidor');
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  // Delete this product
  const deleteProduct = () => {

    const deleteConfirmed = () => {
      setIsLoading(true);
      axios.delete(`${BACK_END}/product/${product_id}`)
        .then(async (res) => {
          console.log(res)
          if (res.status === 200) {
            console.log(res.data);
            toast.success(res.data.message, { autoClose: 3000 });
            navigate(-1);
          } else {
            console.log(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('Status:', error.response.status);
            console.log('Data:', error.response.data);
          } else if (error.request) {
            console.error('No se recibió respuesta del servidor');
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="overlay">
            <div className="custom-ui">
              <h1>Confirmar eliminación</h1>
              <p>¿Estás seguro de que deseas eliminar este producto?</p>
              <div className="custom-ui-buttons">
                <button onClick={onClose}>Cancelar</button>
                <button
                  onClick={() => {
                    deleteConfirmed();
                    onClose();
                  }}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  // Handle Product's cost
  const handleProductCost = (event) => {
    const inputCost = onChangeValue(event, setProductCost, productCost);

    if (inputCost === '') {
      setProductMargin('');
    } else if (productMargin !== '') {
      const cost = parseFloat(inputCost);
      const margin = parseFloat(productMargin);
      const price = (cost + cost * (margin / 100.0)).toFixed(2);
      setProductPrice(removeZeros(price.toString()));
    } else if (inputCost !== '') {
      const cost = parseFloat(inputCost);
      const price = parseFloat(productPrice);

      const diff = price - cost;

      if (diff > 0) {
        const margin = (diff / cost * 100).toFixed(2);
        setProductMargin(removeZeros(margin.toString()));
      } else {
        setProductMargin('');
      }
    }

    setProductOfferPrice('');
    setProductOfferPercent('');
  }

  // Handle Product's margin
  const handleProductMargin = (event) => {
    const inputMargin = onChangeValue(event, setProductMargin, productMargin);

    if (productCost !== '') {
      const cost = parseFloat(productCost);
      const margin = parseFloat(inputMargin);
      const price = (cost + cost * (margin / 100.0)).toFixed(2);
      if (!isNaN(price)) {
        setProductPrice(removeZeros(price.toString()));
      }
    }

    setProductOfferPrice('');
    setProductOfferPercent('');
  }

  // Handle Product's price
  const handleProductPrice = (event) => {
    const inputPrice = onChangeValue(event, setProductPrice, productPrice);

    if (inputPrice === '') {
      setProductMargin('');
    } else if (productCost !== '') {
      const cost = parseFloat(productCost);
      const price = parseFloat(inputPrice);

      const diff = price - cost;

      if (diff > 0) {
        const margin = (diff / cost * 100).toFixed(2);
        setProductMargin(removeZeros(margin.toString()));
      } else {
        setProductMargin('');
      }
    }

    setProductOfferPrice('');
    setProductOfferPercent('');
  }

  useEffect(() => {
    if (selectedGroup !== null && selectedGroup !== undefined && groupOptions.length > 0) {
      fetch(`${BACK_END}/department?group_id=${selectedGroup?.value}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (res) => {
          if (res.ok) {
            const response = await res.json();
            const data = response.data;
            const dataPackage = data.map((department) => ({
              label: department.name,
              value: department.id,
            }));
            setDepartmentOptions(dataPackage);
          }
        })
        .catch((error) => {
          setDepartmentOptions([]);
          console.log(error);
        });
    }
  }, [groupOptions, selectedGroup]);

  const getGroups = async () => {
    let dataResult = null;

    await fetch(`${BACK_END}/group`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          const data = response.data;
          const dataPackage = data.map((brand) => ({
            label: brand.name,
            value: brand.id,
          }));
          dataResult = dataPackage;
          setGroupOptions(dataPackage);
        }
      })
      .catch((error) => console.log(error));
    return dataResult;
  }

  // Get product info
  useEffect(() => {
    const getData = async () => {
      let dataPackageDepartment;
      //let dataPackageBrand;

      let groupData = await getGroups();
      fetch(
        `${BACK_END}/product/single/${product_id}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (res) => {
          if (res.ok) {
            const data = await res.json();

            let group_id = null;

            await fetch(
              `${BACK_END}/department/getGroupId?department_id=${data.department_id}`,
              {
                method: 'GET',
                mode: 'cors',
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then(async (res) => {
                if (res.ok) {
                  const response = await res.json();
                  const { data } = response;
                  group_id = data;
                }
              })
              .catch((error) => console.log(error));

            await fetch(
              `${BACK_END}/department?group_id=${group_id}`,
              {
                method: 'GET',
                mode: 'cors',
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then(async (res) => {
                if (res.ok) {
                  const response = await res.json();
                  const data = response.data;
                  dataPackageDepartment = data.map(department => ({ label: department.name, value: department.id }));
                  setDepartmentOptions(dataPackageDepartment);
                }
              })
              .catch((error) => console.log(error));

            const groupIndex = groupData.findIndex(item => item.value === group_id);
            const departmentIndex = dataPackageDepartment.findIndex(item => item.value === data.department_id);
            // const brandIndex = dataPackageBrand.findIndex(item => item.value === data.brand_id);

            setProductName(data.name);
            setProductStock(data.stock);
            setSelectedGroup(groupData[groupIndex]);
            setSelectedDepartment(dataPackageDepartment[departmentIndex]);
            //setSelectedBrand(dataPackageBrand[groupIndex]);
            setProductDescription(data.description);
            setProductCost(data.cost);
            setProductImageName(data.image);
            setImageUrl(`${BACK_END}/product/image/${data.image}`);
            if (Number(data.originalPrice) === 0) {
              setProductPrice(data.price);
              setProductOfferPrice('');
            } else {
              setIsAddingOffer(true);
              setProductPrice(data.originalPrice);
              setProductOfferPrice(data.price)
              setProductOfferPercent(data.price * 100 / data.originalPrice)
            }

            // Get margin
            const cost = parseFloat(data.cost);
            const price = parseFloat((Number(data.originalPrice) === 0) ? data.price : data.originalPrice);

            const diff = price - cost;

            if (diff > 0) {
              const margin = (diff / cost * 100).toFixed(2);
              setProductMargin(removeZeros(margin.toString()));
            } else {
              setProductMargin('');
            }
          }
        })
        .catch((error) => console.log(error));
    }
    getData();
  }, [product_id]);

  // select an image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setProductImage(file);
    }
  };

  const handleDivClick = () => {
    inputImageRef.current.click();
  };

  // Handle Product's offer price
  const handleProductOfferPrice = (event) => {

    if(productPrice === '') {
      toast.info('Ingrese el precio primero', { autoClose: 3000, hideProgressBar: true });
      return;
    }

    const inputOfferPrice = event.target.value;

    if (/^\d*\.?\d{0,2}$/.test(inputOfferPrice)) {
      setProductOfferPrice(inputOfferPrice);
    } else {
      return;
    }

    if (inputOfferPrice === "") {
      setProductOfferPercent('');
    } else {

      const price = parseFloat(productPrice);
      const offerPrice = parseFloat(inputOfferPrice);

      if (offerPrice < 0 || offerPrice > price) {
        return;
      }

      const percentage = ((price - offerPrice) * 100 / price).toFixed(2);
      setProductOfferPercent(percentage)
    }
  };

  // Handle Product's offer percient
  const handleProductOfferPercent = (event) => {

    if(productPrice === '') {
      toast.info('Ingrese el precio primero', { autoClose: 3000, hideProgressBar: true });
      return;
    }

    const inputOfferPercent = event.target.value;

    if (/^\d*\.?\d{0,2}$/.test(inputOfferPercent)) {
      if (inputOfferPercent === "") {
        setProductOfferPrice('');
      } else {
        const price = parseFloat(productPrice);
        const offerPercent = parseFloat(inputOfferPercent);

        if (offerPercent < 0 || offerPercent > 100) {
          return;
        }

        const offerPrice = (price - (price * offerPercent / 100)).toFixed(2);
        setProductOfferPrice(offerPrice);
      }
      setProductOfferPercent(inputOfferPercent);
    }
  };

  const clearFields = () => {
    setProductName('');
    setProductDescription('');
    setProductStock('');
    setSelectedGroup(null);
    setSelectedDepartment(null);
    setProductCost('');
    setProductPrice('');
    setProductMargin('');
    setIsAddingOffer(false);
    setProductOfferPrice('');
    setProductOfferPercent('');
    setImageUrl(null);
    setProductImage(null);
    setProductImageName(null);
  };

  // Render component
  return (
    <div className='edit-product-father'>
      <Navbar />
      <div className='father-container-edit-product'>
        <div className='edit-product-card'>

          <h3 className='edit-product-header-text'>Editar producto</h3>

          <div className='edit-product-row'>
            <div
              className='edit-product-image'
              onClick={handleDivClick}
              title={(productImage) ? 'Subir foto' : 'Cambiar foto'}
            >
              <button className='edit-product-image-button'>
                {
                  imageUrl
                    ?
                    <img src={imageUrl} alt='Imagen seleccionada' />
                    :
                    <TbPhotoPlus size={192} color='black' />
                }
              </button>
              <div className="edit-product-file-upload-container">
                <label>
                  {(imageUrl) ? 'Cambiar foto' : 'Agregar foto'}
                </label>
                <input
                  ref={inputImageRef}
                  type="file"
                  multiple={false}
                  id="upload-image"
                  accept="image/*"
                  formEncType='multipart/form-data'
                  className="add-image-button"
                  onClick={() => { }}
                  onChange={handleImageUpload}
                />
              </div>
            </div>

            <div className='right-container' >

              <div className='add-product-input' style={{
                marginTop: "10px"
              }}>
                <PiTextTBold size={32} color='black' />
                <input
                  maxLength={120}
                  type="text"
                  placeholder="Nombre del producto"
                  value={productName}
                  onChange={(value) => setProductName(value.target.value)}
                  autoFocus={!isMobile}
                  ref={inputProductNameRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      inputProductDescriptionRef.current.focus();
                    }
                  }}

                />

              </div>

              <div className='add-product-input'>
                <FaBarcode size={32} color='black' />
                <input
                  maxLength={35}
                  type="text"
                  placeholder="Código de barras"
                  value={product_id}
                  disabled={true}
                />
              </div>

              <div className="add-productDesc-input">
                <FaRegistered size={32} color="black" />
                <textarea
                  maxLength={250}
                  placeholder="Descripción del producto"
                  value={productDescription}
                  onChange={(value) =>
                    setProductDescription(value.target.value)
                  }
                  autoFocus={!isMobile}
                  ref={inputProductDescriptionRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // Previniendo el comportamiento por defecto para Enter en un textarea
                      e.preventDefault();
                      inputProductStockRef.current.focus();
                    }
                  }}
                  style={{
                    flex: 1,
                    padding: "8px",
                    marginLeft: "4px",
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    fontFamily: '"readex"',
                    resize: "none",
                  }}
                  className='edit-product-text-area'
                />
              </div>

              <div className="drop-row">
                <div className="add-product-input">
                  <HiMiniRectangleGroup size={32} color="black" />
                  <Dropdown
                    className="edit-product-department-scroll"
                    options={groupOptions}
                    onChange={(text) => {
                      setSelectedDepartment(null);
                      setSelectedGroup(text);
                    }
                    }
                    value={selectedGroup}
                    placeholder="Seleccionar grupo"
                  />
                </div>

                <button
                  className="dropdown-button"
                  title="Agregar departamento"
                  onClick={() => navigate("/add_list/group")}
                >
                  <FaPlus size={20} color="black" />
                </button>
              </div>

              <div className='drop-row'>
                <div className='add-product-input'>
                  <FaTag size={32} color='black' />
                  <Dropdown
                    className='edit-product-department-scroll'
                    options={departmentOptions}
                    onChange={(text) => setSelectedDepartment(text)}
                    value={selectedDepartment}
                    placeholder="Seleccione un departamento"
                  />
                </div>

                <button
                  className='dropdown-button'
                  title='Agregar departamento'
                  onClick={() => navigate('/add_list/department', { state: { group_id: selectedGroup.value } })}
                >
                  <FaPlus size={20} color='black' />
                </button>
              </div>

              <div className='add-product-input'>
                <FaBox size={32} color='black' />
                <input
                  type="text"
                  placeholder="Stock"
                  value={productStock}
                  onChange={(event) => onChangeStock(event, setProductStock)}
                  ref={inputProductStockRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      inputProductCostRef.current.focus();
                    }
                  }}
                />
              </div>

              <div className='edit-product-row-button'>
                <div className='little-add-product-input'>
                  <RiMoneyDollarCircleLine size={32} color='black' />
                  <input
                    maxLength={12}
                    type="text"
                    placeholder="Costo"
                    value={productCost}
                    onChange={handleProductCost}
                    className='number-input'
                    ref={inputProductCostRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        inputProductMarginRef.current.focus();
                      }
                    }}
                  />
                </div>

                <div className='little-add-product-input'>
                  <AiOutlinePercentage size={28} color='black' />
                  <input
                    maxLength={12}
                    type="text"
                    placeholder="Margen"
                    value={productMargin}
                    onChange={handleProductMargin}
                    className='number-input'
                    ref={inputProductMarginRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        inputProductPriceRef.current.focus();
                      }
                    }}
                  />
                </div>

                <div className='little-add-product-input'>
                  <RiMoneyDollarCircleFill size={32} color='black' />
                  <input
                    maxLength={12}
                    type="text"
                    placeholder="Precio"
                    value={productPrice}
                    onChange={handleProductPrice}
                    ref={inputProductPriceRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        inputProductNameRef.current.focus();
                      }
                    }}
                  />
                </div>
              </div>

              {
                (!isAddingOffer)
                  ?
                  <button
                    className="add-product-offer-button"
                    onClick={() => setIsAddingOffer(true)}
                  >
                    <BiSolidOffer size={32} color="d1b104" />
                    Agregar oferta
                  </button>
                  :
                  <div className="add-product-column">
                    <div className="add-product-row-button">
                      <div className="little-add-product-input">
                        <TbShoppingCartDollar size={32} color="black" />
                        <input
                          maxLength={12}
                          type="text"
                          placeholder="Precio con descuento"
                          value={productOfferPrice}
                          onChange={handleProductOfferPrice}
                          ref={inputProductPriceOfferRef}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              inputProductNameRef.current.focus();
                            }
                          }}
                        />
                      </div>

                      <div className="little-add-product-input">
                        <TbDiscount size={32} color="black" />
                        <input
                          maxLength={12}
                          type="text"
                          placeholder="Porcentaje de descuento"
                          value={productOfferPercent}
                          onChange={handleProductOfferPercent}
                          ref={inputProductOfferPercentRef}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              inputProductNameRef.current.focus();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <button
                      className="add-product-offer-button"
                      onClick={() => {
                        setIsAddingOffer(false);
                        setProductOfferPrice('');
                        setProductOfferPercent('');
                      }}
                    >
                      <BiSolidOffer size={32} color="d1b104" />
                      Eliminar oferta
                    </button>
                  </div>
              }

              {
                (!isLoading)
                  ?
                  <>
                    <div className='product-button-container'>
                    <button
                    className="add-product-cancel-button"
                    onClick={() => {
                      clearFields();
                    }}
                    style={{ width: '25%' }}
                  >
                    <PiBroomFill size={32} color="000"/>
                    
                  </button>
                      <button className='edit-cancel-button' onClick={() => { navigate(-1) }}><FaTimesCircle className='icon-buton' size={32} color='d06154' />Cancelar</button>
                      <button className='edit-save-button' onClick={updateProduct}> <FaCloudUploadAlt size={32} className='icon-buton' color='04c055' />Guardar</button>
                    </div>

                    {isAdmin && <button className='edit-delete-button' onClick={deleteProduct}><FaTrash size={28} color='d06154' />Eliminar</button>}

                  </>
                  :
                  <div className="loading-spinner">
                    <div className="spinner"></div>
                  </div>
              }

            </div>

          </div>

        </div>
      </div>


    </div>
  );
}

export default EditProduct;