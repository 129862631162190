import BACK_END from '../../config/direction';
import React, { useState, useEffect } from "react";
import "./EstadoEnvio.css";
import axios from "axios";
import { FaTruck } from "react-icons/fa";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa6";
import DeliveryCodeModal from '../DeliveryCodeModal/DeliveryCodeModal';
import { toast } from 'react-toastify';
import DeliveryDetailModal from '../DeliveryDetailModal/DeliveryDetailModal';
import { format } from 'date-fns';

const EstadoEnvio = ({ viewType, rangeDate, search, userJWT }) => {

    const [selectedBillId, setSelectedBillId] = useState(null);
    const [detailsModal, setDetailsModal] = useState(false);
    const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);

    const closeCodeModal = () => {
        setIsCodeModalOpen(false);
        setSelectedBillId(null);
    }

    const [data, setData] = useState([]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        let hours = date.getHours();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return (<span>{`${day}/${month}/${year}`} <span className='hide-mobile'>{`${hours}:${minutes} ${ampm}`}</span></span>);
    }

    const handleAcceptCodeModal = async (trackCode) => {
        try {
            await changeStatus(selectedBillId, { shipping_status: 'En camino', shipping_tracking_number: trackCode });
            closeCodeModal();
        } catch (error) {
            console.error('Error al aceptar el envío:', error);
        }
    };

    const formatDateTime = (dateTime) => {
        const timeZoneOffset = new Date().getTimezoneOffset() / 60;
        const adjustedDate = new Date(dateTime.getTime() + timeZoneOffset * 60 * 60 * 1000);
        const formattedDate = format(adjustedDate, 'yyyy-MM-dd HH:mm:ss');
        return formattedDate;
    };

    const getData = async (page) => {

        const shippingStatus = (viewType === 'pendientes') ? 'En proceso' : (viewType === 'enviados') ? 'En camino' : 'Entregado';

        try {
            let queries = [];

            if (rangeDate) {
                const startDate = rangeDate ? rangeDate[0] : null;
                const endDate = rangeDate ? rangeDate[1] : null;
    
                const formattedStartDate = startDate ? formatDateTime(startDate) : null;
                const formattedEndDate = endDate ? formatDateTime(endDate) : null;
    
                queries.push(`startDate=${formattedStartDate}`);
                queries.push(`endDate=${formattedEndDate}`);
            }
            if (search !== '') {
                queries.push(`bill_id=${search}`);
            }
            if (viewType === 'entregados') {
                queries.push(`orderBy=desc`);
            } else {
                queries.push(`orderBy=asc`);
            }

            const extraParams = queries.join('&');

            const request = await axios.get(`${BACK_END}/bill?page=${page}&saleChannel=online&shippingStatus=${shippingStatus}&${extraParams}`, {
                headers: {
                    Authorization: `Bearer ${userJWT}`,
                },
            });
            const data = request?.data;

            //console.log(data);

            setData(data)
        } catch (error) {
            console.error('Error al obtener los datos:', error);
        }
    }

    useEffect(() => {
        getData(1); // eslint-disable-next-line
    }, [viewType, rangeDate, search]);

    const headers_state = {
        pendientes: [
            { name: 'NO. DE PEDIDO', styles: 'hide-mobile' },
            { name: 'FECHA DE VENTA' },
            { name: 'DIRECCION', styles: 'hide-mobile' },
            { name: 'TELÉFONO', styles: 'hide-mobile' },
            { name: 'OPCIONES' }
        ],
        enviados: [
            { name: 'NO. DE PEDIDO', styles: 'hide-mobile' },
            { name: 'FECHA DE VENTA' },
            { name: 'DIRECCION', styles: 'hide-mobile' },
            { name: 'TELÉFONO', styles: 'hide-mobile' },
            { name: 'OPCIONES' }
        ],
        entregados: [
            { name: 'NO. DE PEDIDO', styles: 'hide-mobile' },
            { name: 'FECHA DE VENTA' },
            { name: 'DIRECCION', styles: 'hide-mobile' },
            { name: 'TELÉFONO', styles: 'hide-mobile' },
            { name: 'FECHA DE ENTREGADO' }
        ]
    };

    const currentHeaders = headers_state[viewType] || headers_state['enviados'];

    const changeStatus = async (billId, newData) => {
        try {
            const response = await axios.patch(`${BACK_END}/bill?bill_id=${billId}`, newData);
            getData(1);
            toast.success('Actualizado', { autoClose: 1500, hideProgressBar: true });
            return response.data;
        } catch (error) {
            console.error('Error al cambiar el estado de envío:', error);
            toast.error('Algo salió mal', { autoClose: 1500, hideProgressBar: true });
        }
    };

    const handleStatusButton = (bill_id) => {

        if (!bill_id) return;

        if (viewType === 'pendientes') {
            setSelectedBillId(bill_id);
            setIsCodeModalOpen(true);
        } else if (viewType === 'enviados') {
            changeStatus(bill_id, { shipping_status: 'Entregado' });
        }
    }

    return (
        <div className="delivery-table-container">
            <div className="deliveries-table">
                <div className="header-table-row">
                    {currentHeaders.map((header, index) => (
                        <div className={`header-column  ${header.styles}`} key={index}>{header.name}</div>
                    ))}
                </div>
                <div className="deliveries-table-body">
                    {data.map((row, rowIndex) => (
                        <div className="row" key={rowIndex}>
                            <div className="delivery-row-div hide-mobile">{row?.bill_id ?? 'N/A'}</div>
                            <div className='delivery-row-div'>{formatDate(row?.created_at)}</div>
                            <div className="delivery-row-div hide-mobile">{row?.shipping_address ?? 'N/A'}</div>
                            <div className=" delivery-row-div hide-mobile">{row?.phone_number ?? 'N/A'}</div>
                            <div className='delivery-row-div'>
                                {
                                    (viewType !== 'entregados')
                                        ?
                                        <>
                                            <div className='delivery-button-icon delivery-button-icon-info' onClick={() => { setDetailsModal(true); setSelectedBillId(row?.bill_id) }}>
                                                <BsFillInfoCircleFill size={24} color='white' />
                                            </div>
                                            {
                                                row.shipping_status === 'Entregado' ?
                                                    "Entregado"
                                                    :
                                                    <div
                                                        className={`delivery-button-icon ${(viewType === 'pendientes') ? 'delivery-button-icon-gold' : 'delivery-button-icon-green'}`}
                                                        onClick={() => handleStatusButton(row?.bill_id)}
                                                    >
                                                        {
                                                            (viewType === 'pendientes')
                                                                ?
                                                                <FaTruck size={24} />
                                                                :
                                                                <FaBoxOpen color='white' size={24} />
                                                        }
                                                    </div>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className='delivery-button-icon delivery-button-icon-info' onClick={() => { setDetailsModal(true); setSelectedBillId(row?.bill_id) }}>
                                                <BsFillInfoCircleFill size={24} color='white' />
                                            </div>
                                            <div className='delivery-center-text'>
                                                Entregado
                                            </div>
                                        </>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <DeliveryCodeModal handleAccept={handleAcceptCodeModal} isModalOpen={isCodeModalOpen} closeModal={closeCodeModal} />
            <DeliveryDetailModal showDetailsModal={detailsModal} bill_id={selectedBillId} closeDetailModal={() => { setDetailsModal(false); setSelectedBillId(null) }} userJWT={userJWT} />
        </div>
    );
};

export default EstadoEnvio;