import React from 'react';
import './UtilityContainer.css';
import { RiFileExcel2Fill } from 'react-icons/ri';

const UtilityContainer = ({ header = 'N/A', content, index, handleExportClick = () => {} }) => {
    return (
        <div className="utilities-grid-item" key={index}>
            <div className='utilities-grid-item-header'>
                {header}
            </div>
            <div className='utilities-grid-line' />
            <div className='utilities-grid-item-container'>
                {content}
            </div>
            
                <div className='utilities-export-button-mobile' onClick={handleExportClick}>
                    <RiFileExcel2Fill color='white' size={20} /> Descargar datos
                </div>
            
        </div>
    )
}

export default UtilityContainer;