import { FaClockRotateLeft } from 'react-icons/fa6';
import { FaEdit } from 'react-icons/fa';
import BACK_END from '../../config/direction';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import axios from 'axios';

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';

const CashWithdrawalModal = ({ isCashWithdrawalModalOpen, closeCashWithdrawalModal, showRegisterScreen = true }) => {

    const { userData } = useContext(SessionContext);

    const [showRegistrationForm, setShowRegistrationForm] = useState(showRegisterScreen);
    const [cashWithdrawalAmount, setCashWithdrawalAmount] = useState('');
    const [cashWithdrawalReason, setCashWithdrawalReason] = useState('');
    const [cashWithdrawals, setCashWithdrawals] = useState([]);

    const formatDate = (isoDate) => {
        if (!isoDate) {
            return null;
        }

        const date = new Date(isoDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

        return date.toLocaleDateString('es-MX', options);
    };

    const handleCashWithdrawal = async () => {
        try {
            const response = await axios.post(`${BACK_END}/cashWithdrawals`, {
                user_id: userData.user_id,
                amount: cashWithdrawalAmount,
                reason: cashWithdrawalReason,
            });
            toast.success(response.data.message);
            setCashWithdrawalAmount('');
            setCashWithdrawalReason('');
            closeCashWithdrawalModal();
            fetchCashWithdrawals();
        } catch (error) {
            console.error(error);
            toast.error('Error al registrar el retiro');
        }
    };

    const fetchCashWithdrawals = async () => {
        try {
            const response = await axios.get(`${BACK_END}/cashWithdrawals`);
            setCashWithdrawals(response.data);
        } catch (error) {
            console.error(error);
            toast.error('Error al obtener el historial de retiros');
        }
    };

    const toggleView = () => {
        setShowRegistrationForm((prev) => !prev);
    };

    useEffect(() => {
        fetchCashWithdrawals();
    }, []);

    return (
        <Modal
            isOpen={isCashWithdrawalModalOpen}
            onRequestClose={closeCashWithdrawalModal}
            className="custom-ui-modal"
            overlayClassName="overlay-modal"
            contentLabel="Registrar Retiro de Efectivo"
        >
            <h2>{showRegistrationForm ? 'Registrar Retiro de Efectivo' : 'Historial de Retiros'}</h2>
            {showRegistrationForm ? (
                <>
                    <input
                        type="number"
                        value={cashWithdrawalAmount}
                        onChange={(e) => setCashWithdrawalAmount(e.target.value)}
                        placeholder="Monto"
                        autoFocus
                    />
                    <input
                        type="text"
                        value={cashWithdrawalReason}
                        onChange={(e) => setCashWithdrawalReason(e.target.value)}
                        placeholder="Motivo"
                    />
                    <div className="custom-ui-buttons-modal">
                        <button className="golden-button withdrawals-confirm-modal" onClick={handleCashWithdrawal}>
                            <FaEdit /> Registrar
                        </button>
                    </div>
                </>
            ) : (
                <div className="withdrawals-table">
                    <div className="withdrawals-table__header">
                        <div className="withdrawals-table__header-item">Monto</div>
                        <div className="withdrawals-table__header-item">Motivo</div>
                        <div className="withdrawals-table__header-item">Usuario</div>
                        <div className="withdrawals-table__header-item">Fecha</div>
                    </div>
                    <div className="withdrawals-table__content">
                        {cashWithdrawals.map((withdrawal) => (
                            <div key={withdrawal.id} className="withdrawals-table__row">
                                <div className="withdrawals-table__item">${withdrawal.amount}</div>
                                <div className="withdrawals-table__item">{withdrawal.reason}</div>
                                <div className="withdrawals-table__item">{withdrawal.user_name}</div>
                                <div className="withdrawals-table__item">{formatDate(withdrawal.created_at)}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className="toggle-view-button">
                <button className="golden-buttn withdrawals-cancel-modal" onClick={closeCashWithdrawalModal}>
                    Cancelar
                </button>
                <button className='withdrawals-history-modal' onClick={toggleView}>
                    {showRegistrationForm ? <FaClockRotateLeft /> : <FaEdit />}
                    {showRegistrationForm ? 'Ver Historial' : 'Nuevo retiro'}
                </button>
            </div>
        </Modal>
    )
}

export default CashWithdrawalModal;