import { useState, createContext, useEffect } from "react"

// Context
export const SessionContext = createContext(null);

const SessionProvider = (props) => {

    const [userData, setUserData] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('userJWT'));
    const [initialMoney, setInitialMoney] = useState(localStorage.getItem('initialMoney'));
    const [selectedNavbar, setSelectedNavbar] = useState('sales');

    // Logout
    const logout = () => {
        setUserData(null);
        setToken(null);
        sessionStorage.removeItem('selectedNavbarOption');
    }

    // Initial state
    useEffect(() => {
        try {
            setUserData(JSON.parse(localStorage.getItem('userData')));
        } catch (error) {
            console.log(error)
            setUserData(null);
        }
    }, []);

    // Save userData
    useEffect(() => {
        if(userData) {
            localStorage.setItem('userData', JSON.stringify(userData));
        } else {
            localStorage.removeItem('userData');
        }
    }, [userData]);

    // Save token
    useEffect(() => {
        if(token) {
            localStorage.setItem('userJWT', token);
        } else {
            localStorage.removeItem('userJWT');
        }
    }, [token]);

    // Save initialMoney
    useEffect(() => {
        if(initialMoney && !isNaN(initialMoney)) {
            localStorage.setItem('initialMoney', initialMoney);
        } else {
            localStorage.removeItem('initialMoney');
        }
    }, [initialMoney]);

    const value = {
        userData, setUserData,
        token, setToken,
        initialMoney, setInitialMoney,
        logout,
        selectedNavbar, setSelectedNavbar,
    }

    return(
        <SessionContext.Provider value={value}>
            {props.children}
        </SessionContext.Provider>
    );
}

export default SessionProvider;