import Navbar from "../../components/Navbar/Navbar";
import { FaUserFriends } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";

import React from "react";
import "./MyUser.css";

import { useContext } from "react";
import { SessionContext } from "../../hooks/useSession";

function MyUser() {
  const { userData, logout } = useContext(SessionContext);

  return (
    <div className="parent-myuser">
      <Navbar />
      <div class="logout-container">
        <div class="logout">
          <div class="ext-photo inner-logout-left">
            <div class="user-photo">
              <FaUserFriends color="white" size={110} />
            </div>
          </div>
          <div class="logout-input-container inner-logout-right">
            <label htmlFor="username" class="logout-label">
              Nombre de usuario
            </label>
            <input
              type="text"
              id="username"
              className="logout-input"
              placeholder={(userData?.name) ? userData?.name : 'N/A'}
              class="field-logout"
              disabled
            />
            <div class="buttons-container">
              <button id="logout-btn" type="button" onClick={logout}>
                 Cerrar sesión <IoLogOut className='icon-out' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyUser;
