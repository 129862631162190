import BACK_END from '../../config/direction';
import logo from '../../assets/logo.png';
import React, { useState } from 'react';
import './Login.css';

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';
import { toast } from 'react-toastify';

const Login = () => {

  const { setToken, setUserData } = useContext(SessionContext);

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const login = async () => {

    await fetch(
      `${BACK_END}/auth/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user, password }),
      }
    )
    .then(async (response) => {
      const data = await response.json();
      
      if(response.ok) {
        setToken(data.token);
        setUserData(data.userData);
        console.log(data.userData)
      } else {
        toast.info(data.error, { autoClose: 1000, hideProgressBar: true });
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  return (
    <div className="login-container">
      <div className="login-box">

        <div className='login-header-container'>
          <h2 className='login-header-text'>Bienvendio a</h2>
        </div>

        <div className='login-logo-container'>
          <img src={logo} alt="Logo" height={90} />
        </div>

        <div className="login-input-container">
          <label htmlFor="username" className='login-label'>Nombre de usuario</label>
          <input
            type="text"
            id="username"
            className="login-input"
            placeholder='Usuario'
            value={user}
            onChange={(event) => setUser(event.target.value)}
            autoComplete='off'
            autoFocus={true}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                document.getElementById("password").focus();
              }
            }}
          />
        </div>

        <div className="login-input-container">
          <label htmlFor="password" className='login-label'>Contraseña</label>
          <input
            type="password"
            id="password"
            className="login-input"
            placeholder='Contraseña'
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                login();
              }
            }}
          />
        </div>

        <button className="login-button golden-button" onClick={login}>Iniciar sesión</button>

      </div>
    </div>
  );
};

export default Login;
