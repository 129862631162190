import { BsFillPersonVcardFill, BsFillCheckCircleFill } from 'react-icons/bs';
import { FaKey, FaTimesCircle,  } from 'react-icons/fa';
import Navbar from '../../components/Navbar/Navbar';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BACK_END from '../../config/direction';
import { PiTextTBold } from 'react-icons/pi';
import { toast } from 'react-toastify';
import Dropdown from 'react-dropdown';
import './AddUser.css';
import { FaUser } from 'react-icons/fa6';

function AddUser() {

    const navigate = useNavigate();

    const roleOptions = ['Administrador', 'Personal de caja'];

    const [name, setName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState(null);

    const inputNameRef = useRef();
    const inputUserNameRef = useRef();
    const inputPasswordRef = useRef();

    const validFields = () => {
        let isValid = true;

        if(name === '') {
            isValid = false;
            toast.info('Falta el nombre', { hideProgressBar: true, autoClose: 2000 });
        }
        else if(userName === '') {
            isValid = false;
            toast.info('Falta el nombre de usuario', { hideProgressBar: true, autoClose: 2000 });
        }
        else if(password === '') {
            isValid = false;
            toast.info('Falta la contraseña', { hideProgressBar: true, autoClose: 2000 });
        }
        else if(role === null) {
            isValid = false;
            toast.info('Falta seleccionar un rol', { hideProgressBar: true, autoClose: 2000 });
        }

        return isValid;
    }

    const registerUser = () => {
        if(validFields()) {
            fetch(`${BACK_END}/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, username: userName, password, rol: role.value }),
            })
            .then(async (response) => {
                const data = await response.json();
                if (response.ok) {
                    toast.success(data.message, { hideProgressBar: true, autoClose: 1000 });
                    navigate('/users');
                } else {
                    toast.error(data.error, { hideProgressBar: true, autoClose: 1000 });
                }
            })
            .catch((error) => {
                console.error(error);
            });
        }
    }

    return (
        <div className="gen-container">
            <Navbar />
            <div className='father-container'>
                <div className='user-card shadow-div'>
                    <div className="card-title">
                        <h1 className='product-header-text'>Agregar Nuevo Usuario</h1>

                    </div>
                    <br />
                    <div className='add-user-input'>
                        <PiTextTBold size={32} color='000' />
                        <input
                            maxLength={120}
                            type='text'
                            placeholder='Nombre del empleado'
                            value={name}
                            onChange={(value) => setName(value.target.value)}
                            autoFocus
                            ref={inputNameRef}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputUserNameRef.current.focus();
                                }
                            }}
                        />
                    </div>

                    <div className='add-user-input'>
                        <FaUser size={32} color='000' />
                        <input
                            maxLength={120}
                            type='text'
                            placeholder='Nombre de usuario'
                            value={userName}
                            onChange={(value) => setUserName(value.target.value)}
                            autoFocus
                            ref={inputUserNameRef}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputPasswordRef.current.focus();
                                }
                            }}
                        />
                    </div>

                    <div className='add-user-input'>
                        <FaKey size={22} color='000' />
                        <input
                            maxLength={120}
                            type='password'
                            placeholder='Contraseña'
                            value={password}
                            onChange={(value) => setPassword(value.target.value)}
                            autoFocus
                            ref={inputPasswordRef}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    inputNameRef.current.focus();
                                }
                            }}
                        />
                    </div>

                    <div className='add-user-dropdown'>
                        <BsFillPersonVcardFill size={32} color='000'/>
                        <Dropdown
                            className='add-customer-scroll'
                            options={roleOptions}
                            onChange={(text) => setRole(text)}
                            value={role}
                            placeholder='Seleccione un rol'
                        />
                    </div>

                    <div className='user-button-container'>
                        <button className='cancel-button' onClick={() => navigate(-1)}><FaTimesCircle size={32} color='d06154' />Cancelar</button>
                        <button className='save-button' onClick={registerUser}> <BsFillCheckCircleFill size={32} color='04c055' />Guardar</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AddUser;