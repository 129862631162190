import UtilityPayments from './UtilityPayments/UtilityPayments';
import UtilityMonthly from './UtilityMonthly/UtilityMonthly';
import UtilityMonth from './UtilityMonth/UtilityMonth';
import Navbar from '../../components/Navbar/Navbar';
import React from 'react';

import './Utilities.css';
import UtilityStock from './UtilityStock/UtilityStock';
import UtilityTopDepartments from './UtilityTopDepartments/UtilityTopDepartments';
import UtilityTopMargin from './UtilityTopMargin/UtilityTopMargin';
import UtilityCashier from './UtilityCashier/UtilityCashier';
import UtilityRestock from './UtilityRestock/UtilityRestock';
import UtilityOrigin from './UtilityOrigin/UtilityOrigin';

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';
import UtilityStockCost from './UtilityStockCost/UtilityStockCost';
import UtilityStockByDepartment from './UtilityStockByDepartment/StockByDepartment';

const Utilities = () => {

  const { userData } = useContext(SessionContext);

  const isAdmin = userData && userData.rol === "Administrador";

  return (
    <div className="utilities-parent">
      <Navbar />
      <div className='utilities-container'>
        <div className='utilities-box'>
          <div className="utilities-grid-container">
            {isAdmin && <UtilityMonth />}
            {isAdmin && <UtilityMonthly />}
            <UtilityPayments />
            {isAdmin && <UtilityStock />}
            {isAdmin && <UtilityStockCost />}
            <UtilityTopDepartments />
            <UtilityOrigin />
            {isAdmin && <UtilityTopMargin />}
            <UtilityCashier />
            <UtilityRestock />
            <UtilityStockByDepartment />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Utilities;