import { FaPlusCircle, FaSearch } from "react-icons/fa";
import Dropdown from 'react-dropdown';
import Navbar from "../../components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import BACK_END from "../../config/direction";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import "react-dropdown/style.css";
import "./Customers.css";
import { MdEdit, MdList } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomerPaymentsModal from "../../components/CustomerPaymentsModal/CustomerPaymentsModal";
import { SessionContext } from "../../hooks/useSession";
import { useContext } from "react";

import SectionNavbar from '../../components/SectionNavbar/SectionNavbar';

const Customers = () => {
  const { userData } = useContext(SessionContext);
  const isAdmin = userData && userData.rol === "Administrador";

  const [customerName, setCustomerName] = useState("");
  const [selectedDebt, setSelectedDebt] = useState(null);
  const [customers, setCustomers] = useState([]);

  const [selectedCustomerId, setSelectedCustomerId] = useState(null);

  const navigate = useNavigate();

  const toastySuccess = (msg) => {
    toast.success(`${msg}`);
  };
  const toastyError = (msg) => {
    toast.error(`${msg}`);
  };

  const options = ['Todos', 'Si', 'No'];

  const formatNumber = (number) => {
    number = Number(number);
    return number.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  useEffect(() => {
    fetch(`${BACK_END}/customer/0`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }).then(async (res) => {
      const response = await res.json();
      if (res.ok) {
        setCustomers(response.data);
      } else {
        toastyError("Ocurrió un error inesperado");
        console.log(res.error);
      }
    });
  }, []);

  const deleteCustomer = (customer) => {
    const deleteConfirmed = () => {
      const data = { id: customer.customer_id };
      fetch(`${BACK_END}/customer`, {
        method: "DELETE",
        mode: "cors",
        cache: "default",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      })
        .then(async (res) => {
          const response = await res.json();
          if (res.ok) {
            const index = customers.indexOf(customer);
            setCustomers([
              ...customers.slice(0, index),
              ...customers.slice(index + 1),
            ]);
            toastySuccess("Cliente eliminado");
          } else {
            toastyError("Ocurrió un error inesperado");
            console.log(response.error);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="overlay">
            <div className="custom-ui">
              <h1>Confirmar eliminación</h1>
              <p>¿Estás seguro de que deseas eliminar este cliente?</p>
              <div className="custom-ui-buttons">
                <button onClick={onClose}>Cancelar</button>
                <button
                  onClick={() => {
                    deleteConfirmed();
                    onClose();
                  }}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const filterCustomers = () => {
    fetch(`${BACK_END}/customer/filter/0`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: customerName,
        debtOption: selectedDebt?.value || '',
      }),
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.ok) {
          setCustomers(response.data);
        } else {
          toastyError("Ocurrió un error inesperado");
          console.log(response.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(filterCustomers, [customerName, selectedDebt]);

  const openModal = (customer_id) => {
    setSelectedCustomerId(customer_id);
  };

  const closeModal = () => {
    setSelectedCustomerId(null);
  };

  return (
    <div className="parent-customers">
      <Navbar />
      <div className='customers-container'>
        <SectionNavbar
          navbarData={[{ name: 'Clientes', link: '/customers' }]}
          setSelectedOption={() => { }}
          selectedOption={1}
        />
        <div className="inventory-container">
          <div className="customers-box-border">
            <div className="customers-box-container">
              <div className="customers-box">
                <div className="customers-header">
                  {!isMobile && <div className="header-item">Nombre</div>}
                  {!isMobile && <div className="header-item">Teléfono</div>}
                  <div className="header-item">Deuda</div>
                  {!isMobile && <div className="header-item">Puntos</div>}
                  <div className="header-item">Origen</div>
                  {!isMobile && <div className="header-item">Opciones</div>}
                </div>
                <div className="customers-body">
                  {customers.map((customer, index) => (
                    <div
                      className='customers-row'
                      onClick={() => {
                        if (isMobile) {
                          navigate("/edit_customer/" + customer.customer_id);
                        }
                      }}
                      key={index}
                    >
                      <div className="row-item">{customer.name}</div>
                      {!isMobile && <div className="row-item">{customer.phone}</div>}
                      <div className="row-item">${formatNumber(customer.totalDebt)}</div>
                      {!isMobile && <div className="row-item">{formatNumber(customer.points)}</div>}
                      {!isMobile && <div className="row-item">{customer.originName}</div>}

                      <div className="row-item">
                        <div className="options">
                          <button
                            className="option-button"
                            title="Historial"
                            onClick={(e) => {
                              e.stopPropagation();
                              openModal(customer.customer_id);
                            }}
                          >
                            <MdList size={24} color='white' />
                          </button>
                          <button
                            className="option-button"
                            title="Editar"
                            onClick={() =>
                              navigate("/edit_customer/" + customer.customer_id)
                            }
                          >
                            <MdEdit size={24} color='white' />
                          </button>
                          {
                            isAdmin &&
                            <button
                              className="option-button-delete"
                              title="Eliminar"
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteCustomer(customer);
                              }}
                            >
                              <RiDeleteBin6Line size={24} color='white' />
                            </button>
                          }
                        </div>
                      </div>

                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-row">
            <button
              className="add-customer golden-button"
              onClick={() => navigate("/add_customer")}
            >
              Agregar <FaPlusCircle size={24} />{" "}
            </button>
            <div className="search-bar-customer">
              <FaSearch className="search__icon" />
              <input
                type="text"
                onChange={(event) => setCustomerName(event.target.value)}
                placeholder="Búsqueda"
                autoFocus={!isMobile}
              />
            </div>
            <Dropdown className='customer-scroll' options={options} onChange={(text) => setSelectedDebt(text)} value={selectedDebt} placeholder='¿Debe?' />
          </div>
        </div>
      </div>
      <CustomerPaymentsModal
        onRequestClose={closeModal}
        customer_id={selectedCustomerId}
      />
    </div>
  );
};

export default Customers;