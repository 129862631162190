import React, { useState } from 'react';
import Modal from 'react-modal';
import './DeliveryCodeModal.css';

const DeliveryCodeModal = ({ isModalOpen, closeModal, handleAccept }) => {

    const [deliveryCode, setDeliveryCode] = useState('');

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className="delivery-code-ui-modal"
            overlayClassName="overlay-modal"
            contentLabel="Ingrese el código"
        >
            <h2>Registrar envío</h2>
            <p className='delivery-code-ui-modal-header'>Número de guía para seguimiento:</p>
            <input
                type="text"
                value={deliveryCode}
                onChange={(e) => setDeliveryCode(e.target.value)}
                placeholder="Ingrese el código de seguimiento"
                autoFocus
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleAccept(deliveryCode);
                        setDeliveryCode('');
                    }
                }}
            />
            <div className="delivery-code-ui-buttons-modal">
                <button className="delivery-code-cancel-modal" onClick={closeModal}>Cancelar</button>
                <button className="delivery-code-confirm-modal" onClick={() => {handleAccept(deliveryCode); setDeliveryCode('')}}>Envíar</button>
            </div>
        </Modal>
    )
}

export default DeliveryCodeModal;