import axios from 'axios';

// Print Demo
export const printTicketDemo = async () => {

    const response = await axios.get(`http://localhost:3333/demo`, {
        
    }).catch(error => {
        console.error(error);
    });

    return response;
};

export const printTicket = async (cashier, cart, date, paid, total, change, discount, bill_id) => {
    console.log(bill_id)

    const response = await axios.post(`http://localhost:3333/print`, {
        cashier,
        cart,
        date,
        paid,
        total,
        change,
        discount,
        bill_id
    }).catch(error => {
        console.error(error);
    });

    return response;
};

export const printDebtPayment = async (data) => {
    const response = await axios.post(`http://localhost:3333/printDebtPayment`,
        data
    ).catch(error => {
        console.error(error);
    });
    return response;
};

export const printCut = async (cutData) => {

    const response = await axios.post(`http://localhost:3333/printCut`, {
        cutData
    }).catch(error => {
        console.error(error);
    });

    return response;
};

export const openCashBox = async () => {
    await axios.post(`http://localhost:3333/openCashBox`).catch(error => {
        console.error(error);
    });
}