import { BsTrashFill, BsFillCartXFill } from 'react-icons/bs';
import { FaPlus, FaMinus, FaKey } from 'react-icons/fa6';
import { SessionContext } from '../../hooks/useSession';
import { confirmAlert } from 'react-confirm-alert';
import React, { useEffect, useState } from 'react';
import { FaMoneyBill } from 'react-icons/fa';
//import { GiBackForth } from "react-icons/gi";
import { toast } from 'react-toastify';
import { useContext } from 'react';
import './Receipt.css';

import logo from '../../assets/logo.png';
import { openCashBox } from '../../util/pos_esc';

const Receipt = ({ cart, setCart, setPayingState, cartTotal, setCartTotal, stage, discount, setDiscount, cancel2x1, setCancel2x1 }) => {

   const [totalCartOriginal, setTotalCartOriginal] = useState(0.0);

   const [cart2x1, setCart2x1] = useState([]);

   const { userData } = useContext(SessionContext);

   const isAdmin = userData && userData.rol === "Administrador";

   const currentDate = new Date();
   const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
   let dateDisplay = currentDate.toLocaleDateString('es-MX', options);
   dateDisplay = dateDisplay.charAt(0).toUpperCase() + dateDisplay.slice(1, -8);

   // Sort cart's products by price
   const sortCartByPrice = (cart) => {
      return [...cart].sort((a, b) => b.product.price - a.product.price);
   };

   // Number of products in the cart
   const numberOfProductsInCart = () => cart.reduce((total, producto) => total + producto.amount, 0);

   // Group products by sections
   const groupProducts = (sortedCart) => {

      if (sortedCart.length === 0) {
         setCart2x1([]);
         return sortedCart;
      }

      let products2x1 = [];
      let productsDiscount = [];

      sortedCart.forEach((item) => {
         if (products2x1.length === 0) {
            if (item.amount > 2) {
               products2x1.push({ ...item, amount: 1 });
               products2x1.push({ ...item, amount: 1 });
               productsDiscount.push({ ...item, amount: item.amount - 2 });
            }
            if (item.amount === 2) {
               products2x1.push({ ...item, amount: 1 });
               products2x1.push({ ...item, amount: 1 });
            }
            else if (item.amount === 1) {
               products2x1.push({ ...item, amount: 1 });
            }
         } else if (products2x1.length === 1) {
            if (item.amount >= 2) {
               products2x1.push({ ...item, amount: 1 });
               productsDiscount.push({ ...item, amount: item.amount - 1 });
            }
            else if (item.amount === 1) {
               products2x1.push({ ...item, amount: 1 });
            }
         } else {
            productsDiscount.push(item);
         }
      });

      setCart2x1(products2x1);

      return [...products2x1, ...productsDiscount];
   };

   // Update total
   const updateTotal = () => {
      if (cart.length > 0) {
         if (cart2x1.length === 2 && numberOfProductsInCart() === 2 && !cancel2x1) {
            const total = cart2x1.reduce((accumulated, item) => accumulated + Number(item.product.price), 0).toFixed(2);
            setTotalCartOriginal(total);
            setCartTotal(Number(cart2x1[0].product.price).toFixed(2));
         }
         else {
            const totalInCart = cart
               .reduce(
                  (accumulated, item) => (accumulated + Number(item.product.price) * item.amount),
                  0
               )
               .toFixed(2);

            const totalDiscount = cart.reduce((accumulated, item) => (accumulated + item.product.price * item.amount * discount / 100), 0).toFixed(2);

            setTotalCartOriginal(totalInCart);
            setCartTotal(Number(totalInCart - totalDiscount).toFixed(2));
         }
      } else {
         setCartTotal('0.00');
         setTotalCartOriginal('0.00');
      }
   }

   // Remove product from the cart
   const quitProduct = (product) => {
      var productIndex = cart.findIndex((item) => item.product.product_id === product.product_id);

      if (productIndex !== -1) {
         var updatedCart = [...cart];
         updatedCart.splice(productIndex, 1);
         setCart(updatedCart);
      }
   };

   // Add product to the cart
   const additionProduct = (product) => {
      const productIndex = cart.findIndex((item) => item.product.product_id === product.product_id);

      if (productIndex !== -1) {
         if (cart[productIndex].amount < product.stock) {
            let updatedCart = [...cart];
            updatedCart[productIndex].amount += 1;
            setCart(updatedCart);
         } else {
            toast.info('Límite del stock alcanzado', { autoClose: 1000, hideProgressBar: true });
         }
      }
   };

   // Subtract product from the cart
   const subtractProduct = (product) => {
      let productIndex = cart.findIndex((item) => item.product.product_id === product.product_id);

      if (productIndex !== -1) {
         let updatedCart = [...cart];
         if (updatedCart[productIndex].amount > 1) {
            updatedCart[productIndex].amount -= 1;
            setCart(updatedCart);
         } else {
            // Remove the product from the cart if it's amount is 1
            updatedCart.splice(productIndex, 1);
            setCart(updatedCart);
         }
      }
   };

   // Empty cart
   const cleanCart = () => {
      confirmAlert({
         customUI: ({ onClose }) => {
            return (
               <div className="overlay">
                  <div className="custom-ui">
                     <h1>Confirmarción</h1>
                     <p>¿Desea eliminar todos los productos que tiene actualmente en el carrito?</p>
                     <div className="custom-ui-buttons">
                        <button onClick={onClose}>Cancelar</button>
                        <button
                           onClick={() => {
                              setCart([]);
                              setCart2x1([]);
                              toast.success('Carrito vaciado', { hideProgressBar: true, autoClose: 1000 });
                              onClose();
                           }}
                        >
                           Eliminar
                        </button>
                     </div>
                  </div>
               </div>
            );
         },
      });
   }

   const changePayState = () => {
      if (cart.length > 0) {
         setPayingState(1);
      } else {
         toast.info('Carrito vacío', { autoClose: 2000, hideProgressBar: true });
      }
   }

   useEffect(() => {
      const prodcutsInTheCart = numberOfProductsInCart();
      if (prodcutsInTheCart === 0) {
         setDiscount(0)
      } else if (prodcutsInTheCart > 2 && discount === 0) {
         setDiscount(50);
      }
      const listSorted = sortCartByPrice(cart);
      groupProducts(listSorted); // eslint-disable-next-line
   }, [cart, setCartTotal, discount, cancel2x1]);

   // eslint-disable-next-line
   useEffect(updateTotal, [cart, setCartTotal, cart2x1, discount, cancel2x1])

   const increaseDiscount = () => {
      if (discount < 100) {
         if (discount + 5 > 100) {
            setDiscount(100);
         } else {
            setDiscount(discount + 5);
         }
      }
   }

   const decreaseDiscount = () => {
      if (discount > 0) {
         if (discount - 5 < 0) {
            setDiscount(0);
         } else {
            setDiscount(discount - 5);
         }
      }
   }

   return (
      <div className='ticket-card'>

         <h3 className='sales-title'>Ticket actual</h3>
         <div className='products-list'>
            <div className='pay-box'>
               <div className='header'>
                  <img src={logo} alt='logo' className="img-logo" />
                  <p className='sales-date'>{dateDisplay}</p>
               </div>

               <div className={stage === 0 ? 'products-box' : 'products-box-checkout'}>
                  {
                     (cart.length <= 1 && cart2x1.length <= 1)
                        ?
                        cart.map((item, index) =>
                           <div
                              className='sales-product-column'
                              key={`cart_column_${index}`}
                           >
                              <div className='recipent-header-row recipent-header-margin-left'>
                                 <p className='recipent-section-header'>{discount}% DESCUENTO</p>
                                 <div className='sales-button-row'>
                                    <button className='sale-pad-button' onClick={increaseDiscount}>
                                       <FaPlus size={16} color='black' />
                                    </button>
                                    <button className='sale-pad-button' onClick={decreaseDiscount}>
                                       <FaMinus size={16} color='black' />
                                    </button>
                                 </div>
                              </div>
                              <div className='sales-product-row'>
                                 <p className='juguete'>{item.product.name}</p>
                                 <p className='juguete' >({item.amount})</p>
                                 <span className='sales-dot-separator'></span>
                                 <p className='juguete' >${item.product.price}</p>
                              </div>
                              <div className='sales-product-row'>
                                 <div className='sales-button-row'>
                                    <button className='sale-pad-button' onClick={() => additionProduct(item.product)}>
                                       <FaPlus size={16} color='black' />
                                    </button>
                                    <button className='sale-pad-button' onClick={() => subtractProduct(item.product)}>
                                       <FaMinus size={16} color='black' />
                                    </button>
                                    <button className='sale-pad-button' onClick={() => quitProduct(item.product)}>
                                       <BsTrashFill size={16} color='black' />
                                    </button>
                                 </div>
                              </div>
                           </div>
                        )
                        :
                        <>
                           {
                              (numberOfProductsInCart() === 2)
                                 ?
                                 <div className='recipent-section'>
                                    {
                                       (!cancel2x1) ?
                                          <div className='recipent-header-row'>
                                             <p className='recipent-section-header'>DOSX1</p>
                                             <button className='recipent-cancel2x1' onClick={() => setCancel2x1(true)}>Cancelar 2x1</button>
                                          </div>
                                          :
                                          <div className='recipent-header-row'>
                                             <p className='recipent-section-header'>{discount}% DESCUENTO</p>
                                             <div className='sales-button-row'>
                                                <button className='sale-pad-button' onClick={increaseDiscount}>
                                                   <FaPlus size={16} color='black' />
                                                </button>
                                                <button className='sale-pad-button' onClick={decreaseDiscount}>
                                                   <FaMinus size={16} color='black' />
                                                </button>
                                             </div>
                                             <button className='recipent-cancel2x1' onClick={() => setCancel2x1(false)}>Activar 2x1</button>
                                          </div>
                                    }
                                    <div className='product-2x1 background-animation'>
                                       {
                                          cart2x1.map((item, index) =>
                                             <div
                                                className='sales-product-column'
                                                key={`DOSX1_${index}`}
                                             >
                                                <div className='sales-product-row'>
                                                   <p className='juguete'>{item.product.name}</p>
                                                   <p className='juguete' >({item.amount})</p>
                                                   <span className='sales-dot-separator'></span>
                                                   <p className='juguete' >${item.product.price}</p>
                                                </div>
                                                <div className='sales-product-row'>
                                                   <div className='sales-button-row'>
                                                      <button className='sale-pad-button' onClick={() => additionProduct(item.product)}>
                                                         <FaPlus size={16} color='black' />
                                                      </button>
                                                      <button className='sale-pad-button' onClick={() => subtractProduct(item.product)}>
                                                         <FaMinus size={16} color='black' />
                                                      </button>
                                                      <button className='sale-pad-button' onClick={() => quitProduct(item.product)}>
                                                         <BsTrashFill size={16} color='black' />
                                                      </button>
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       }
                                    </div>
                                 </div>
                                 :
                                 <div className='recipent-section'>
                                    <div className='recipent-header-row'>
                                       <p className='recipent-section-header'>{discount}% DESCUENTO</p>
                                       <div className='sales-button-row'>
                                          <button className='sale-pad-button sales-pad-button-offer' onClick={increaseDiscount}>
                                             <FaPlus size={16} color='black' />
                                          </button>
                                          <button className='sale-pad-button sales-pad-button-offer' onClick={decreaseDiscount}>
                                             <FaMinus size={16} color='black' />
                                          </button>
                                       </div>
                                    </div>
                                    <div className='product-discount'>
                                       {
                                          cart.map((item, index) =>
                                             <div
                                                className='sales-product-column'
                                                key={`cart_item_${index}`}
                                             >
                                                <div className='sales-product-row'>
                                                   <p className='juguete'>{item.product.name}</p>
                                                   <p className='juguete' >({item.amount})</p>
                                                   <span className='sales-dot-separator'></span>
                                                   <p className='juguete' >${item.product.price}</p>
                                                </div>
                                                <div className='sales-product-row'>
                                                   <div className='sales-button-row'>
                                                      <button className='sale-pad-button' onClick={() => additionProduct(item.product)}>
                                                         <FaPlus size={16} color='black' />
                                                      </button>
                                                      <button className='sale-pad-button' onClick={() => subtractProduct(item.product)}>
                                                         <FaMinus size={16} color='black' />
                                                      </button>
                                                      <button className='sale-pad-button' onClick={() => quitProduct(item.product)}>
                                                         <BsTrashFill size={16} color='black' />
                                                      </button>
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       }
                                    </div>
                                 </div>
                           }
                        </>
                  }
               </div>

            </div>

            <div className='receipt-total-container'>
               <div className='row-subtotal'>
                  <h4>Subtotal: ${totalCartOriginal}</h4>
               </div>
               {
                  (stage === 0 && cart.length > 0)
                  &&
                  <button className='empty-cart-button' onClick={cleanCart}>
                     <BsFillCartXFill className="empty-cart-icon" />
                  </button>
               }
               <div className='row-total'>
                  <h2>Total: </h2>
                  <div className='row-total-money'>
                     <h2>${cartTotal}</h2>
                  </div>
               </div>
            </div>

         </div>

         <div className='receipt-button-row'>
            {/*stage === 0 &&
               <button className='receipt-cash-box-button receipt-cash-box-button--hover-shadow' onClick={openModal}>
                  <GiBackForth size={24} color='000000' />
               </button>
            */}
            {stage === 0 &&
               <button className='charge charge--hover-shadow golden-button' onClick={changePayState}>
                  <FaMoneyBill size={38} color='000000' className='charge-icon' />
                  Cobrar
               </button>
            }
            {(isAdmin && stage === 0) ?
               <button className='receipt-cash-box-button receipt-cash-box-button--hover-shadow' onClick={openCashBox}>
                  <FaKey size={32} color='000000' />
               </button>
               :
               <></>
            }
         </div>
      </div>
   )
}

export default Receipt;