import { GiSwipeCard, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import React, { useEffect, useState, useRef, useContext } from "react";
import { FaUserAlt, FaTimesCircle } from "react-icons/fa";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";
import Navbar from "../../components/Navbar/Navbar";
import "react-toastify/dist/ReactToastify.css";
import BACK_END from "../../config/direction";
import { toast } from "react-toastify";
import "./PayDebt.css";
import { SessionContext } from "../../hooks/useSession";
import { printDebtPayment } from "../../util/pos_esc";
import { TiPrinter } from "react-icons/ti";

const PayDebt = () => {

  const { userData } = useContext(SessionContext);

  const location = useLocation();
  const { billId } = useParams();
  const { state: { customer_id , clean} } = location;
  const navigate = useNavigate();

  const [customerData, setCustomerData] = useState(null);

  const [bill, setBill] = useState(null);
  const userJWT = useContext(SessionContext)?.token;

  const [cashAmount, setCashAmount] = useState("");
  const [cardAmount, setCardAmount] = useState("");

  const [totalState, setTotalState] = useState("");
  const [nameState, setNameState] = useState("");
  const [debtState, setDebtState] = useState(0.0);
  const newDebtRef = useRef(0.0);

  const [ticketData, setTicketData] = useState(null);
  const [isPaied, setIsPaied] = useState(false);

  const handleCashChange = (amount) => {
    setCashAmount(amount);
    calculateRemainingDebt(amount, cardAmount);
  };

  const handleCardChange = (amount) => {
    setCardAmount(amount);
    calculateRemainingDebt(cashAmount, amount);
  };

  const calculateRemainingDebt = (cash, card) => {
    const totalReceived = Number(cash) + Number(card);
    const newDebt = debtState - totalReceived;
    newDebtRef.current = newDebt > 0 ? newDebt : 0;
    setTotalState(newDebt < 0 ? Math.abs(newDebt) : 0);
  };

const cleanSales=()=>{
  localStorage.removeItem('cartData');
}

  useEffect(() => {
    if(clean){
      cleanSales();
    }
    fetch(`${BACK_END}/customer/${customer_id}`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }).then(async (res) => {
      const response = await res.json();
      if (res.ok) {
        setCustomerData(response[0]);
        setNameState(response[0].name);
        newDebtRef.current = response[0].debt;
      } else {
        toastyError("Ocurrió un error inesperado");
        console.log(res.error);
      }
    });

    fetch(`${BACK_END}/bill/${billId}`, {
      method: "GET",
      mode: "cors",
      cache: "default",
      headers: {
        "Content-Type": "application/json",
        'Authentication': userJWT,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    }).then(async (res) => {
      const response = await res.json();
      if (res.ok) {
        setBill(response);
        setDebtState(response.debtOutstanding);
      } else {
        toastyError("Ocurrió un error al obtener los detalles de la factura");
        console.log(res.error);
      }
    }); // eslint-disable-next-line
  }, [customer_id, billId]);

  const toastySuccess = (msg) => {
    toast.success(`${msg}`);
  };

  const toastyWarn = (msg) => {
    toast.warn(`${msg}`,
    );
  };
  const toastyError = (msg) => {
    toast.error(`${msg}`,);
  };

  const isValidNumber = (value) => {
    return !isNaN(value) && Number(value) >= 0;
  };

  const payDebt = () => {

    if (!bill) {
      toastyError("Falta información de la factura.");
      return;
    }

    if (!isValidNumber(cashAmount) || !isValidNumber(cardAmount)) {
      toastyError("Ingrese montos válidos.");
      return;
    }

    const totalReceived = Number(cashAmount) + Number(cardAmount);
    if (totalReceived === 0) {
      toastyWarn("El monto total debe ser mayor a 0.");
      return;
    }

    const debtChanged = newDebtRef.current !== debtState;
    if (debtChanged) {
      if (bill.debtOutstanding - Number(cardAmount) < 0 && Number(cashAmount) <= 0) {
        toastyWarn("El pago con tarjeta no puede ser mayor a la deuda");
        return;
      }

      // Calcular el efectivo pagado sin contar el sobrante
      const cashPaid = Number(cashAmount) - (totalState > 0 ? totalState : 0);

      if (cashPaid < 0) {
        toastyWarn("No se puede pagar con efectivo si la tarjeta paga toda la deuda.");
        return;
      }

      const formData = {
        id: customerData.customer_id,
        bill_id: bill.bill_id,
        debt: newDebtRef.current,
        cash: cashPaid,
        card: Number(cardAmount),
        change: Number(totalState),
      };

      fetch(`${BACK_END}/customer/${customer_id}`, {
        method: "PATCH",
        mode: "cors",
        cache: "default",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      }).then(async (res) => {
        const data = await res.json()
        if (res.ok) {

          const formData = {
            cashier: userData?.name,
            customerName: nameState ?? 'N/A',
            date: new Date().toISOString(),
            paid: { cash: cashPaid, card: cardAmount },
            total: debtState,
            change: totalState,
            bill_id: bill.bill_id,
            payment_id: data.payment_id,
          }

          printDebtPayment(formData);
          toastySuccess(data.message);

          setTicketData(formData);
          setIsPaied(true);
        } else {
          toastyError("Ocurrió un error inesperado");
          console.log(res.error);
        }
      }).catch(error => console.error(error));
    } else {
      toastyWarn("La deuda no ha sido pagada");
    }
  };

  const reprintTicket = () => {
    if (ticketData) {
      printDebtPayment(ticketData);
    } else {
      toastyWarn('Datos del ticket no disponibles.');
    }
  }

  return (
    <>
      <div className="parent-paydebt">
        <Navbar />
        <div className="back-container">
          <div className="debt-card">
            {/* Header */}
            <h3 className="debt-header">Abono de apartados</h3>
            <div className="white-container">
              <div className="debt-body">

                <div className="pay-method">
                  <div className="pay-method__instruction">
                    <p>Información del cliente:</p>
                  </div>
                </div>

                {/* Customer name and debt */}
                <div className="customer-rows">
                  <div className="customer-row">
                    <FaUserAlt className="customer-row__icon" size={26} color="000" />
                    <p className="customer-row__text">{nameState}</p>
                  </div>
                  <div className="customer-row">
                    <GiReceiveMoney className="customer-row__icon" size={26} color="000" />
                    <p className="customer-row__text">${debtState}</p>
                  </div>
                </div>

                <div className="pay-method">
                  <div className="pay-method__instruction">
                    <p>Ingrese los montos correspondientes:</p>
                  </div>
                </div>

                {/* Sub and total rows */}
                <div className="qty-rows">
                  <div className="subtotal-row">
                    <p className={(isPaied) ? "debt-text gray__text" : "debt-text"}>Efectivo :</p>
                    <div className="input-box">
                      <GiPayMoney className="customer-row__icon" size={26} color={(isPaied) ? 'gray' : "black"} />
                      <input
                        className="subtotal"
                        type="number"
                        autoFocus
                        placeholder="$0"
                        value={cashAmount}
                        onChange={(e) => handleCashChange(e.target.value)}
                        disabled={isPaied}
                      />
                    </div>
                  </div>

                  <div className="subtotal-row">
                    <p className={(isPaied) ? "debt-text gray__text" : "debt-text"}>Tarjeta :</p>
                    <div className="input-box">
                      <GiSwipeCard className="customer-row__icon" size={26} color={(isPaied) ? 'gray' : "black"} />
                      <input
                        className="subtotal"
                        type="number"
                        placeholder="$0"
                        value={cardAmount}
                        onChange={(e) => handleCardChange(e.target.value)}
                        disabled={isPaied}
                      />
                    </div>
                  </div>

                  <div className="total-row">
                    <p className="debt-text gray__text">Cambio : </p>
                    <div className="input-box">
                      <RiMoneyDollarCircleFill className="customer-row__icon" size={26} color="gray" />
                      <input
                        className="total"
                        type="number"
                        placeholder="$0"
                        value={isNaN(totalState) ? '0.00' : Number(totalState).toFixed(2)}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payDebt-buttons">
              <button
                className={`payDebt--cancel hover-shadow click-shadow ${(isPaied) ? 'payDebt--exit' : ''}`}
                onClick={() => (isPaied) ? navigate('/customers') : navigate(-1)}
              >
                {
                  (isPaied)
                  ?
                  <IoMdArrowRoundBack size={28} color="black" />
                  :
                  <FaTimesCircle size={28} color="F44336" />
                }
                {(isPaied) ? 'Volver' : 'Cancelar'}
              </button>
              <button className="golden-button payDebt--printer" onClick={() => (isPaied) ? reprintTicket() : payDebt()}>
                {(isPaied) ? <TiPrinter /> : <BsFillCheckCircleFill size={23} color="000" />}
                {(isPaied) ? 'Imprimir ticket' : 'Abonar'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default PayDebt;
