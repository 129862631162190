import React, { useContext } from "react";
import { useState } from "react";
import "./Envios.css";
import EstadoEnvio from "../../components/Envios/EstadoEnvio";
import Navbar from '../../components/Navbar/Navbar';
import { BsCalendar3 } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import { MdClose, MdFilterAltOff } from 'react-icons/md';
import Calendar from 'react-calendar';
import { SessionContext } from "../../hooks/useSession";

const Envios = () => {

    const userJWT = useContext(SessionContext)?.token;

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [currentView, setCurrentView] = useState("pendientes");
    const [viewType, setViewType] = useState('pendientes')
    const [rangeDate, setRangeDate] = useState(null);

    const [searchBillId, setSearchBillId] = useState("");

    const weekdayLabels = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];

    const handleChangeComponent = (view) => {
        setCurrentView(view);
        setViewType(view);
    };

    const cleanFilters = () => {
        setRangeDate(null);
    }

    const renderComponent = () => {
        switch (currentView) {
            case "pendientes":
                return (
                    <EstadoEnvio viewType={viewType} rangeDate={rangeDate} search={searchBillId} userJWT={userJWT} />
                );
            case "enviados":
                return (
                    <EstadoEnvio viewType={viewType} rangeDate={rangeDate} search={searchBillId} userJWT={userJWT} />
                );
            case "entregados":
                return (
                    <EstadoEnvio viewType={viewType} rangeDate={rangeDate} search={searchBillId} userJWT={userJWT} />
                );
            default:
                return (
                    <EstadoEnvio />
                );
        }
    }

    return (
        <div className="parent-envios">
            <Navbar />
            <div className="deliveries-tab-container">
                <div className="tab-gen-container">

                    <nav className="delivery-nav-menu">
                        <ul>
                            <li onClick={() => {
                                handleChangeComponent("pendientes")
                                setViewType('pendientes')
                            }}
                                style={{ backgroundColor: '#E84747', color: 'white' }}>PENDIENTES</li>
                            <li onClick={() => {
                                handleChangeComponent("enviados")
                                setViewType('enviados')
                            }}
                                style={{ backgroundColor: '#E8A847', color: 'white' }}>ENVIADOS</li>
                            <li onClick={() => handleChangeComponent("entregados")}
                                style={{ backgroundColor: '#2D8205', color: 'white' }}>ENTREGADOS</li>
                        </ul>
                    </nav>
                    <div className="deliveries-lower-container"

                        style={{

                            backgroundColor: getColor(currentView)
                        }}
                    >
                        <div className="tab-container">
                            {renderComponent()}
                        </div>
                    </div>
                    <div className="delivery-tab-footer">
                        <div className="search-packages-container">
                            <FaSearch className="search-icon" style={{ margin: '5px' }} />
                            <input
                                value={searchBillId}
                                onChange={(e) => setSearchBillId(e.target.value)}
                                className="search-packages"
                                type="text"
                                id="searchInput"
                                placeholder="No. DE PEDIDO"
                            />
                        </div>
                        <button className="date-deploy-button" onClick={() => setShowDatePicker(!showDatePicker)}>
                            <span className="date-values">FECHA</span>
                            <BsCalendar3 size={20} style={{ margin: '5px' }} />
                        </button>

                        {showDatePicker && (
                            <div className="date-modal-picker">
                                <div className="date-picker-container">
                                    <Calendar
                                        tileClassName={({ date, view }) => {

                                            if (view === 'month' && date.toDateString() === new Date().toDateString()) {
                                                return 'highlight-today';
                                            }
                                            return '';
                                        }}
                                        onChange={(date) => setRangeDate(date)}
                                        value={rangeDate}
                                        selectRange={true}
                                        showNeighboringMonth={false}
                                        formatShortWeekday={(locale, date) => weekdayLabels[date.getDay()]}
                                    />
                                    <button className='calendar-close-button1' onClick={() => setShowDatePicker(false)}>
                                        <MdClose size={20} color='black' />
                                    </button>
                                </div>
                            </div>
                        )}

                        <button className="delivery-clean-filters" onClick={cleanFilters}>
                            <MdFilterAltOff size={20} color='black' />
                        </button>
                    </div>
                </div>
            </div>

            <div className="inventory-bottom"></div>
        </div>
    );

};

function getColor(view) {
    switch (view) {
        case "pendientes":
            return '#E84747'; // Light blue for Profile
        case "enviados":
            return '#E8A847'; // Light red for Orders
        case "entregados":
            return '#2D8205'; // Light green for Settings
        default:
            return 'transparent'; // Default no selection
    }
}

export default Envios;