import { FaExchangeAlt, FaMoneyBill, FaUserAlt } from 'react-icons/fa';
import { MdAddCircle, MdCancel } from 'react-icons/md';
import { PiHandCoinsFill } from "react-icons/pi";
import { FaRegCreditCard } from "react-icons/fa";
import { BsCash } from "react-icons/bs";
import { MdOutlineAttachMoney } from "react-icons/md";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import BACK_END from '../../config/direction';
import { toast } from 'react-toastify';

import { useContext } from 'react';
import { SessionContext } from '../../hooks/useSession';

import './Register.css';
import '../../assets/shadows/shadow.css';
import { printTicket } from '../../util/pos_esc';
import { IoMdArrowRoundBack } from 'react-icons/io';

const Register = ({ cart, setPayingState, cartTotal, setCart, afterSell, discount, cancel2x1, setCancel2x1 }) => {

   const selectedColor = '#D9AA08';

   const navigate = useNavigate();

   // User data
   const { userData } = useContext(SessionContext);

   // Payment mode state: 'contado' or 'deuda'
   const [paymentMode, setPaymentMode] = useState('contado');

   // Mix pay method (cash, card, debt)
   const [cash, setCash] = useState('');
   const [card, setCard] = useState('');
   const [usePoints, setUsePoints] = useState(false);

   // Focus
   const [isCashFocused, setIsCashFocused] = useState(false);
   const [isCardFocused, setIsCardFocused] = useState(false);

   // Paied data
   const [change, setChange] = useState('0.00');
   const [missing, setMissing] = useState('0.00')

   // Customer data
   const [nameOrPhone, setNameOrPhone] = useState('');
   const [customer, setCustomer] = useState(null);
   const [customerNotExist, setCustomerNotExist] = useState(false);

   // Cleans all fields (except for cart)
   const cleanFields = () => {
      setCash('');
      setCard('');
      setCustomer(null);
      setNameOrPhone('');
   }

   // Toasty notifications
   // Only 'success' can reset the cart
   const toastySuccess = (msg) => {
      toast.success(`${msg}`);
   }
   const toastyWarn = (msg) => {
      toast.warn(`${msg}`, { autoClose: 3000 });
   }
   const toastyInfo = (msg) => {
      toast.warn(`${msg}`, {
         hideProgressBar: true,
         autoClose: 1000,
      });
   }
   const toastyError = (msg) => {
      toast.error(`${msg}`,);
   }

   // Obtains the customer profile by name or phone
   const fetchCustomer = () => {
      if (nameOrPhone !== '') {
         console.log(nameOrPhone)
         fetch(
            `${BACK_END}/customer/fetch/${nameOrPhone}`,
            {
               method: 'GET',
               mode: 'cors',
               cache: 'default',
               headers: {
                  'Content-Type': 'application/json'
               },
               redirect: 'follow',
               referrerPolicy: 'no-referrer',
            }
         )
            .then(async (res) => {
               const response = await res.json();
               if (res.ok) {
                  if (response.length > 0) {
                     setCustomer(response[0]);
                     setNameOrPhone(response[0].name);
                     if (response[0].points > 0) {
                        setUsePoints(true);
                     }
                     toast.success('Cliente encontrado', { autoClose: 1000, hideProgressBar: true });
                  }
                  else {
                     toastyWarn('Cliente sin registrar');
                     setCustomerNotExist(true);
                  }
               }
               else {
                  toastyError('Ocurrió un error inesperado');
                  console.log(res.error);
               }
            });
      }
      else {
         toastyWarn('Inserte un dato para buscar');
      }
   }

   const createBill = async (formData) => {
      let bill_id = null;
      await fetch(
         `${BACK_END}/bill/`,
         {
            method: 'POST',
            mode: 'cors',
            cache: 'default',
            credentials: 'same-origin',
            headers: {
               'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(formData)
         }
      )
         .then(async (res) => {
            const data = await res.json();
            if (res.ok) {
               bill_id = data.bill_id;
               toastySuccess(data.message);
               afterSell();
            } else {
               toastyError('Occurió un error inesperado con el ticket');
               console.log(res.error);
            }
         })
         .catch((error) => console.log(error))
      return bill_id
   }

   const payUp = async () => {

      const customerPoints = (customer && usePoints) ? Number(customer.points) : 0;
      let remainingTotal = cartTotal - customerPoints;

      const cashAmount = Number(cash);
      const cardAmount = Number(card);

      if (remainingTotal <= 0 && (cashAmount > 0 || cardAmount > 0)) {
         toast.info('No se necesita efectivo ni tarjeta, el total ya está pagado con puntos.');
         return;
      }

      if (missing > 0 && paymentMode === 'contado') {
         toastyInfo('Falta dinero para completar la compra');
         return;
      }
      if (paymentMode !== 'contado' && !customer) {
         toastyInfo('Falta seleccionar un cliente');
         return;
      }

      const customer_id = (customer) ? customer.customer_id : null;

      const formData = {
         user_id: userData.user_id,
         customer_id: customer_id,
         total_amount: cartTotal,
         cart,
         cash: Number(cash) - Number(change),
         card,
         debt: (paymentMode === 'contado') ? 0 : Number(cartTotal),
         change,
         discount: Number(discount).toFixed(2),
         usePoints,
         cancel2x1,
      };

      await createBill(formData)
         .then((bill_id) => {
            setPayingState(0);
            setCart([]);
            printTicket(
               userData?.name,
               cart,
               new Date().toISOString(),
               { cash: formData.cash, card: formData.card, debt: formData.debt, points: Number(customer?.points || 0) },
               cartTotal,
               change,
               discount,
               bill_id
            );
            if(paymentMode !== 'contado'){
               navigate(`/pay_debt/${bill_id}`, { state: { customer_id: customer.customer_id , clean: true } });
            }
         })
         .catch((error) => {
            console.error(error)
            toastyError('Ocurrió un error con la creación del ticket')
         });

      cleanFields();
      
   }

   const handleMoneyInput = (event, setValue) => {
      const value = event.target.value;
      if (/^\d*\.?\d{0,2}$/.test(value)) {
         setValue(value);
      }
   }

   // Give a nice format
   const formatNumber = (number) => {
      number = Number(number);
      return number.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
   };

   useEffect(() => {
      if (usePoints) {
         const customerPoints = (customer && usePoints) ? Number(customer.points) : 0;
         let remainingTotal = cartTotal - customerPoints;

         const cashAmount = Number(cash);
         const cardAmount = Number(card);

         if (remainingTotal > 0) {
            remainingTotal -= (cashAmount + cardAmount);
         } else {
            remainingTotal = 0;
         }

         const missing = remainingTotal.toFixed(2);
         const change = (remainingTotal === 0) ? Math.abs(cashAmount + cardAmount).toFixed(2) : Math.abs(remainingTotal).toFixed(2);

         setMissing(missing >= 0 ? missing : '0.00');
         setChange(change);
      } else {
         const cashAmount = Number(cash);
         const cardAmount = Number(card);
         const totalPaid = cashAmount + cardAmount;
         const remainingTotal = cartTotal - totalPaid;

         const missing = remainingTotal > 0 ? remainingTotal.toFixed(2) : '0.00';
         const change = remainingTotal < 0 ? Math.abs(remainingTotal).toFixed(2) : '0.00';

         setMissing(missing);
         setChange(change);
      }
   }, [cash, card, usePoints, customer, cartTotal]);

   // Handle checkbox
   const handleCheckboxChange = () => {
      if (!customer) {
         toastyWarn('Falta seleccionar un cliente');
         return;
      }
      setUsePoints(!usePoints);
   };

   // Toggle payment mode
   const togglePaymentMode = () => {
      setPaymentMode((prevMode) => (prevMode === 'contado' ? 'deuda' : 'contado'));
      setUsePoints(false);
      setCash('');
      setCard('');
   };

   return (
      <div className="register-card">
         <h3 className="register-title">INGRESE EL MONTO CORRESPONDIENTE</h3>
         <div className="register-payment">
            {paymentMode === 'contado' ? (
               <>
                  <div className="register-efectivo payment-method field shadow-div">
                     <div className='register-box-center'>
                        <div className="payment-title"><h3>Efectivo</h3></div>
                        <BsCash className="icon" size="50" color={(cash !== '') ? 'black' : (isCashFocused) ? selectedColor : 'gray'} />
                     </div>
                     <div className="money-field">
                        <div className={`payment-field ${(cash !== '') ? 'payment-field-filled' : isCashFocused ? 'payment-field-focused' : ''}`}>
                           <MdOutlineAttachMoney size="28" color={(cash !== '') ? 'black' : (isCashFocused) ? selectedColor : 'gray'} />
                           <input
                              autoFocus
                              type='text'
                              placeholder='0.00'
                              className="payment-input"
                              value={cash}
                              onChange={(e) => handleMoneyInput(e, setCash)}
                              onFocus={() => setIsCashFocused(true)}
                              onBlur={() => setIsCashFocused(false)}
                           />
                        </div>
                     </div>
                  </div>

                  <div className="register-tarjeta payment-method field shadow-div">
                     <div className='register-box-center'>
                        <div className="payment-title" style={{ color: (card !== '') ? 'black' : (isCardFocused) ? selectedColor : 'gray' }}><h3>Tarjeta</h3></div>
                        <FaRegCreditCard className="icon" size="50" color={(card !== '') ? 'black' : (isCardFocused) ? selectedColor : 'gray'} />
                     </div>
                     <div className="money-field">
                        <div className={`payment-field ${(card !== '') ? 'payment-field-filled' : isCardFocused ? 'payment-field-focused' : ''}`}>
                           <MdOutlineAttachMoney size={28} color={(card !== '') ? 'black' : (isCardFocused) ? selectedColor : 'gray'} />
                           <input
                              type='text'
                              placeholder='0.00'
                              className="payment-input"
                              value={card}
                              onChange={(e) => handleMoneyInput(e, setCard)}
                              onFocus={() => setIsCardFocused(true)}
                              onBlur={() => setIsCardFocused(false)}
                           />
                        </div>
                     </div>
                  </div>

                  <div className="register-tarjeta payment-method field shadow-div">
                     <div className="user-info">
                        <div
                           className={`
                           register-user-field
                           ${(customer) ? 'register-user-field-correct' :
                                 (customerNotExist) ? 'register-user-field-warn' : ''
                              }
                        `}
                        >
                           <FaUserAlt size={28} />
                           <input
                              type='text'
                              placeholder='Nombre/teléfono'
                              disabled={customer}
                              value={nameOrPhone}
                              onChange={(e) => {
                                 setNameOrPhone(e.target.value);
                                 setCustomerNotExist(false);
                              }}
                              maxLength={128}
                              onKeyDown={
                                 (event) => {
                                    if (event.key === 'Enter') {
                                       fetchCustomer();
                                    }
                                 }
                              }
                           />
                           <button
                              className='receipt-user-button'
                              onClick={(customer) ? () => { setCustomer(null); setUsePoints(false); } : () => navigate('/add_customer')}
                           >
                              {
                                 (customer)
                                    ?
                                    <MdCancel size={30} className="receipt-user-button-icon" color='black' />
                                    :
                                    <MdAddCircle size={30} className="receipt-user-button-icon" color='black' />
                              }
                           </button>
                        </div>
                        {
                           (customer)
                              ?
                              <div className="user-info-inner">
                                 <div className="register-user-points">
                                    <input
                                       value={
                                          //formatNumber((!usePoints) ? customer.points : (customer.points - cartTotal > 0) ? (customer.points - cartTotal) : 0)
                                          formatNumber(customer.points)
                                       }
                                       placeholder='0'
                                       type="text"
                                       id="responseTextField"
                                       disabled={true}
                                       readonly
                                    />
                                 </div>
                              </div>
                              :
                              <></>
                        }
                     </div>
                     <div className="user-checkbox">
                        <input
                           type="checkbox"
                           id="myCheckbox"
                           name="myCheckbox"
                           value="checkboxValue"
                           checked={usePoints}
                           onChange={handleCheckboxChange}
                        />
                        <label htmlFor="myCheckbox">Usar puntos</label>
                     </div>
                  </div>
               </>
            ) : (
               <>
                  <div className="register-credito payment-method field shadow-div">
                     <div className='register-box-center'>
                        <div className="payment-title"><h3>Crédito</h3></div>
                        <PiHandCoinsFill className="icon" color={'gray'} />
                     </div>
                     <div className="money-field">
                        <div className={`payment-field`}>
                           <MdOutlineAttachMoney size={28} color={'gray'} />
                           <input
                              type='text'
                              placeholder='0.00'
                              className="payment-input"
                              value={cartTotal}
                              disabled={true}
                           />
                        </div>
                     </div>
                     <div className="user-info">
                        <div
                           className={`
                           register-user-field
                           ${(customer) ? 'register-user-field-correct' :
                                 (customerNotExist) ? 'register-user-field-warn' : ''
                              }
                        `}
                        >
                           <FaUserAlt size={28} />
                           <input
                              type='text'
                              placeholder='Nombre/teléfono'
                              disabled={customer}
                              value={nameOrPhone}
                              onChange={(e) => {
                                 setNameOrPhone(e.target.value);
                                 setCustomerNotExist(false);
                              }}
                              maxLength={128}
                              onKeyDown={
                                 (event) => {
                                    if (event.key === 'Enter') {
                                       fetchCustomer();
                                    }
                                 }
                              }
                           />
                           <button
                              className='receipt-user-button'
                              onClick={(customer) ? () => { setCustomer(null); setUsePoints(false); } : () => navigate('/add_customer')}
                           >
                              {
                                 (customer)
                                    ?
                                    <MdCancel size={30} className="receipt-user-button-icon" color='black' />
                                    :
                                    <MdAddCircle size={30} className="receipt-user-button-icon" color='black' />
                              }
                           </button>
                        </div>
                        {
                           (customer)
                              ?
                              <div className="user-info-inner">
                                 <div className="register-user-points">
                                    <input
                                       value={
                                          customer.points
                                          //formatNumber((!usePoints) ? customer.points : (customer.points - cartTotal > 0) ? (customer.points - cartTotal) : 0)
                                       }
                                       placeholder='0'
                                       type="text"
                                       id="responseTextField"
                                       disabled={true}
                                       readonly
                                    />
                                 </div>
                              </div>
                              :
                              <></>
                        }
                     </div>
                     <div className="user-checkbox">
                        <input
                           type="checkbox"
                           id="myCheckbox"
                           name="myCheckbox"
                           value="checkboxValue"
                           checked={usePoints}
                           onChange={handleCheckboxChange}
                        />
                        <label htmlFor="myCheckbox">Usar puntos</label>
                     </div>
                  </div>
               </>
            )}

            {
               paymentMode === 'contado' &&
               <div className="register-missing field">
                  <p>FALTAN:</p>
                  <h3 className="register-missing-text shadow-div">
                     ${missing}
                  </h3>
               </div>
            }
            <button className="toggle-payment-mode" onClick={togglePaymentMode}>
               <FaExchangeAlt /> {paymentMode === 'contado' ? 'Apartar' : 'Pagar de contado'}
            </button>
         </div>
         <div className="register-change">
            <h3 className='register-change-text'>
               CAMBIO: ${change}
            </h3>
         </div>
         <div className="register-options">
            <div className='register-button-row'>
               {
                  <button className='charge charge--hover-shadow golden-button reduce-golden' onClick={payUp} >
                     <FaMoneyBill size={38} color='000000' className='charge-icon' />
                     Cobrar
                  </button>
               }
               <button className='recipent-back-button' onClick={() => setPayingState(0)}>
                  <IoMdArrowRoundBack size={32} color='000000' className='charge-icon' />
                  Volver
               </button>
            </div>
         </div>
      </div>
   )
}

export default Register;
