import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './fonts.css';

import SessionProvider from './hooks/useSession';

import Modal from 'react-modal';

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <SessionProvider>
        <App />
    </SessionProvider>
);

reportWebVitals();