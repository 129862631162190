import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

import EditCustomer from './screens/EditCustomer/EditCustomer';
import InitialMoney from './screens/InitialMoney/InitialMoney';
import AddCustomer from './screens/AddCustomer/AddCustomer';
import EditProduct from './screens/EditProduct/EditProduct';
import AddProduct from './screens/AddProduct/AddProduct';
import Inventory from './screens/Inventory/Inventory';
import Utilities from './screens/Utilities/Utilities';
import Customers from './screens/Customers/Customers';
import EditUser from './screens/EditUser/EditUser';
import AddUser from './screens/AddUser/AddUser';
import PayDebt from './screens/PayDebt/PayDebt';
import AddList from './screens/AddList/AddList';
import { ToastContainer } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import Users from './screens/Users/Users';
import Login from './screens/Login/Login';
import Sales from './screens/Sales/Sales';
import Refund from './screens/Refund/Refund';

import { useContext } from 'react';
import { SessionContext } from './hooks/useSession';
import AddEntry from './screens/Inventory/AddEntry/AddEntry';
import MyUser from './screens/MyUser/MyUser';
import Cut from './screens/Cut/Cut';
import Settings from './screens/Settings/Settings';
import Envios from './screens/Envios/Envios';

function App() {

  const { userData, token, initialMoney } = useContext(SessionContext);

  const isAdmin = userData && userData.rol === "Administrador";

  return (
    <div className="app">
      <Router>
        <Routes>
          {
            (!token)
            ?
            <>
              <Route path='/login' element={<Login/>} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
            :
            (!initialMoney && !isMobile)
            ?
            <>
              <Route path='/initial_money' element={<InitialMoney/>} />
              <Route path="*" element={<Navigate to="/initial_money" />} />
            </>
            :
            <>
              {!isMobile && <Route path='/' element={<Sales />} />}
              {!isMobile && <Route path='/refund/:refund_id' element={<Refund />} />}
              <Route path='/inventory' element={<Inventory />} />
              <Route path='/add_entry' element={<AddEntry />} />
              <Route path='/add_entry/:entry_id' element={<AddEntry />} />
              <Route path='/customers' element={<Customers />} />
              <Route path='/utilities' element={<Utilities />} />
              {!isMobile && <Route path='/edit_user/:user_id' element={<EditUser />} />}
              <Route path='/add_product' element={<AddProduct />} />
              <Route path='/edit_product/:product_id' element={<EditProduct />} />
              <Route path='/add_list/:list' element={<AddList />} />
              <Route path='/add_customer' element={<AddCustomer />} />
              {!isMobile && <Route path='/add_user' element={<AddUser />} />}
              <Route path='/edit_customer/:id' element={<EditCustomer />} />
              <Route path='/pay_debt/:billId' element={<PayDebt />}/>
              <Route path='/settings' element={<Settings />}/>
              {/* Admin routes */}
              { isAdmin && !isMobile && <Route path='/users' element={<Users />} /> }
              {/* Redirects */}
              {isMobile && <Route path='/' element={<Navigate to="/inventory" />} />}
              <Route path="/login" element={<Navigate to="/" />} />
              <Route path="/initial_money" element={<Navigate to="/" />} />
              <Route path='/my_user' element={<MyUser />} />
              <Route path='/cut' element={<Cut />} />
              <Route path='/envios' element={<Envios />} />
            </>
          }
        </Routes>
      </Router>
      <ToastContainer position='top-right' />
    </div>
  );
}

export default App;

/*
  Puse un perro guardian aquí para que los bugs no entren.
                                          _.-.._         _._
                                     _,/^^,y./  ^^^^"""^^\= \
                                     \y###XX;/  /     \    ^\^\
                                       `\Y^   /   .-==||==-.)^^
                   ,.-=""""=-.__       /^ (  (   -/<0>++<0>(
                 .^      .: . . :^===(^ \ (  (  /```^^^^^^^)
                /      .: .,GGGGp,_ .(   \   /    /-(o'~'o))
              .^      : . gGG"""YGG}. \   )   / /  _/-====-\
             /       (. .gGP  __ ~~ . .\  \  (    (  _.---._)
            /        (. (GGb,,)GGp. . . \_-^-.__(_ /______./
           (          \ . `"!GGP^ . . . . ^=-._--_--^^^^^~)
           (        /^^^\_. . . . . . . . . . . . . . . . )
           )       /     /._. . . . . . . . . . . . . ._.=)
           \      /      |  ^"=.. . . . . . . ._++""\"^    \
            \    |       |       )^|^^~'---'~^^      \     )
            )   /        )      /   \                 \    \
            |`  |        \     /\    \                (    /
            |   |         (   (  \ . .\               |   (
            )   |         )   )   ^^^^^^              |   |
           /. . \         |  '|                       )   (
           ^^^^^^         )    \                      /. . \
                          / . . \                     ^^^^^^
                          ^^^^^^^
Allen Mullen.
*/