import React, { useEffect, useState } from 'react';
import './CutDetailModal.css';
import BACK_END from '../../config/direction';
import { TiPrinter } from 'react-icons/ti';
import { printCut } from '../../util/pos_esc';
import { BsCashCoin } from "react-icons/bs";
import { FaRegCreditCard } from "react-icons/fa";
import { HiShoppingCart } from "react-icons/hi";
import { GiClothes } from "react-icons/gi";

const CutDetailModal = ({ isOpen, onClose, cutData }) => {
  const [data, setData] = useState(null);

  const getData = () => {
    if (cutData) {
      const cut_id = cutData.cut_id;
      fetch(`${BACK_END}/cut/${cut_id}`)
        .then(async response => {
          if (!response.ok) {
            throw new Error('Algo salió mal.');
          }
          const result = await response.json();
          setData(result);
        })
        .catch(error => console.error('Error:', error));
    }
  }

  useEffect(getData, [cutData]);

  const formatNumber = (number) => {
    if (number === undefined || number === null) return 'N/A';
    return Number(number).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handlePrintCut = () => {
    if (cutData) {
      const dataFull = {
        ...data,
        user_name: cutData?.user_name ?? 'N/A',
        created_at: cutData?.created_at
      }
      console.log(dataFull)
      printCut(dataFull);
    }
  }

  return isOpen ? (
    <div className="cut-modal-overlay" onClick={onClose}>
      <div className="cut-modal" onClick={(e) => e.stopPropagation()}>
        <div className="cut-modal-header">
          <h2>Detalle del Corte</h2>
          <div className="cut-modal-row-button">
            <button
              className="cut-modal-print-button"
              onClick={handlePrintCut}
            >
              <TiPrinter size={24} /> Imprimir corte
            </button>
          </div>
          <button className="cut-close-button" onClick={onClose}>
            X
          </button>
        </div>
        <div className="cut-modal-body">
          <p>
            <strong>Fecha:</strong>{" "}
            {new Date(cutData?.created_at).toLocaleString()}
          </p>
          <p>
            <strong>Usuario:</strong> {cutData?.user_name ?? "N/A"}
          </p>
          <p>
            <strong>Resultado:</strong>{" "}
            {cutData?.missing > 0
              ? `Faltante: $${cutData?.missing}`
              : cutData?.spare > 0
                ? `Sobrante: $${cutData?.spare}`
                : "Exacto"}
          </p>
          {data && (
            <>
              <div className="card__body">
                <div className="cut__column">
                  <div className="cut__row">
                    <div className="cut__column--text">
                      <p className="cut__row--title">Dinero en Caja</p>
                      <p className="cut__row--text">Dinero Inicial en Caja:</p>
                      <p className="cut__row--text">Ventas con Efectivo:</p>
                      <p className="cut__row--text">Abonos con Efectivo:</p>
                      <p className="cut__row--text">Entradas:</p>
                      <p className="cut__row--text">Salidas:</p>
                      <p className="cut__row--title">Efectivo total:</p>
                    </div>
                    <div className="cut__column--money">
                      <div className="cash_icon">
                        <BsCashCoin size={24} color="BLACK" />
                      </div>

                      <div className="lock">
                        <p className="cut__row--number">
                          ${formatNumber(data.initialCash)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--green">
                          ${formatNumber(data.totalCashPaid)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--green">
                          ${formatNumber(data.totalDebtCash)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--green">
                          ${formatNumber(data.totalCashIn)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--red">
                          ${formatNumber(data.totalCashOut)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--title">
                          ${formatNumber(data.totalCashInBox)}
                        </p>
                      </div>

                    </div>
                  </div>

                  <div className="cut__row2">
                    <div className="cut__column--text">
                      <p className="cut__row--title">Dinero en el banco </p>
                      <p className="cut__row--text">Ventas con Tarjeta:</p>
                      <p className="cut__row--text">Abonos con Tarjeta:</p>
                      <p className="cut__row--title">Total en el banco: </p>
                    </div>
                    <div className="cut__column--money">
                      <div className="card_icon">
                        <FaRegCreditCard size={24} color="BLACK" />
                      </div>

                      <div className="lock">
                        <p className="cut__row--green">
                          ${formatNumber(data?.totalCardPaid)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--green">
                          ${formatNumber(data?.totalDebtCard)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--title">
                          ${formatNumber(data?.totalMoneyInBank)}
                        </p>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="cut__column2">
                  <div className="cut__row">
                    <div className="cut__column--text">
                      <p className="cut__row--title">Ventas</p>
                      <p className="cut__row--text">Número de Ventas:</p>
                      <p className="cut__row--text">Productos Vendidos:</p>
                      <p className="cut__row--text">Total en Efectivo:</p>
                      <p className="cut__row--text">Total en Tarjeta:</p>
                      <p className="cut__row--text">Ventas por Apartado:</p>
                      <p className="cut__row--title">Total de Ventas:</p>
                      <p className="cut__row--title">Ganancias Totales:</p>
                    </div>
                    <div className="cut__column--money">
                      <div className="card_icon">
                        <HiShoppingCart size={24} color="BLACK" />
                      </div>

                      <div className="lock">
                        <p className="cut__row--number">
                          {parseInt(data?.totalSales)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--number">
                          {parseInt(data?.totalProductsSold)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--green">
                          ${formatNumber(data?.totalCashInBox)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--green">
                          ${formatNumber(data?.totalMoneyInBank)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--yellow">
                          ${formatNumber(data?.totalSalesDebt)}
                        </p>
                      </div>

                      <div className="lock">
                        <p className="cut__row--title">
                          ${formatNumber(data?.totalSalesMoney)}
                        </p>
                      </div>



                      <div className="lock">
                        <p className="cut__row--title">
                          ${formatNumber(data?.totalGain)}
                        </p>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="cut__column auto-scroll">
                  <div className="cut__row">
                    <p className="cut__row--title">Ventas por departamento:</p>
                    <div className="card_icon">
                      <GiClothes size={24} color="BLACK" />
                    </div>
                  </div>
                  <div className="cut__row">
                    <div className="cut__column--text2">
                      {data.salesByDepartment.map((department) => (
                        <p className="cut__row--text">
                          ({department?.totalProductsSold}){" "}
                          {department?.departmentName}
                        </p>
                      ))}
                      <p className="cut__row--title">Total:</p>
                    </div>
                    <div className="cut__column--money2">
                      {data.salesByDepartment.map((department) => (
                        <div className="lock">
                          <p className="cut__row--number">
                            ${formatNumber(department?.totalSales)}
                          </p>
                        </div>
                      ))}
                      <p className="cut__row--title">
                        $
                        {formatNumber(
                          data.salesByDepartment.reduce(
                            (acc, department) => acc + department.totalSales,
                            0
                          )
                        )}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="cut__row">
                    <p className="cut__row--title">
                      Ventas por departamento (Apartados):
                    </p>
                    <div className="card_icon">
                      <GiClothes size={24} color="BLACK" />
                    </div>
                  </div>
                  <div className="cut__row">
                    <div className="cut__column--text2">
                      {data.salesByDepartmentCredit.map(
                        (department) => (
                          <p className="cut__row--text">
                            ({department?.totalProductsSold}){" "}
                            {department?.departmentName}
                          </p>
                        )
                      )}
                      <p className="cut__row--title">Total:</p>
                    </div>
                    <div className="cut__column--money2">
                      {data.salesByDepartmentCredit.map(
                        (department) => (
                          <div className="lock">
                            <p className="cut__row--number">
                              ${formatNumber(department?.totalSales)}
                            </p>
                          </div>
                        )
                      )}
                      <p className="cut__row--title">
                        $
                        {formatNumber(
                          data.salesByDepartmentCredit.reduce(
                            (acc, department) => acc + department.totalSales,
                            0
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </>
          )}

        </div>
      </div>
    </div>
  ) : null;
};

export default CutDetailModal;